import {
  candidateValidateDocument,
  changeEmployeeInOrgchart,
  changeMultipleEmployeeInOrgchart,
  checkLeaderByEmployeeId,
  checklistTemplateAdd,
  checklistTemplateNew,
  checklistTemplateRemove,
  checklistTemplateUpdate,
  checklistToggleHiring,
  checklistToggleResignation,
  checklistUpdateHiring,
  checklistUpdateResignation,
  createBoardReport,
  createFormConfig,
  createHiringCard,
  createManyResignationCard,
  createMassCandidateProcess,
  createModelProposedLetter,
  createResignationCard,
  deleteModelProposedLetter,
  dependentValidateDocument,
  dismissCandidateProcess,
  duplicateModelProposedLetter,
  getMultipleS3File,
  getS3File,
  hiringChangeColumn,
  hiringPostComment,
  hiringUpdateCard,
  hiringUpdateCardStatus,
  interruptMassCandidateProcess,
  orderCardDelivery,
  removeFormConfig,
  renameFormConfig,
  replaceChildrenInOrgchart,
  resendAdmissionalExamRequest,
  resendDocumentsRequest,
  resendSignRequest,
  resetAso,
  resetContract,
  resignationPostComment,
  resignationUpdateCard,
  saveBenefits,
  saveWorkshift,
  sendAdmissionalExamRequest,
  sendDocumentsRequest,
  sendFormConfigExtension,
  sendHiringNextColumn,
  sendSignRequest,
  sendSignRequestV2,
  signManyContracts,
  updateCandidateById,
  updateModelProposedLetter,
  validateAsoDocument,
} from "./mutations"
import {
  getAddressByZipCode,
  getCompanyBenefits,
  getCompanyContracts,
  getDeliveryCardOrder,
  getFlowSubcategoryByFlowId,
  getWorkshiftById,
  getWorkshifts,
  verifyModelContract,
} from "./queries"

const mutation = {
  hiring: {
    checklist: {
      templateNew: checklistTemplateNew,
      templateAdd: checklistTemplateAdd,
      templateRemove: checklistTemplateRemove,
      templateUpdate: checklistTemplateUpdate,
      toggleItem: checklistToggleHiring,
      updateItem: checklistUpdateHiring,
    },
    massCandidate: {
      createProcess: createMassCandidateProcess,
      interruptProcess: interruptMassCandidateProcess,
      dismissProcess: dismissCandidateProcess,
    },
    massSignature: {
      signManyContracts,
    },
    modelDocuments: {
      createFormConfig,
      removeFormConfig,
      renameFormConfig,
      sendFormConfigExtension,
    },
    modelProposedLetter: {
      create: createModelProposedLetter,
      update: updateModelProposedLetter,
      duplicate: duplicateModelProposedLetter,
      delete: deleteModelProposedLetter,
    },
    flow: {
      getFlowSubcategoryByFlowId,
    },
    card: {
      createCard: createHiringCard,
      updateCard: hiringUpdateCard,
      nextColumn: sendHiringNextColumn,
      postComment: hiringPostComment,
      updateStatus: hiringUpdateCardStatus,
      moveColumn: hiringChangeColumn,
      resetAso: resetAso,
    },
    contract: {
      resetContract,
      sendSignRequest,
      sendSignRequestV2,
      resendSignRequest,
      verifyModelContract,
    },
    sendAdmissionalExamRequest,
    resendAdmissionalExamRequest,
    updateCandidate: updateCandidateById,
    candidateValidateDocument,
    dependentValidateDocument,
    sendDocumentsRequest,
    resendDocumentsRequest,
    createBoardReport,
    validateAdmissionalExamDocument: validateAsoDocument,
  },
  resignation: {
    checklist: {
      toggleItem: checklistToggleResignation,
      updateItem: checklistUpdateResignation,
    },
    card: {
      postComment: resignationPostComment,
      createCard: createResignationCard,
      createManyCard: createManyResignationCard,
      updateCard: resignationUpdateCard,
    },
  },
  helper: {
    getS3File,
    getMultipleS3File,
  },
  orgchart: {
    checkLeaderByEmployeeId,
    changeEmployeeInOrgchart,
    replaceChildrenInOrgchart,
    changeMultipleEmployeeInOrgchart,
  },
  workshift: {
    saveWorkshift,
  },
  cardDelivery: {
    orderCardDelivery: orderCardDelivery,
  },
  benefits: {
    saveBenefits,
  },
}

const query = {
  company: {
    getWorkshifts,
    getBenefits: getCompanyBenefits,
    getCompanyContracts,
  },
  workshift: {
    getWorkshiftById,
  },
  cardDelivery: {
    getDeliveryCardOrder,
    getAddressByZipCode,
  },
}

export const api = {
  mutation,
  query,
}
