import { useParams } from "react-router-dom"
import { ResignationCardPage } from "../../../components"

const ResignationUpdatePage = () => {
  const { id: cardId = "" } = useParams()

  return (
    <ResignationCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Desligamento", href: "/flows/resignation" },
        {
          name: "Atualização de Documento",
          href: `/flows/resignation/document/update/${cardId}`,
        },
      ]}
    />
  )
}

export default ResignationUpdatePage
