import { useTranslation } from "react-i18next"
import { GenericModal } from "../../../../components"
import { SelectField } from "@flash-tecnologia/hros-web-ui-v2"
import { useMutation } from "@tanstack/react-query"
import { api } from "../../../../api"
import { Flow, HiringCard } from "../../../../types"
import { useState } from "react"
import dispatchToast from "../../../../../src/utils/dispatchToast"
import {
  AdmissionWarningModal,
  ModalDoneAdmission,
} from "../../../../components/common/ModalDoneAdmission"
import { hiringColumnsId } from "../../../../mock/board"
import { useNavigate } from "react-router-dom"

export const ModalMoveStep = ({
  flow,
  card,
  isOpen,
  handleClose,
}: {
  flow: Flow
  card: HiringCard
  isOpen: boolean
  handleClose: () => void
}) => {
  const [modal, setModal] = useState<"done" | "warning">()
  const [admissionDoneModal, setAdmissionDoneModal] =
    useState<React.ComponentProps<typeof ModalDoneAdmission>["card"]>()
  const [columnToChange, setColumnToChange] = useState<string>(card.columnId)

  const [t] = useTranslation("translations", {
    keyPrefix: "page.home.modalMovePage",
  })
  const navigate = useNavigate()
  const { mutateAsync: moveCardColumn, isLoading } = useMutation(
    api.mutation.hiring.card.moveColumn,
  )

  const handleSubmit = async () => {
    try {
      if (hiringColumnsId.initialConfiguration.includes(columnToChange)) {
        setModal("warning")
        return
      }

      if (hiringColumnsId.done.includes(columnToChange)) {
        const isMissingData =
          !card?.employeeId ||
          !card?.hiringDate ||
          !card?.corporateEmail ||
          !card?.documentNumber

        if (isMissingData) {
          setAdmissionDoneModal({
            candidateId: card.candidateId,
            candidate: {
              name: card.name,
              documentNumber: card.documentNumber,
              email: card.email,
              corporateEmail: card.corporateEmail || undefined,
              probationPeriod: card.candidate.probationPeriod,
              proposalLetterInfo: card.hiringDate
                ? { hiringDate: card.hiringDate }
                : undefined,
            },
          })
          setModal("done")
          return
        }
      }

      await moveCardColumn({
        flowCardId: card._id,
        newColumnId: columnToChange,
        newPosition: 0,
        version: card.version,
      })
      dispatchToast({
        content: "Candidato movido de coluna com sucesso!",
        type: "success",
      })
      handleClose()
    } catch (err) {
      dispatchToast({
        content: "Houve um problema ao mover o candidato de coluna",
        type: "error",
      })
      console.error(err)
    }
  }

  return (
    <GenericModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={t("title")}
      description={t("description")}
      actionButtons={[
        {
          description: t("button.cancel"),
          handleAction: handleClose,
          variant: "link",
        },
        {
          description: t("button.submit"),
          handleAction: handleSubmit,
          variant: "primary",
          icon: "IconArrowRight",
          disabled: columnToChange === card.columnId || isLoading,
          isLoading: isLoading,
        },
      ]}
    >
      <SelectField
        label={t("selectLabel")}
        onSelectChange={(_event, { value }) => setColumnToChange(value)}
        value={columnToChange}
        options={flow.columns?.map((column) => ({
          label: column.name,
          value: column._id,
        }))}
      />

      {modal === "warning" ? (
        <AdmissionWarningModal
          isOpen={true}
          handleClose={() => setModal(undefined)}
        />
      ) : (
        <></>
      )}

      {modal === "done" && admissionDoneModal ? (
        <ModalDoneAdmission
          isOpen={true}
          handleClose={() => {
            setModal(undefined)
            setAdmissionDoneModal(undefined)
          }}
          card={admissionDoneModal}
          cbConfigure={async () => {
            await moveCardColumn(
              {
                flowCardId: card._id,
                newColumnId: columnToChange,
                newPosition: 0,
                version: card.version,
              },
              {
                onError: () =>
                  dispatchToast({
                    content: "Ocorreu um erro de conexão ao atualizar o card!",
                    type: "error",
                  }),
              },
            )
            setAdmissionDoneModal(undefined)
            setModal(undefined)

            navigate("/flows/hiring")
          }}
          loading={isLoading}
        />
      ) : (
        <></>
      )}
    </GenericModal>
  )
}
