import React from "react"
import { useParams } from "react-router-dom"
import EmptySection from "../EmptySection"
import ConfigAccordion, {
  AccordionButton,
} from "../ConfigAccordion/ConfigAccordion"
import { HiringContentProps } from "../../../../components/screen/CardPage/types"
import {
  AccordionChildrenContainer,
  BenefitContainer,
  BenefitName,
} from "./styles"
import { Benefit } from "../../../../types"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { currencyParser } from "../../../../utils"
import BenefitBadge from "./components/BenefitBadge"
import { globalContext } from "../../../../context"
import { trpc } from "@api/client"

type BenefitWithVaue = Benefit & { value: number }

const BenefitsInformation: React.FC<{ benefits: BenefitWithVaue[] }> = ({
  benefits,
}) => {
  return (
    <AccordionChildrenContainer>
      {benefits.map((benefit) => (
        <BenefitContainer key={`${benefit.id}`}>
          <BenefitBadge src={benefit.icon} name={benefit.name} size="s" />
          <BenefitName variant="body3">{benefit.name}</BenefitName>
          <Typography variant="body3" weight={700}>
            {currencyParser(benefit?.value?.toString())}
          </Typography>
        </BenefitContainer>
      ))}
    </AccordionChildrenContainer>
  )
}

const BenefitsAccordion: React.FC<{
  status: "completed" | "pending"
  title: string
  description: string
  children: React.ReactNode
  loading?: boolean
}> = ({ status, title, description, children, loading }) => {
  const { id: cardId = "" } = useParams()
  const { companyPermissions } = React.useContext(globalContext)

  const [hasBenefitAssignmentPermission, hasBenefitOrderPermission] =
    React.useMemo(() => {
      if (!companyPermissions) {
        return [false, false]
      }
      return companyPermissions?.reduce(
        (acc, permission) => {
          const [benefitAssignment, benefitOrder] = acc
          if (permission === "*") {
            return [true, true]
          }
          const newBenefitAssignment =
            benefitAssignment || permission === "benefits_simpleassignment_view"
          const newBenefitOrder =
            benefitOrder || permission === "benefits_benefits_orderview"
          return [newBenefitAssignment, newBenefitOrder]
        },
        [false, false],
      )
    }, [companyPermissions])

  return (
    <ConfigAccordion
      status={status}
      title={title}
      description={description}
      loading={loading}
      disabled={!hasBenefitAssignmentPermission}
      button={
        status === "completed" ? (
          <AccordionButton
            label="Ir para pedido de benefícios"
            href="/benefits/dashboard/company/employees"
            variant={"secondary"}
            disabled={!hasBenefitOrderPermission}
          />
        ) : (
          <AccordionButton
            label="Ir para atribuição de benefícios"
            href={`/flows/hiring/initial-config/benefits/${cardId}`}
            variant={"primary"}
            disabled={!hasBenefitAssignmentPermission}
          />
        )
      }
    >
      {children}
    </ConfigAccordion>
  )
}

interface BenefitsConfigProps extends HiringContentProps {}

const BenefitsConfig: React.FC<BenefitsConfigProps> = ({ card }) => {
  const { data, isInitialLoading: isLoading } =
    trpc.benefit.getCandidateBenefits.useQuery(
      {
        candidateId: card?.candidate._id,
      },
      {
        enabled: !!card?.candidate._id,
        refetchOnWindowFocus: false,
      },
    )

  return (
    <BenefitsAccordion
      status={data?.length ? "completed" : "pending"}
      title="Benefícios da pessoa"
      description="Atribua os benefícios da flash para a nova pessoa"
      loading={isLoading}
    >
      {data?.length ? (
        <BenefitsInformation
          benefits={(data as BenefitWithVaue[]).map((benefit) => ({
            ...benefit,
            value: benefit.value,
          }))}
        />
      ) : (
        <EmptySection
          title={"Atribua os benefícios da flash para a nova pessoa"}
          description="As informações das configurações selecionadas aparecerão aqui."
        />
      )}
    </BenefitsAccordion>
  )
}

export const BenefitsConfigByType = {
  clt: BenefitsConfig,
  pj: BenefitsConfig,
  internship: BenefitsConfig,
}
