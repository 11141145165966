import styled from "styled-components"
import { Component, IFieldConfig } from "@customTypes/documents"
import { RenderField } from "../RenderField"
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"

interface PreviewFieldProps {
  field: IFieldConfig
}

const RoundedLineContainer = styled.div`
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 50%;
  margin-right: 8px;
`

export const PreviewField = ({ field }: PreviewFieldProps) => {
  const isRequired = field.fieldRule?.required

  if (field.component === Component.Attachment)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          alignItems: "center",
          width: "100%",
        }}
      >
        <RoundedLineContainer>
          <Icons
            fill="transparent"
            name="IconCamera"
            color="#6B5B66"
            size={16}
          />
        </RoundedLineContainer>

        <Typography variant="body3" variantColor="#B9ACB4">
          {field.placeholder}
          {isRequired && (
            <span
              style={{
                marginLeft: 2,
                color: "#FEA034",
              }}
            >
              *
            </span>
          )}
        </Typography>
      </div>
    )

  return (
    <RenderField
      field={{
        ...field,
        enabled: false,
      }}
    />
  )
}
