import { useTranslation } from "react-i18next"
import {
  LinkButton,
  Modal,
  SelectField,
  TextEditor,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"

import { Container, WarningLinkButton } from "./styles"
import { IFormConfig, IFormGroup } from "../../../types"
import { ConfirmationModal } from "../ConfirmationModal"
import { useContext, useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { api } from "../../../api"
import dispatchToast from "../../../utils/dispatchToast"
import { LoadingButton } from "../LoadingButton"
import { globalContext } from "../../../context"

interface IModalDocumentsInstructions {
  isOpen: boolean
  handleClose: () => void
  handleExtension: (extesionId: string) => void
  formConfig: IFormConfig
  section: IFormGroup
}

export const ModalDocumentInstructions = ({
  isOpen,
  handleClose,
  handleExtension,
  formConfig,
  section,
}: IModalDocumentsInstructions) => {
  const [instructions, setInstructions] = useState<string>(
    section?.fillInstruction?.html || "",
  )
  const [confirmationDeleteInstruction, setConfirmationDeleteInstruction] =
    useState<boolean>(false)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const queryClient = useQueryClient()
  const { companyId = "" } = useContext(globalContext)
  const { mutateAsync: sendFormConfigExtension, isLoading } = useMutation(
    api.mutation.hiring.modelDocuments.sendFormConfigExtension,
  )

  const isUpdating = !!section.fillInstruction?.html

  const updateSection = async (updatedFormGroup: IFormGroup[]) => {
    if (!formConfig._id) {
      dispatchToast({
        type: "error",
        content: "Não foi possível atualizar a instrução",
      })
      return
    }
    return await sendFormConfigExtension(
      {
        companyId,
        baseId: formConfig._id,
        name: formConfig.name,
        formGroups: updatedFormGroup,
      },
      {
        onSuccess: async ({ _id }) => {
          dispatchToast({
            type: "success",
            content: isUpdating
              ? "Instrução atualizada com sucesso!"
              : "Instrução adicionada com sucesso!",
          })

          handleExtension(_id)

          await queryClient.invalidateQueries()
        },
        onError: (err: any) => {
          dispatchToast({
            type: "error",
            content: "Não foi possível atualizar a instrução",
          })
        },
      },
    )
  }

  const handleSubmit = async () => {
    if (instructions.toString().length === 0) {
      dispatchToast({
        content: "Por favor insira uma instrução antes de salvar",
        type: "warning",
      })
    }

    const updatedSection: IFormGroup = {
      ...section,
      fillInstruction: {
        html: instructions,
        json: "",
      },
    }

    const clonedFormGroups = [...formConfig.formGroups]
    const sectionIndexPosition = clonedFormGroups.findIndex(
      (s) => s._id === section._id,
    )
    clonedFormGroups.splice(sectionIndexPosition, 1, updatedSection)

    await updateSection(clonedFormGroups)
    handleClose()
  }

  const handleDeleteInstruction = async () => {
    const updatedSection: IFormGroup = {
      ...section,
      fillInstruction: {
        html: "",
        json: "",
      },
    }

    const clonedFormGroups = [...formConfig.formGroups]
    const sectionIndexPosition = clonedFormGroups.findIndex(
      (s) => s._id === section._id,
    )
    clonedFormGroups.splice(sectionIndexPosition, 1, updatedSection)

    await updateSection(clonedFormGroups)
    handleClose()
  }

  return (
    <Modal.Root open={isOpen} onClose={handleClose} size="md">
      <>
        <Modal.Header
          title={t("documentTemplate.instructionModal.title")}
          description={t("documentTemplate.instructionModal.description")}
        />

        <Modal.Content>
          <Container>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="headline8">
                {t("documentTemplate.instructionModal.bodyTitle")}
              </Typography>

              <Typography variant="body3">
                <span style={{ color: "#C96C01" }}>* </span>
                {t("documentTemplate.instructionModal.requiredText")}
              </Typography>
            </div>

            <SelectField
              disabled={true}
              required={true}
              label={t("documentTemplate.instructionModal.sectionLabel")}
              value={{
                label: section.title,
                value: section._id,
              }}
              options={[
                {
                  label: section.title,
                  value: section._id,
                },
              ]}
            />

            <TextEditor
              value={instructions}
              onChange={(value) => setInstructions(value)}
              placeholder={t(
                "documentTemplate.instructionModal.instructionPlaceholder",
              )}
              styles={{
                editor: {
                  height: 100,
                },
              }}
            />

            <ConfirmationModal
              isOpen={confirmationDeleteInstruction}
              onClose={() => setConfirmationDeleteInstruction(false)}
              title="Tem certeza que deseja excluir as instruções?"
              description="Essa ação não poderá ser desfeita."
              confirmButton={{
                onClick: handleDeleteInstruction,
                isLoading,
                text: "Confirmar exclusão",
                icon: "IconTrash",
              }}
            />
          </Container>
        </Modal.Content>

        <Modal.Footer>
          {!isUpdating ? (
            <>
              <LinkButton
                variant="primary"
                onClick={handleClose}
                style={{ alignSelf: "center" }}
              >
                Cancelar
              </LinkButton>

              <LoadingButton
                variant="primary"
                size="large"
                isLoading={isLoading}
                onClick={handleSubmit}
              >
                Salvar
              </LoadingButton>
            </>
          ) : (
            <>
              <WarningLinkButton
                variant="secondary"
                onClick={() => setConfirmationDeleteInstruction(true)}
                style={{ alignSelf: "center" }}
              >
                Excluir instruções
              </WarningLinkButton>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 40,
                }}
              >
                <LinkButton
                  variant="primary"
                  onClick={handleClose}
                  style={{ alignSelf: "center" }}
                >
                  Cancelar
                </LinkButton>

                <LoadingButton
                  variant="primary"
                  size="large"
                  isLoading={isLoading}
                  onClick={handleSubmit}
                >
                  Salvar
                </LoadingButton>
              </div>
            </>
          )}
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}
