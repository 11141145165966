import { useEffect, useState } from "react"

import { Flow, ResignationCard } from "../../../types"

import { OnHoldContent } from "./OnHoldContent"
import { StartProcessContent } from "./StartProcessContent"
import { InterviewContent } from "./InterviewContent"
import { ExamContent } from "./ExamContent"
import { RescissionContent } from "./RescissionContent"
import { SignatureContent } from "./SignatureContent"
import { UpdateContractContent } from "./UpdateContractContent"

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import { Container } from "./styles"

import { trpc } from "@api/client"

export const DoneContent = ({
  card,
  flow,
}: {
  card: ResignationCard
  flow: Flow
}) => {
  const [resignationFlowInfo, setResignationFlowInfo] = useState<any>("")

  const { data: contract, isInitialLoading: isContractLoading } =
    trpc.contract.getContractByFlowCardId.useQuery(
      {
        flowCardId: card._id,
      },
      {
        queryKey: ["contract.getContractByFlowCardId", { flowCardId: card._id }],
        enabled: !!card._id,
        refetchOnWindowFocus: false,
      },
    )

  useEffect(() => {
    if (card) setResignationFlowInfo(card)
  }, [card])

  return (
    <Container>
      {isContractLoading ? (
        <Skeleton variant="rectangular" width={"100%"} height={"200px"} />
      ) : (
        <>
          {card.employee && <OnHoldContent employee={card.employee} />}
          <StartProcessContent resignationFlowInfo={resignationFlowInfo} />
          <InterviewContent resignationFlowInfo={resignationFlowInfo} />
          <ExamContent resignationFlowInfo={resignationFlowInfo} />
          <RescissionContent resignationFlowInfo={resignationFlowInfo} />
          <SignatureContent contract={contract} />
          <UpdateContractContent resignationFlowInfo={resignationFlowInfo} />
        </>
      )}
    </Container>
  )
}
