import { useNavigate } from "react-router-dom"
import { ButtonMenu } from "../ButtonMenu"
import {
  Column,
  Flow,
  FlowCategory,
  HiringCard,
  ResignationCard,
} from "../../../types"
import { StyledFooterBar, StyledButtonContainer } from "./style"
import {
  Button,
  Icons,
  LinkButton,
  Loader,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "../../../utils/dispatchToast"
import { request } from "../../../api/client"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { HIRING_MOVE_COLUMN } from "../../../api/mutations/hiring-card-move-column"
import { RESIGNATION_MOVE_COLUMN } from "../../../api/mutations/resignation-card-move-column"
import {
  ModalDoneAdmission,
  AdmissionWarningModal,
} from "../ModalDoneAdmission"
import React, { useState } from "react"
import { DocumentNode } from "graphql"
import {
  hiringColumnsId,
  hiringFlowColumns,
  resignationColumnsId,
} from "../../../mock/board"

export interface FooterData {
  card: HiringCard | ResignationCard
  flow: Flow
  flowType: FlowCategory
  disableButton?: boolean
}

type QueryMap<T> = {
  [key in FlowCategory]: T
}

const moveColumnQueryType: QueryMap<DocumentNode> = {
  hiring: HIRING_MOVE_COLUMN,
  resignation: RESIGNATION_MOVE_COLUMN,
}

const redirect: QueryMap<string> = {
  hiring: "/flows/hiring",
  resignation: "/flows/resignation",
}

const initialConfigColumn = "Configurações iniciais"
const hiringDoneColumns = ["Admissão concluída", "Concluído"]

type DropdownItemVariant = "active" | "secondary" | "default"

const getDropdownItemVariant = (
  column: Column,
  current: Column,
): DropdownItemVariant => {
  if (column._id === current._id) return "active"
  if (column.position > current.position) return "secondary"

  return "default"
}

type DropdownItemIcon = "IconCheck" | "IconArrowRight" | "IconArrowLeft"

const getDropdownItemIcon = (
  column: Column,
  current: Column,
): DropdownItemIcon => {
  if (column._id === current._id) return "IconCheck"
  if (column.position > current.position) return "IconArrowRight"

  return "IconArrowLeft"
}

const FooterBar = ({ card, flow, flowType, disableButton }: FooterData) => {
  const isMobile = useMediaQuery("(max-width: 768px)")
  const navigate = useNavigate()
  const [modal, setModal] = useState<"warning" | "done" | null>(null)
  const [cbFunction, setCbFunction] = useState(() => () => {})
  const queryClient = useQueryClient()

  const isHiring = flowType === "hiring"
  const hiringCard = isHiring ? (card as HiringCard) : null
  const { deepColumns, actualColumn, nextColumn } = React.useMemo(() => {
    if (!flow?.columns?.length || !card?.columnId)
      return {
        deepColumns: [],
        actualColumn: undefined,
        nextColumn: undefined,
      }

    const index = flow.columns.findIndex((f) => f._id === card.columnId)
    const nextColumn =
      index === flow.columns.length - 1 ? undefined : flow.columns[index + 1]

    return {
      deepColumns: flow.columns,
      actualColumn: flow.columns[index],
      nextColumn,
    }
  }, [flow.columns, card.columnId])

  const { mutate: moveColumn, isLoading: isMovingColumn } = useMutation(
    async ({
      params,
    }: {
      params: {
        flowCardId: string
        newColumnId: string
        newPosition: number
        version: number
      }
    }) => {
      await request(moveColumnQueryType[flowType], { params })
      return params
    },
    {
      onSuccess: (params) => {
        const rightColumnInfo = hiringFlowColumns.find((column) =>
          column.columnsId.includes(params.newColumnId),
        )
        queryClient.invalidateQueries()

        navigate(
          rightColumnInfo
            ? `${rightColumnInfo.route}${card._id}`
            : redirect[flowType],
          {
            replace: true,
          },
        )
      },
      onError: () => {
        dispatchToast({
          content: "Erro ao mover o card para outra coluna",
          type: "error",
        })
      },
    },
  )

  const handleMoveColumn = (column?: Column) => {
    if (!column) return
    const fn = () => {
      moveColumn({
        params: {
          flowCardId: card._id,
          newColumnId: column._id,
          newPosition: 0,
          version: card.version,
        },
      })
    }
    if (!isHiring) {
      return fn()
    }

    const isMissingData =
      !hiringCard?.candidate?.employeeId ||
      !hiringCard?.candidate?.proposalLetterInfo?.hiringDate ||
      !hiringCard?.candidate?.corporateEmail ||
      !hiringCard?.candidate?.documentNumber

    if (!isMissingData) {
      return fn()
    }
    if (
      initialConfigColumn === column.name &&
      !hiringDoneColumns.includes(column.name)
    ) {
      setModal("warning")
      return
    }

    if (
      hiringDoneColumns.includes(column.name) &&
      column.name !== initialConfigColumn
    ) {
      setCbFunction(() => fn)
      setModal("done")
      return
    }
    fn()
  }

  const dropdownOptions: React.ComponentProps<
    typeof ButtonMenu
  >["dropdown"]["options"] = React.useMemo(
    () =>
      actualColumn
        ? deepColumns.map((column) => ({
            text: column.name,
            onClick: () =>
              actualColumn?._id !== column._id && handleMoveColumn(column),
            icon: getDropdownItemIcon(column, actualColumn),
            variant: getDropdownItemVariant(column, actualColumn),
          }))
        : [],
    [card, deepColumns, actualColumn],
  )

  const hideMoveToNextStepButton =
    hiringColumnsId.archived.includes(card.columnId) ||
    hiringColumnsId.done.includes(card.columnId) ||
    resignationColumnsId.archived.includes(card.columnId) ||
    resignationColumnsId.done.includes(card.columnId)

  return (
    <StyledFooterBar>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <LinkButton
          variant="primary"
          style={{ alignSelf: "center" }}
          onClick={() => navigate(redirect[flowType])}
        >
          Voltar
        </LinkButton>
        <StyledButtonContainer>
          <ButtonMenu
            button={{
              size: isMobile ? "small" : "large",
              variant: "secondary",
              text: "Mover para outra etapa",
              style: { alignSelf: "center" },
              disabled: isMovingColumn || disableButton,
            }}
            dropdown={{
              title: "Mover card para fase",
              options: dropdownOptions,
            }}
          />
          {!hideMoveToNextStepButton && (
            <Button
              size={isMobile ? "small" : "large"}
              variant="primary"
              style={{ marginLeft: "24px", alignSelf: "center" }}
              onClick={() => handleMoveColumn(nextColumn)}
              disabled={isMovingColumn || disableButton}
            >
              {isMovingColumn ? (
                <Loader size="extraSmall" variant="secondary" />
              ) : (
                <>
                  Mover para a próxima etapa
                  <Icons name="IconArrowNarrowRight" />
                </>
              )}
            </Button>
          )}
        </StyledButtonContainer>
      </div>
      <AdmissionWarningModal
        isOpen={modal === "warning"}
        handleClose={() => setModal(null)}
      />
      {modal === "done" && hiringCard && (
        <ModalDoneAdmission
          isOpen={true}
          handleClose={() => setModal(null)}
          card={{
            candidateId: hiringCard.candidate._id,
            candidate: {
              name: hiringCard.candidate.name,
              documentNumber: hiringCard.candidate.documentNumber,
              email: hiringCard.candidate.email,
              corporateEmail: hiringCard.candidate.corporateEmail,
              ...(hiringCard?.candidate?.proposalLetterInfo?.hiringDate && {
                proposalLetterInfo: {
                  hiringDate:
                    hiringCard?.candidate?.proposalLetterInfo?.hiringDate,
                },
              }),
              probationPeriod: hiringCard.candidate.probationPeriod,
            },
          }}
          cbConfigure={cbFunction}
          loading={isMovingColumn}
        />
      )}
    </StyledFooterBar>
  )
}

export { FooterBar }
