import gql from "graphql-tag"
import { request } from "../client"
import { IDocumentValidation } from "../../types"

const CANDIDATE_VALIDATE_DOCUMENT = gql`
  mutation ValidateCandidateDocument($params: ValidateCandidateDocumentInput) {
    validateCandidateDocument(params: $params)
  }
`

export const candidateValidateDocument = async (params: {
  candidateId: string
  formGroupId: string
  status: "approved" | "declined" | "waiting"
  statusComment?: string
}) => {
  const { validateCandidateDocument } = await request(
    CANDIDATE_VALIDATE_DOCUMENT,
    { params },
  )
  return validateCandidateDocument as IDocumentValidation[]
}
