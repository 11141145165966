import { TextArea, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import {
  GenericCard,
  StepBodyContentContainer,
  StepContentContainer,
  StepSiderContentContainer,
} from "../../styled"
import { useTheme } from "styled-components"
import { CardContentContainer, CardContentInputContainer } from "./styled"
import { useCreateSectionPageContext } from "../../context/CreateSectionPageContext"

export const SectionInfoStep = () => {
  const theme = useTheme()

  const {
    sectionName,
    setSectionName,
    sectionDescription,
    setSectionDescription,
    sectionNameInputErrorMessage,
    setSectionNameInputErrorMessage,
  } = useCreateSectionPageContext()

  return (
    <>
      <StepContentContainer>
        <StepSiderContentContainer>
          <Typography
            variant="headline7"
            variantColor={theme.colors.secondary[50]}
            weight={700}
          >
            Informações básicas
          </Typography>
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[50]}
            weight={400}
          >
            Dê um nome á seção e descreva o seu objetivo.
          </Typography>
        </StepSiderContentContainer>
        <StepBodyContentContainer>
          <GenericCard>
            <CardContentContainer>
              <Typography
                variant="headline8"
                variantColor={theme.colors.neutral[30]}
                weight={700}
                style={{ marginBottom: theme.spacings.s }}
              >
                Informações básicas
              </Typography>
              <CardContentInputContainer>
                <TextArea
                  maxLength={50}
                  placeholder="Nome da seção"
                  resizable
                  value={sectionName}
                  errorMessage={sectionNameInputErrorMessage}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setSectionNameInputErrorMessage(undefined)
                    setSectionName(e.target.value)
                  }}
                />
                <TextArea
                  maxLength={600}
                  placeholder="Descrição da seção"
                  resizable
                  value={sectionDescription}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setSectionDescription(e.target.value)
                  }}
                />
              </CardContentInputContainer>
            </CardContentContainer>
          </GenericCard>
        </StepBodyContentContainer>
      </StepContentContainer>
    </>
  )
}
