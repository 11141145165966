import { IDocumentValidation } from "./candidate"

export interface FormConfigList {
  extensionId?: string | null
  name: string
  numberOfGroups: number
  _id: string
  default: boolean
}

export interface FormConfig {
  _id: string
  name: string
  formGroups: FormGroup[]
  companyId?: string
  baseId?: string
  extensionId?: string | null
  type: "base" | "extension"
}

export interface FormGroup {
  _id: string
  title?: string
  order?: number
  enabled?: boolean
  category: "employee" | "dependent"
  type?: "base" | "extension"
  description?: string
  fields?: FieldConfig[]
  fillInstruction?: {
    html: string
    json: string
  }
}

export interface FieldConfig {
  fieldId: string
  customFieldId: string
  documentTypeId?: string
  formGroupId: string
  order?: number
  fieldType: FieldType
  options?: string[]
  component: Component
  placeholder: string
  fieldRule?: FieldRule
  type: "base" | "extension"
  enabled: boolean
}

export enum FieldType {
  Text = "text",
  Number = "number",
  Date = "date",
  Boolean = "boolean",
  File = "file",
  Array = "array",
}

export enum Component {
  ShortText = "shortText",
  LongText = "longText",
  Number = "number",
  Attachment = "attachment",
  Link = "link",
  DatePicker = "datePicker",
  TimePicker = "timePicker",
  Select = "select",
  MultiSelect = "multiSelect",
  Checkbox = "checkbox",
}

export interface FieldRule {
  validations?: Comparison[]
  enable?: Comparison[]
  required?: boolean
  fieldId: string
}

export enum ComparisonType {
  Equal = "equal",
  NotEqual = "notEqual",
  GreaterThan = "greaterThan",
  GreaterThanOrEqual = "greaterThanOrEqual",
  LesserThan = "lesserThan",
  LesserThanOrEqual = "lesserThanOrEqual",
  Includes = "includes",
  NotIncludes = "notIncludes",
  Exists = "exists",
  NotExists = "notExists",
  Or = "or",
  And = "and",
}

export interface Comparison {
  _id?: string
  compared?: CompareField
  comparedTo?: CompareField
  comparisonType: ComparisonType
  comparisons?: Comparison[]
}

export interface CompareField {
  _id?: string
  name?: string
  sensitive?: boolean
  type: FieldType
  isFixedValue?: boolean
  value?: any
}

export interface FieldValue extends FieldConfig {
  value?: any
  candidateId: string
}

export interface ConfirmationData {
  candidateId: string
  formConfigId: string
  extensionId?: string
  candidateFields: FieldValue[]
  documentFields: ConfirmationDocumentsWithValue[]
  dependents?: ConfirmationDependent[]
}

export interface ConfirmationDependent {
  _id: any
  name: string
  active: boolean
  kinship: string
  birthDate: string
  candidateId: string
  formConfig: string
  extensionId: string
  documents: ConfirmationDocumentsWithValue[]
}

export interface ConfirmationDocumentsWithValue {
  _id: string
  formConfigId: string
  extensionId?: string
  name: string
  title: string
  description?: string
  enabled: boolean
  fields: FieldValue[]
  validation?: IDocumentValidation
}
