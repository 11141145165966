import React from "react"
import { Upload } from "../../../../components"
import { ModelDownload } from "../components"
import { Description, ModelWrapper, Title } from "../styles"
import { MassCandidatesAcceptedFilesTypes, S3File } from "../../../../types"
import { globalContext } from "../../../../context"
import { useMutation } from "@tanstack/react-query"
import { api } from "../../../../api"
import { massCandidateContext } from "../../AddMassCandidates"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "../../../../utils/dispatchToast"
import { useNavigate } from "react-router-dom"
import { Grid } from "@mui/material"
import { getFileExtension } from "src/utils"
import { trpc } from "@api/client"
import { useTracking } from "@utils/useTracking"

export const SendData = () => {
  const [file, setFile] = React.useState<S3File | undefined>(undefined)
  const [fileError, setFileError] = React.useState<boolean>(false)

  const { companyId, employeeId } = React.useContext(globalContext)
  const { flow, refetchProcess, setFooterButtons } =
    React.useContext(massCandidateContext)
  const navigate = useNavigate()

  const tracking = useTracking()

  const { data: archiveModels, isInitialLoading: isLoadingArchiveModels } =
    trpc.massCandidate.getAddMassCandidateModel.useQuery(
      {
        flowType: flow?.subcategory || "clt",
      },
      { enabled: !!flow },
    )

  const { mutateAsync: createMassCandidateProcess, isLoading: isCreating } =
    useMutation(api.mutation.hiring.massCandidate.createProcess)

  React.useEffect(() => {
    setFooterButtons({
      primary: {
        icon: "IconArrowRight",
        iconSide: "right",
        text: "Continuar",
        props: {
          onClick: () => {
            tracking.trackEvent({
              name: "hiring_add_mass_candidates_clicked_create_candidates_button",
            })
            createProcess()
          },
          loading: isCreating,
        },
      },
      tertiary: {
        text: "Cancelar",
        props: {
          onClick: () => navigate("/flows/hiring"),
        },
      },
    })
  }, [file])

  async function createProcess() {
    if (!file?.path) {
      dispatchToast({
        content: "Por favor preencha o arquivo de importação",
        type: "error",
      })
      return
    }

    const fileType = getFileExtension(file.path)
    if (
      !fileType ||
      !Object.values(MassCandidatesAcceptedFilesTypes)?.includes(
        fileType as MassCandidatesAcceptedFilesTypes,
      )
    ) {
      setFileError(true)
      dispatchToast({
        content:
          "Extensão do arquivo incorreta, por favor tente outro documento",
        type: "error",
      })
    }

    try {
      await createMassCandidateProcess({
        companyId: companyId || "",
        flowId: flow._id,
        notificationEmployeeId: employeeId || "",
        fileKey: file.path,
        fileType: fileType as MassCandidatesAcceptedFilesTypes,
      })
      refetchProcess()
      return true
    } catch (err: any) {
      dispatchToast({
        content: err.message,
        type: "error",
      })
    }
  }

  return (
    <Grid item sm={12} md={7} lg={9}>
      <div
        style={{
          alignItems: "center",
          padding: "32px 40px",
          border: "1px solid #EBE6E9",
          borderRadius: "10px",
          marginTop: "40px",
        }}
      >
        <Title variant="headline8">
          Com qual modelo de arquivo deseja prosseguir?
        </Title>
        <Description variant="body4">
          Baixe o modelo da Flash e preencha-o com as informações dos
          candidatos.
        </Description>

        <ModelWrapper>
          {!isLoadingArchiveModels && (archiveModels || [])?.length > 0
            ? archiveModels?.map((archive, idx) => (
                <ModelDownload
                  key={idx}
                  fileType={archive.type}
                  presignedFileUrl={archive.s3PresignedValue || ""}
                />
              ))
            : [0, 1, 2].map((key) => (
                <Skeleton key={key} height={80} width={"100%"} />
              ))}
        </ModelWrapper>
      </div>
      <div
        style={{
          alignItems: "center",
          padding: "32px 40px",
          border: "1px solid #EBE6E9",
          borderRadius: "10px",
          marginTop: "40px",
        }}
      >
        <Title variant="headline8">Envie seu arquivo</Title>
        <Description variant="body4">
          Com o arquivo em mãos, agora é só anexar abaixo!
        </Description>
        <div
          style={{
            marginTop: "32px",
          }}
        >
          <Upload
            value={file}
            label={`Seu arquivo`}
            accept={["xls", "xlsx", "csv", "txt"]}
            folder={`addMassCandidates/${companyId}/${flow._id}`}
            canDelete={true}
            onUpload={async (file) => setFile(file)}
            onRemove={async () => setFile(undefined)}
            error={fileError}
          />
        </div>
      </div>
    </Grid>
  )
}
