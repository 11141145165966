import { useMutation } from "@tanstack/react-query"
import { request } from "../api/client"

import { HIRING_MOVE_COLUMN } from "../api/mutations/hiring-card-move-column"
import { HIRING_RESET_REFUSED_FIELDS } from "../api/mutations/hiring-reset-refused-fields"
import { HIRING_ACCEPT_PROPOSAL } from "../api/mutations/hiring-accept-proposal"
import { HIRING_NEXT_COLUMN } from "../api/mutations/hiring-card-move-next-column"
import { HIRING_UPDATE_CARD_STATUS } from "../api/mutations/hiring-update-card-status"
import { CREATE_TOKEN } from "../api/mutations/create-token"
import { SEND_PROPOSAL_LETTER } from "../api/mutations/send-proposal-letter"
import { SET_CANDIDATE_FIELD } from "../api/mutations/set_candidate_field"
import { HIRING_REFUSE_PROPOSAL } from "../api/mutations/hiring-refuse-proposal"

export const useBFF = () => {
  const setCandidateField = useMutation(
    async ({ candidateId, values }: { candidateId: string; values: any }) => {
      await request(SET_CANDIDATE_FIELD, { candidateId, values })
    },
  )

  const hiringMoveColumn = useMutation(async ({ params }: { params: any }) => {
    await request(HIRING_MOVE_COLUMN, { params })
  })

  const hiringUpdateCardStatus = useMutation(
    async ({ params }: { params: any }) => {
      await request(HIRING_UPDATE_CARD_STATUS, { params })
    },
  )

  const hiringResetRefusedFields = useMutation(
    async ({ candidateId }: { candidateId: string }) => {
      await request(HIRING_RESET_REFUSED_FIELDS, { candidateId })
    },
  )

  const hiringAcceptProposal = useMutation(
    async ({ candidateId }: { candidateId: string }) => {
      await request(HIRING_ACCEPT_PROPOSAL, { candidateId })
    },
  )

  const hiringRefuseProposal = useMutation(
    async ({
      candidateId,
      refusedReason,
    }: {
      candidateId: string
      refusedReason?: string | null
    }) => {
      await request(HIRING_REFUSE_PROPOSAL, { candidateId, refusedReason })
    },
  )

  const hiringNextColumn = useMutation(
    async ({ flowCardId }: { flowCardId: string }) => {
      await request(HIRING_NEXT_COLUMN, { flowCardId })
    },
  )

  const createToken = useMutation(
    async ({
      params,
    }: {
      params: {
        cardId: string
        employeeId?: string
        candidateId?: string
        name?: string
        type?: string
        category?: string
        subcategory?: string
      }
    }) => {
      const { createToken: data } = await request(CREATE_TOKEN, { params })
      return data
    },
  )

  const sendProposalLetter = useMutation(
    async ({
      params,
    }: {
      params: {
        candidateId: string
        flowCardId?: string
        columnId?: string
        subject: string
        token: string
      }
    }) => {
      await request(SEND_PROPOSAL_LETTER, { params })
    },
  )

  return {
    hiring: {
      moveColumn: hiringMoveColumn,
      updateCardStatus: hiringUpdateCardStatus,
      resetRefusedFields: hiringResetRefusedFields,
      acceptProposal: hiringAcceptProposal,
      refuseProposal: hiringRefuseProposal,
      nextColumn: hiringNextColumn,
    },
    candidate: {
      sendProposalLetter,
      setCandidateField,
    },
    resignation: {},
    token: {
      createToken,
    },
  }
}
