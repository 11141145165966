import { Icons, IconTypes } from "@flash-tecnologia/hros-web-ui-v2"
import { MiniCardContainer } from "./styles"

export type CardType = {
  children: React.ReactNode
  active?: boolean
  onClick?: (e: any) => void
  icon?: IconTypes
  dataOnboarding?: string
}

export const MiniCard = ({
  children,
  active,
  onClick,
  icon,
  dataOnboarding,
}: CardType) => {
  return (
    <MiniCardContainer
      active={active || false}
      onClick={onClick}
      data-onboarding={dataOnboarding}
    >
      {children}

      {icon && <Icons name={icon} color="#D1C7CE" fill="transparent" />}
    </MiniCardContainer>
  )
}
