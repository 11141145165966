import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css, useTheme } from "styled-components"

export type ColumnBadgeProps = {
  text: string
  onClick?: () => void
  info?: string | number
  active?: boolean
  dataOnboarding?: string
}

const Column = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 8px 16px 8px 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.secondary[95]};
      border: 1px solid ${({ theme }) => theme.colors.primary};
    `}

  >span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const Tag = styled.div<{ active: boolean }>`
  background-color: ${({ theme, active }) =>
    active ? "none" : theme.colors.neutral[90]};
  color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.neutral[10]};
  font-size: 12px;
  border-radius: 24px;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ColumnBadge = ({
  text,
  info,
  onClick,
  active = false,
  dataOnboarding,
}: ColumnBadgeProps) => {
  const theme = useTheme()
  return (
    <Column active={active} onClick={onClick} data-onboarding={dataOnboarding}>
      <Typography
        variant="body4"
        variantColor={
          active ? theme.colors.secondary[50] : theme.colors.neutral[50]
        }
        weight={600}
      >
        {text}
      </Typography>

      {info ? <Tag active={active}>{info}</Tag> : <></>}
    </Column>
  )
}
