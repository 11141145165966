import styled from "styled-components"

export const CardAddSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  gap: ${({ theme }) => theme.spacings.xs};
`

export const DividerLine = styled.div`
  border: 1px solid #ebe6e9;
  height: 0;
  width: 100%;
`

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 24px 0;
`

export const CancelButton = styled.div`
  cursor: pointer;
  color: #fe2b8f;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 2px solid #fe2b8f;
`
export const AddSectionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding: 24px 24px 0px 24px;;
`

export const AddSectionActionContainer = styled.div`
  padding: 0px 24px 24px 24px;
`