import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import {
  GenericCard,
  StepBodyContentContainer,
  StepContentContainer,
  StepSiderContentContainer,
} from "../../styled"
import { useTheme } from "styled-components"
import {
  CardContentContainer,
  EmptyFieldListContainer,
  FieldLineContainer,
} from "./styled"
import { useCreateSectionPageContext } from "../../context/CreateSectionPageContext"
import { useState } from "react"
import { SelectCustomFieldModal } from "./components/SelectCustomFieldModal"
import { RenderField } from "@components/common"
import EmptyListSvg from "../EmptyListSvg"

export const AddFieldsStep = () => {
  const theme = useTheme()

  const [isSelectCustomFieldModalVisible, setIsSelectCustomFieldModalVisible] =
    useState(false)

  const { fields, setFields } = useCreateSectionPageContext()

  return (
    <>
      <StepContentContainer>
        <StepSiderContentContainer>
          <Typography
            variant="headline7"
            variantColor={theme.colors.secondary[50]}
            weight={700}
          >
            Campos
          </Typography>
          <Typography
            variant="body3"
            variantColor={theme.colors.neutral[50]}
            weight={400}
          >
            Inclua os campos que pertencem a essa seção. Os campos podem ser
            textos, números, datas, seleções e até anexos.
          </Typography>
        </StepSiderContentContainer>
        <StepBodyContentContainer>
          <GenericCard>
            <CardContentContainer>
              <Typography
                variant="headline8"
                variantColor={theme.colors.neutral[30]}
                weight={700}
              >
                Adicionar campos à seção
              </Typography>
              <Typography
                variant="body4"
                variantColor={theme.colors.neutral[50]}
                weight={400}
              >
                Adicione um campo para a nova seção.
              </Typography>
              <Button
                size="large"
                variant="primary"
                onClick={() => setIsSelectCustomFieldModalVisible(true)}
                style={{ width: "100%" }}
              >
                <Icons name="IconLink" /> Adicionar um campo existente
              </Button>
            </CardContentContainer>
          </GenericCard>
          {fields.length ? (
            <GenericCard>
              <CardContentContainer>
                <Typography
                  variant="headline8"
                  variantColor={theme.colors.neutral[30]}
                  weight={700}
                >
                  Campos
                </Typography>
                <Typography
                  variant="body4"
                  variantColor={theme.colors.neutral[50]}
                  weight={400}
                >
                  Os campos adicionados irão refletir apenas nesta seção.
                </Typography>
                {fields.map((field) => (
                  <FieldLineContainer>
                    <RenderField field={{ ...field, enabled: false }} />
                    <Icons
                      name="IconTrash"
                      onClick={() => {
                        setFields((prev) => {
                          const filteredFields = prev.filter(
                            ({ order }) => order !== field.order,
                          )

                          return filteredFields.map((filteredField, idx) => ({
                            ...filteredField,
                            order: idx,
                          }))
                        })
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </FieldLineContainer>
                ))}
              </CardContentContainer>
            </GenericCard>
          ) : (
            <EmptyFieldListContainer>
              <EmptyListSvg />
              <Typography
                variant="body4"
                variantColor={theme.colors.neutral[50]}
                weight={600}
              >
                Você ainda não adicionou nenhum campo ou anexo
              </Typography>
            </EmptyFieldListContainer>
          )}
        </StepBodyContentContainer>
      </StepContentContainer>
      {isSelectCustomFieldModalVisible && (
        <SelectCustomFieldModal
          closeModal={() => setIsSelectCustomFieldModalVisible(false)}
        />
      )}
    </>
  )
}
