import React from "react"
import { SearchField } from "../SearchField"
import { OrgchartInfoBox } from "./OrgchartInfoBox"
import { LeadDecisionToggle } from "./LeadDecisionToggle"
import { Container, OptionsDiv, StyledText } from "./styles"
import { trpc } from "@api/client"

export type OrgchatLeader = {
  _id: string
  value: string
  label: string
}

export const LeadershipHandler = ({
  companyId,
  setSelectedOption,
  setSelectedLeader,
  selectedOption,
}: {
  companyId: string
  setSelectedOption: any
  setSelectedLeader: any
  selectedOption: string
}) => {
  const { data: leaders, isInitialLoading: isLeaderLoading } =
    trpc.orgchart.getLeadersFromOrgchart.useQuery(undefined, {
      retry: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    })

  const leaderOptions =
    leaders
      ?.filter((leader) => leader.label)
      ?.map((leader) => ({
        label: leader.label,
        value: leader._id,
      })) || []

  const radioOptions = ["Substituir líder", "Não substituir líder"]

  return (
    <Container>
      <StyledText variant="body3" weight={700}>
        A pessoa selecionada é líder de uma equipe. Selecione um substituto e
        evite que os membros da equipe sejam desvinculados do organograma
        empresarial.
      </StyledText>
      <OrgchartInfoBox />
      <OptionsDiv>
        {radioOptions.map((option, index) => {
          return (
            <React.Fragment key={index}>
              <LeadDecisionToggle
                text={option}
                checked={selectedOption == option}
                onOptionSelect={() => setSelectedOption(option)}
              />
            </React.Fragment>
          )
        })}
      </OptionsDiv>
      {selectedOption == "Substituir líder" && (
        <SearchField
          label="Selecione o novo líder"
          onSearchChange={(_, value) => {
            setSelectedLeader(value)
          }}
          loading={isLeaderLoading}
          onInputChange={() => {}}
          options={leaderOptions}
        />
      )}
    </Container>
  )
}
