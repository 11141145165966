import {
  Button,
  Icons,
  Loader,
  Tag,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { api } from "../../../api"
import { Contract, ResignationCard, SignType, Signature } from "../../../types"
import dispatchToast from "../../../utils/dispatchToast"
import styled, { useTheme } from "styled-components"
import { InlineTextContainer, TextContainer } from "./styles"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  alignitems: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacings.xs} ${theme.spacings.s}`};
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral90}`};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs1};
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
`

export interface ContractSignatureProps {
  card: ResignationCard
  contract: Contract
  signature: Signature
  nextSignature: Signature | undefined
}
export const ContractSignature: React.FC<ContractSignatureProps> = ({
  contract,
  signature,
  card,
}) => {
  const queryClient = useQueryClient()

  const {
    mutateAsync: resendSignRequest,
    isLoading: isLoadingResendSignRequest,
  } = useMutation(api.mutation.hiring.contract.resendSignRequest, {
    onSuccess: () => {
      dispatchToast({
        content: "Contrato reenviado!",
        type: "success",
      })

      queryClient.invalidateQueries()
      queryClient.invalidateQueries()
    },
    onError: (err: any) => {
      dispatchToast({
        type: "error",
        content: err.message || "Ocorreu um erro ao reenviar o contrato.",
      })
    },
  })

  const theme = useTheme()

  let tagLabel = ""
  switch (signature.type) {
    case SignType.candidate:
    case SignType.employee:
      tagLabel = "Pessoa"
      break
    case SignType.company:
      tagLabel = "Empresa"
      break
    case SignType.witness:
      tagLabel = "Testemunha"
      break
    default:
      break
  }

  const statusColor =
    signature?.status === "Signed"
      ? theme.colors.success40
      : theme.colors.error40

  const canResend = signature?.status !== "Signed"
  return (
    <Container>
      <SubContainer>
        <InlineTextContainer>
          <Typography variant="body3" weight={700}>
            {signature.name}
          </Typography>
          <Tag variant="disabled" disabled>
            {tagLabel}
          </Tag>
        </InlineTextContainer>
        <Typography variant="body4">{signature.email}</Typography>
        <TextContainer>
          <Icons
            name={signature?.status === "Signed" ? "IconCheck" : "IconClock"}
            fill="transparent"
            size={16}
            stroke={statusColor}
          />
          <Typography variant="body4" variantColor={statusColor}>
            {signature?.status === "Signed"
              ? `Assinado em: ${
                  signature?.signedAt
                    ? dayjs(signature?.signedAt).format("DD/MM/YYYY [às] HH:mm")
                    : ""
                }`
              : "Aguardando assinatura"}
          </Typography>
        </TextContainer>
        <TextContainer>
          <Icons name="IconDevices" fill="transparent" size={16} />
          <Typography variant="body4">
            IP: {signature?.ip || "Não registrado"}
          </Typography>
        </TextContainer>
      </SubContainer>
      {canResend ? (
        <Button
          size="large"
          variant="primary"
          disabled={isLoadingResendSignRequest}
          onClick={async () => {
            await resendSignRequest({
              cardId: card._id,
              columnId: card.columnId,
              companyId: card.companyId,
              contractId: contract._id,
              signature: contract.signature,
              signatureToReset: {
                type: signature?.type,
                email: signature?.email,
                name: signature?.name,
              },
              category: "resignation",
            })
          }}
          style={{
            alignSelf: "center",
            marginLeft: "20px",
            marginTop: "0px",
          }}
        >
          {isLoadingResendSignRequest ? (
            <Loader variant="secondary" size="small" />
          ) : (
            <>
              Reenviar contrato{" "}
              <Icons name="IconArrowRight" size={24} fill="transparent" />
            </>
          )}
        </Button>
      ) : (
        <></>
      )}
    </Container>
  )
}
