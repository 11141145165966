import { SVGProps } from "react"

export default function EmptyListSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="105"
      height="126"
      viewBox="0 0 105 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M52.4995 117.409C81.4445 117.409 104.909 93.9445 104.909 64.9995C104.909 36.0544 81.4445 12.5898 52.4995 12.5898C23.5544 12.5898 0.0898438 36.0544 0.0898438 64.9995C0.0898438 93.9445 23.5544 117.409 52.4995 117.409Z"
        fill="#EBE6E9"
      />
      <g filter="url(#filter0_d_1837_36563)">
        <path
          d="M82.5474 42.6387H22.451C20.5213 42.6387 18.957 44.203 18.957 46.1326V119.506C18.957 121.436 20.5213 123 22.451 123H82.5474C84.4771 123 86.0414 121.436 86.0414 119.506V46.1326C86.0414 44.203 84.4771 42.6387 82.5474 42.6387Z"
          fill="white"
        />
      </g>
      <path
        d="M45.5112 53.1191H27.3425C26.1847 53.1191 25.2461 54.0577 25.2461 55.2155C25.2461 56.3733 26.1847 57.3119 27.3425 57.3119H45.5112C46.669 57.3119 47.6075 56.3733 47.6075 55.2155C47.6075 54.0577 46.669 53.1191 45.5112 53.1191Z"
        fill="#D1C7CE"
      />
      <path
        d="M58.0895 62.2031H27.3425C26.1847 62.2031 25.2461 63.1417 25.2461 64.2995C25.2461 65.4573 26.1847 66.3959 27.3425 66.3959H58.0895C59.2473 66.3959 60.1859 65.4573 60.1859 64.2995C60.1859 63.1417 59.2473 62.2031 58.0895 62.2031Z"
        fill="#EBE6E9"
      />
      <path
        d="M45.5112 71.9883H27.3425C26.1847 71.9883 25.2461 72.9269 25.2461 74.0847C25.2461 75.2425 26.1847 76.1811 27.3425 76.1811H45.5112C46.669 76.1811 47.6075 75.2425 47.6075 74.0847C47.6075 72.9269 46.669 71.9883 45.5112 71.9883Z"
        fill="#D1C7CE"
      />
      <path
        d="M58.0895 81.0723H27.3425C26.1847 81.0723 25.2461 82.0108 25.2461 83.1687C25.2461 84.3265 26.1847 85.265 27.3425 85.265H58.0895C59.2473 85.265 60.1859 84.3265 60.1859 83.1687C60.1859 82.0108 59.2473 81.0723 58.0895 81.0723Z"
        fill="#EBE6E9"
      />
      <path
        d="M45.5112 90.8555H27.3425C26.1847 90.8555 25.2461 91.7941 25.2461 92.9519C25.2461 94.1097 26.1847 95.0482 27.3425 95.0482H45.5112C46.669 95.0482 47.6075 94.1097 47.6075 92.9519C47.6075 91.7941 46.669 90.8555 45.5112 90.8555Z"
        fill="#D1C7CE"
      />
      <path
        d="M58.0895 99.9395H27.3425C26.1847 99.9395 25.2461 100.878 25.2461 102.036C25.2461 103.194 26.1847 104.132 27.3425 104.132H58.0895C59.2473 104.132 60.1859 103.194 60.1859 102.036C60.1859 100.878 59.2473 99.9395 58.0895 99.9395Z"
        fill="#EBE6E9"
      />
      <g filter="url(#filter1_d_1837_36563)">
        <path
          d="M82.5474 7H22.451C20.5213 7 18.957 8.56431 18.957 10.494V31.4578C18.957 33.3875 20.5213 34.9518 22.451 34.9518H82.5474C84.4771 34.9518 86.0414 33.3875 86.0414 31.4578V10.494C86.0414 8.56431 84.4771 7 82.5474 7Z"
          fill="#9F939B"
        />
      </g>
      <path
        d="M45.5112 14.6875H27.3425C26.1847 14.6875 25.2461 15.6261 25.2461 16.7839C25.2461 17.9417 26.1847 18.8803 27.3425 18.8803H45.5112C46.669 18.8803 47.6075 17.9417 47.6075 16.7839C47.6075 15.6261 46.669 14.6875 45.5112 14.6875Z"
        fill="#D1C7CE"
      />
      <path
        d="M58.0895 23.7715H27.3425C26.1847 23.7715 25.2461 24.7101 25.2461 25.8679C25.2461 27.0257 26.1847 27.9643 27.3425 27.9643H58.0895C59.2473 27.9643 60.1859 27.0257 60.1859 25.8679C60.1859 24.7101 59.2473 23.7715 58.0895 23.7715Z"
        fill="#EBE6E9"
      />
      <defs>
        <filter
          id="filter0_d_1837_36563"
          x="14.7643"
          y="36.3495"
          width="75.4715"
          height="88.7469"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2.09639" />
          <feGaussianBlur stdDeviation="2.09639" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1837_36563"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1837_36563"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1837_36563"
          x="14.7643"
          y="0.710844"
          width="75.4715"
          height="36.3367"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2.09639" />
          <feGaussianBlur stdDeviation="2.09639" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1837_36563"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1837_36563"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
