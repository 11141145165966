import { Button, Toggle } from "@flash-tecnologia/hros-web-ui-v2"
import { useNavigate } from "react-router-dom"
import { IFormConfig, IFormGroup } from "../../../../types"
import {
  IconButtonPopover,
  PopoverButton,
  PopoverType,
} from "../../../common/IconButtonPopover"
import {
  CardContainer,
  Content,
  Actions,
  Title,
  Description,
  Badge,
  TitleContainer,
  Row,
  ToggleText,
} from "./styles"
import { ModalDocumentInstructions } from "../../../common"
import { useState } from "react"
import { createSegmentTrack } from "../../../../utils"
import { useMutation } from "@tanstack/react-query"
import { api } from "../../../../api"

export type DocumentCardProps = {
  flowId: string
  formConfig: IFormConfig
  section: IFormGroup
  handleUpdateSection: (
    updateFormGroup: IFormGroup,
    successMessage?: string,
  ) => void
  dataOnboarding?: string
}

export const DocumentCard = ({
  formConfig,
  section,
  flowId,
  handleUpdateSection,
  dataOnboarding,
}: DocumentCardProps) => {
  const [isInstructionModalOpen, setIsInstructionModalOpen] =
    useState<boolean>(false)
  const navigate = useNavigate()

  const { _id, title, description, enabled, type } = section

  const { mutateAsync: getFlowSubcategoryByFlowId } = useMutation(
    api.mutation.hiring.flow.getFlowSubcategoryByFlowId,
  )

  const isAllFieldsRequired = section.fields?.every(
    (field) => field.fieldRule?.required,
  )

  const handleChangeSectionRequired = (_, checked) => {
    const updatedFields = section.fields?.map((field) => ({
      ...field,
      fieldRule: {
        ...field.fieldRule,
        required: checked,
        fieldId: field.fieldRule?.fieldId ?? "",
      },
    }))

    const updatedFormGroup: IFormGroup = {
      ...section,
      fields: updatedFields,
    }

    const message = checked
      ? "Campos requeridos marcados com sucesso!"
      : "Campos requeridos desmarcados com sucesso!"

    handleUpdateSection(updatedFormGroup, message)
  }

  const handleChangeSectionEnabled = () => {
    const updatedFormGroup: IFormGroup = {
      ...section,
      enabled: !enabled,
    }

    const message = enabled
      ? "Seção ocultada com sucesso!"
      : "Seção habilitada com sucesso!"

    handleUpdateSection(updatedFormGroup, message)
  }

  const handleOpenDocument = (e: any) => {
    const baseOrExtensionId = formConfig.extensionId || formConfig._id
    navigate(
      `/flows/settings/${flowId}/documents/${baseOrExtensionId}/section/${_id}`,
    )
  }

  const hideSection: PopoverButton = {
    text: enabled ? "Ocultar seção" : "Habilitar seção",
    variant: "normal",
    icon: enabled ? "IconEyeOff" : "IconEye",
    handleClick: async () => {
      try {
        const flowInfo = await getFlowSubcategoryByFlowId({ flowId })
        createSegmentTrack({
          track: `company_hiring_${
            flowInfo.subcategory || "clt"
          }_documentlisttemplates_hidesections_clicked`,
        })
      } catch (err: any) {
        console.error(err)
      }

      handleChangeSectionEnabled()
    },
  }

  const fillInstruction: PopoverButton = {
    text: "Instruções de preenchimento",
    variant: "normal",
    icon: "IconTextPlus",
    handleClick: async () => {
      try {
        const flowInfo = await getFlowSubcategoryByFlowId({ flowId })
        createSegmentTrack({
          track: `company_hiring_${
            flowInfo.subcategory || "clt"
          }_documentlisttemplates_fillinginstructions_clicked`,
        })
      } catch (err: any) {
        console.error(err)
      }

      setIsInstructionModalOpen(true)
    },
  }

  const popover: PopoverType = !enabled
    ? { buttons: [hideSection] }
    : { buttons: [hideSection, fillInstruction] }

  return (
    <CardContainer disabled={!enabled} data-onboarding={dataOnboarding}>
      <Content>
        <TitleContainer>
          <Title variant="headline8">{title}</Title>
          {type === "base" && <Badge>Seção padrão</Badge>}
        </TitleContainer>

        <Description variant="body4">{description}</Description>

        <Row role="presentation">
          <Toggle
            checked={isAllFieldsRequired}
            onChange={handleChangeSectionRequired}
            disabled={!enabled}
          />
          <ToggleText>Preenchimento obrigatório</ToggleText>
        </Row>
      </Content>

      <Actions>
        <Button
          style={{ marginRight: 25 }}
          variant="secondary"
          size="medium"
          onClick={handleOpenDocument}
          disabled={!enabled}
        >
          Visualizar
        </Button>

        <IconButtonPopover
          variant="filled"
          icon="IconDotsVertical"
          size="medium"
          popover={popover}
        />
      </Actions>

      {setIsInstructionModalOpen && (
        <ModalDocumentInstructions
          isOpen={isInstructionModalOpen}
          handleClose={() => {
            setIsInstructionModalOpen(false)
          }}
          handleExtension={(newExtensionId) => {
            if (newExtensionId !== formConfig.extensionId) {
              navigate(`/flows/settings/${flowId}/documents/${newExtensionId}`)
            }
          }}
          formConfig={formConfig}
          section={section}
        />
      )}
    </CardContainer>
  )
}
