import {
  Card as ICard,
  HiringCard as IHiringCard,
  ResignationCard as IResignationCard,
} from "bff/src/types/hiring"
import { IURL } from "../types"
import { Candidate, ProbationPeriod } from "./hiring"

type StatusHistory = {
  status: string
  columnId: string
}

export type EmailLink = {
  name: string
  columnId: string
  link: string
  expired?: boolean
}

export type ChecklistCard = {
  columnId: string
  items: {
    checked: boolean
    label: string
  }[]
}

export type S3File = {
  key: string
  path: string
  value?: string
  type: "internal" | "external"
}

export interface Author {
  _id: string
  name: string
  profilePicture: IURL
}

export interface Comment {
  message: string
  authorEmployeeId: string
  authorName: string
  sentDate: string
  timeSince: string
  profilePicture?: IURL
}

export interface Follower {
  _id: string
  name: string
  profilePicture?: IURL
}

export enum AdmissionalExamStatus {
  sent = "sent",
  resent = "resent",
  filled = "filled",
  declined = "declined",
  approved = "approved",
}

export interface AdmissionalExam {
  validation?: {
    status: "approved" | "declined" | "waiting"
    statusComment: string
  }
  medicalReferral?: S3File
  limitDate?: string
  date?: string
  status?: AdmissionalExamStatus
  file?: S3File
}

export type Card = ICard

export type HiringCard = IHiringCard & {
  employeeId: string | null // This prop should only be available from Get Flow By Id
  hiringDate: string | null // This prop should only be available from Get Flow By Id
  corporateEmail: string | null // This prop should only be available from Get Flow By Id
  role: { _id: string; name: string } | null // This prop should only be available from Get Flow By Id
  leader: { _id: string; name: string } | null // This prop should only be available from Get Flow By Id
  probationPeriod: ProbationPeriod | null // This prop should only be available from Get Flow By Id
  // This prop is comming from BFF not from the entity itself
  candidate: Candidate
}

export type ResignationCard = IResignationCard
