import { useOutletContext, useSearchParams } from "react-router-dom"
import { Flow } from "../../../types"
import { useState, useMemo } from "react"
import { ColumnWrapper, Container, ContentWrapper } from "./styles"
import { Icons, Tooltip, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import ManageChecklist from "../../../components/screen/Checklist/ManageChecklist"
import { hiringColumnsId, resignationColumnsId } from "../../../mock/board"
import { ColumnBadge } from "../../../components"
import { trpc } from "@api/client"
import { PendoEnum, replacePendoVariable } from "@pendo"

const hiringLabels = { clt: "CLT", pj: "PJ", internship: "Estágio" }

const ChecklistTemplate = () => {
  const [searchParams] = useSearchParams()
  const [flow] = useOutletContext<[flow: Flow]>()
  const columnFromQueryString = searchParams.get("column")
  const [activeColumn, setActiveColumn] = useState<string | undefined>(
    columnFromQueryString ||
      (flow?.columns?.length ? flow?.columns[0]?._id : undefined),
  )

  const { data: checklistList } =
    trpc.modelChecklist.getChecklistTemplate.useQuery(
      { flowId: flow._id },
      {
        enabled: !!flow,
        refetchOnWindowFocus: false,
      },
    )

  const hiringType = useMemo(() => flow.subcategory || "clt", [flow])

  const checklist = checklistList?.find(
    (checklist) => checklist.columnId === activeColumn,
  )

  const inactivesColumns = [
    ...resignationColumnsId.done,
    ...resignationColumnsId.archived,
    ...hiringColumnsId.archived,
    ...hiringColumnsId.done,
  ]
  const filteredColumns = flow?.columns?.filter(
    (column) => !inactivesColumns.includes(column._id),
  )

  return (
    <Container>
      <ColumnWrapper>
        <Typography variant="headline7" style={{ color: "#F20D7A" }}>
          Templates de checklist
        </Typography>
        <Typography
          variant="body3"
          style={{ color: "#83727D", margin: "10px 0px" }}
        >
          Crie padrões de checklists para cada etapa do seu processo de admissão{" "}
          {hiringLabels[hiringType]}.
        </Typography>

        {filteredColumns?.map((column, columnIndex) => (
          <ColumnBadge
            key={columnIndex}
            active={activeColumn === column._id}
            onClick={() => setActiveColumn(column._id)}
            text={column.name}
            dataOnboarding={replacePendoVariable(
              PendoEnum.MODEL_CHECKLIST_TAB_COLUMN,
              [{ name: "$column", value: columnIndex.toString() }],
            )}
          />
        ))}
      </ColumnWrapper>

      <ContentWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="headline7" style={{ color: "#53464F" }}>
            Checklist de tarefas
          </Typography>
          <Tooltip
            title="O template será automaticamente atribuído a todas as pessoas que entrarem nesta etapa."
            placement="top-start"
          >
            <span>
              <Icons
                name="IconInfoCircle"
                style={{ color: "#53464F", marginLeft: 5 }}
                fill="transparent"
                size={16}
              />
            </span>
          </Tooltip>
        </div>
        <Typography variant="body4" style={{ color: "#83727D", marginTop: 5 }}>
          Organize e gerencie abaixo sua lista de atividades e ganhe agilidade
          para acompanhar a etapa de <b>Candidato.</b>
        </Typography>

        {activeColumn && (
          <ManageChecklist
            flowId={flow._id}
            columnId={activeColumn}
            companyId={flow.companyId}
            subcategory={flow.subcategory}
            checklist={checklist}
            width={"80%"}
          />
        )}
      </ContentWrapper>
    </Container>
  )
}

export default ChecklistTemplate
