import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const Title = styled(Typography)`
  color: #53464f;
`

export const Description = styled(Typography)`
  color: #83727d;
`

export const Badge = styled.div`
  color: #00244c;
  font-weight: 600;
  font-size: 12px;
  margin: 0 10px;
  padding: 4px 12px;
  background: #d4e8ff;
  border-radius: 24px;
`

export const CardContainer = styled.div<{ disabled: boolean }>`
  width: 100%;
  border-radius: 12px;
  padding: 25px 40px;
  border: 1px solid #ebe6e9;

  display: flex;
  flex-directionm: row;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.disabled
      ? css`
          background: #f8f6f8;
        `
      : css`
          background: #ffffff;
        `}

  ${Title} {
    ${({ disabled }) =>
      disabled
        ? css`
            color: #b9acb4;
          `
        : null}
  }

  ${Description} {
    ${({ disabled }) =>
      disabled
        ? css`
            color: #b9acb4;
          `
        : null}
  }

  ${Badge} {
    ${({ disabled }) =>
      disabled
        ? css`
            color: #b9acb4;
            background: #ebe6e9;
          `
        : null}
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 13px;
`

export const ToggleText = styled.div`
  color: #6b5b66;
  weight: 600;
  font-size: 16px;
  margin: 0 10px;
`
