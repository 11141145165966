import { Modal } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`

export const StyledModalFooter = styled(Modal.Footer)`
  & .content-footer {
    padding: 0px 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacings.xs};
  }
`

export const StyledModalContent = styled(Modal.Content)`
  display: flex;
  padding: 0px 40px;
`

export const StyledModalContentLeft = styled.div`
  flex: 5;
`

export const VerticalDivider = styled.div`
  border-right: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
  height: auto;
  margin: 0 ${({ theme }) => theme.spacings.xs};
`

export const HorizontalDivider = styled.div`
  border-top: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
  width: auto;
`

export const StyledModalContentRight = styled.div`
  flex: 5;
`

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
`
