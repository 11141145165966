import { IconTypes, Toggle, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Component, IFieldConfig, IFieldRule } from "@customTypes/documents"
import { RenderField } from "../../../common/RenderField"
import { Badge, Container, FieldWrapper, Icon, Row } from "./styles"

type FieldInfo = {
  icon: IconTypes
  name: string
}

type FieldData = { [key in Component]: FieldInfo }

type DocumentFieldControlProps = {
  field: IFieldConfig
  handleUpdateField: (field: IFieldConfig, message: string) => void
}

export const DocumentFieldControl = ({
  field,
  handleUpdateField,
}: DocumentFieldControlProps) => {
  const fieldData: FieldData = {
    [Component.ShortText]: {
      icon: "IconCursorText",
      name: "Campo de texto curto",
    },
    [Component.Checkbox]: {
      icon: "IconSquareCheck",
      name: "Campo de checkbox",
    },
    [Component.Select]: {
      icon: "IconList",
      name: "Campo de seleção",
    },
    [Component.MultiSelect]: {
      icon: "IconList",
      name: "Campo de seleção múltipla",
    },
    [Component.Attachment]: {
      icon: "IconPaperclip",
      name: "Anexo",
    },
    [Component.Number]: {
      icon: "IconSquare",
      name: "Campo de número",
    },
    [Component.DatePicker]: {
      icon: "IconCalendar",
      name: "Campo de data",
    },
    [Component.TimePicker]: {
      icon: "IconCalendar",
      name: "Campo de horário",
    },
    [Component.Link]: {
      icon: "IconCalendar",
      name: "Campo de link",
    },
    [Component.LongText]: {
      icon: "IconCalendar",
      name: "Campo de texto longo",
    },
  }

  const handleFieldRequired = (isChecked: boolean) => {
    const updatedField: IFieldConfig = {
      ...field,
      fieldRule: {
        ...(field.fieldRule as IFieldRule),
        required: isChecked,
      },
    }

    const message = isChecked
      ? "Campos requerido marcado com sucesso!"
      : "Campos requerido desmarcado com sucesso!"

    handleUpdateField(updatedField, message)
  }

  return (
    <Container disabled={!field.enabled}>
      {field.component && (
        <Row>
          <Icon name={fieldData[field.component].icon} fill="transparent" />
          <Badge>{fieldData[field.component].name}</Badge>
        </Row>
      )}

      <Row>
        <FieldWrapper>
          <RenderField field={field} />
        </FieldWrapper>
      </Row>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <Toggle
          checked={field.fieldRule?.required}
          onChange={(_, value) => handleFieldRequired(value)}
        />
        <Typography variant="body3" style={{ marginLeft: "10px" }}>
          Obrigatório
        </Typography>
      </div>
    </Container>
  )
}
