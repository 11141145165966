import {
  IconButton,
  Icons,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  Container,
  PreviewDocContainer,
  PreviewDocWithoutContainer,
  PreviewImageContainer,
  PreviewTextContainer,
  StyledPOneLine,
  StyledPre,
} from "./styles"
import { Component } from "@customTypes/documents"

export const RenderPreviewField = ({
  value,
  label = "",
  component,
}: {
  value: any
  label: string
  component?: Component
}) => {
  const renderOthersValue = () => {
    if (
      component === Component.ShortText ||
      component === Component.Number ||
      component === Component.Select
    )
      return value

    if (component === Component.Checkbox)
      return !value ? "Não marcado" : "Marcado"

    if (component === Component.MultiSelect) {
      const isArray = Array.isArray(value) && value.length > 0
      return isArray ? value.map((x) => x.value).join(" ") : "Não consta"
    }

    if (component === Component.DatePicker)
      return value ? dayjs(value).format("DD/MM/YYYY") : "Não consta"

    if (component === Component.TimePicker)
      return value ? dayjs(value).format("hh:mm A") : "Não consta"

    if (component === Component.Link)
      return value ? <a href={value}>{value}</a> : "Não consta"

    if (component === Component.LongText) {
      return <StyledPre>{value}</StyledPre>
    }

    return "Não consta"
  }

  if (component === Component.Attachment) {
    const { value: fileValue = "", key = "" } = value || {}

    const lastIndexName = key ? key.lastIndexOf("/") : ""
    const nameOfArchive =
      key && lastIndexName
        ? key.substring(lastIndexName + 1)
        : "Nome não identificado"
    return fileValue ? (
      <PreviewDocContainer>
        <div style={{ display: "flex" }}>
          <PreviewImageContainer url={fileValue} />
          <PreviewTextContainer style={{ marginLeft: "10px" }}>
            <StyledPOneLine>
              <Typography variant="body3">{label}</Typography>
            </StyledPOneLine>
          </PreviewTextContainer>
        </div>
        <IconButton
          size="medium"
          variant="line"
          icon="IconDownload"
          onClick={async () => {
            const readFile = await fetch(fileValue, {
              mode: "cors",
              cache: "no-cache",
              credentials: "same-origin",
            })

            const fileToArrayBuffer = await readFile.arrayBuffer()
            const res = URL.createObjectURL(new Blob([fileToArrayBuffer]))
            const a = document.createElement("a")
            a.href = res
            a.download = nameOfArchive
            a.click()

            URL.revokeObjectURL(res)
          }}
          style={{ marginLeft: "10px" }}
        />
      </PreviewDocContainer>
    ) : (
      <PreviewDocWithoutContainer>
        <div style={{ display: "flex" }}>
          <StyledPOneLine>
            <Typography variant="body4">Documento não enviado</Typography>
          </StyledPOneLine>
        </div>
      </PreviewDocWithoutContainer>
    )
  }

  return (
    <Container>
      <Icons
        name="IconList"
        style={{
          marginRight: "15px",
          width: "16px",
          height: "16px",
        }}
      />{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontWeight: "600",
            fontSize: "8px",
            lineHeight: "11px",
            color: "#50494C",
          }}
        >
          {label}
        </span>
        <span
          style={{
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "19px",
            color: "#50494C",
            lineBreak: "anywhere",
          }}
        >
          {renderOthersValue()}
        </span>
      </div>
    </Container>
  )
}
