import React from "react"
import {
  CnpjMask,
  CpfMask,
  EmailMask,
  PhoneMask,
} from "../../../../../utils/masks"
import {
  DatePicker,
  SelectField,
  TextField,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  validarCNPJ,
  validateCPF,
  validateEmail,
  validatePhone,
} from "../../../../../utils"
import {
  GenericModal,
  LeaderAndFollowers,
  ProbationaryPeriod,
  ProbationaryRef,
  TextFieldCheckbox,
} from "../../../../common"
import { useFormik } from "formik"
import * as yup from "yup"
import { FieldContainer } from "./styles"
import { Candidate, Flow, Follower, HiringCard } from "../../../../../types"
import dispatchToast from "../../../../../utils/dispatchToast"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { api } from "../../../../../api"
import { useTranslation } from "react-i18next"

const validationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
  documentNumber: yup
    .string()
    .length(14, "Número de CPF precisa conter 12 numeros"),
  cnpj: yup.string().notRequired().nullable(),
  legalName: yup.string().notRequired().nullable(),
  nationality: yup.string().notRequired().nullable(),
  socialName: yup.string(),
  phone: yup.string().when("isPhoneNotRequired", {
    is: false,
    then: (schema) => schema.required("Campo obrigatório"),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  hiringDate: yup
    .date()
    .typeError("Favor selecionar uma data válida")
    .notRequired(),
  isPhoneNotRequired: yup.bool(),
  email: yup.string().required("Campo obrigatório"),
  leader: yup.object().nullable().required("Campo obrigatório"),
  followers: yup.array().notRequired(),
})

interface EditCandidate {
  isOpen: boolean
  handleClose: () => void
  card: HiringCard
  candidate: Candidate
  flow: Flow
}

const EditCandidate = ({
  isOpen,
  handleClose,
  card,
  candidate,
  flow,
}: EditCandidate) => {
  const [isSubmitLoading, setIsSubmitLoading] = React.useState<boolean>(false)

  const [t] = useTranslation("translations", {
    keyPrefix: "page.hiring.candidate",
  })

  const queryClient = useQueryClient()

  const { mutateAsync: updateCandidate } = useMutation(
    api.mutation.hiring.updateCandidate,
  )
  const { mutateAsync: updateCard } = useMutation(
    api.mutation.hiring.card.updateCard,
  )

  const probationPeriodRef = React.useRef<ProbationaryRef>(null)

  const formik = useFormik({
    initialValues: {
      name: candidate.name ?? "",
      nationality: candidate.nationality ?? "",
      socialName: candidate.socialName ?? "",
      documentNumber: candidate.documentNumber
        ? CpfMask(candidate.documentNumber)
        : "",
      cnpj: candidate.cnpj ? CnpjMask(candidate.cnpj) : "",
      legalName: candidate.legalName ?? "",
      email: candidate.email ?? "",
      phone: candidate.phone ?? "",
      hiringDate: candidate.proposalLetterInfo?.hiringDate ?? "",
      followers:
        card.followers?.map((follower) => ({
          label: follower?.name,
          value: follower?._id,
        })) ?? [],
      leader: candidate.leader ?? null,
      isPhoneNotRequired: !candidate.phone,
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {}

      if (values.email?.length > 1 && !validateEmail(values.email))
        errors.email = "Email invalido"
      if (
        values.documentNumber?.length > 1 &&
        !validateCPF(values.documentNumber)
      )
        errors.documentNumber = "CPF invalido"
      if (values.cnpj?.length > 1 && !validarCNPJ(values.cnpj))
        errors.cnpj = "CNPJ invalido"
      if (values.phone?.length > 1 && !validatePhone(values.phone))
        errors.phone = "Telefone invalido"

      if (flow.subcategory === "pj") {
        if (!values.cnpj || !validarCNPJ(values.cnpj))
          errors.cnpj = "Dado inválido"
        if (!values.legalName) errors.legalName = "Dado inválido"
      }

      if (Object.keys(errors)?.length > 0) {
        dispatchToast({
          content: "Por favor, preencha todos os campos obrigatórios",
          type: "error",
        })
      }
      return errors
    },
    onSubmit: async (values) => {
      try {
        if (!probationPeriodRef.current) throw new Error("Form not defined")
        const errors = await probationPeriodRef?.current?.validateForm()
        if (Object.keys(errors)?.length > 0) {
          dispatchToast({
            content: "Por favor, preencha todos os campos obrigatórios",
            type: "error",
          })
          return
        }
        const probationPeriod: typeof probationPeriodRef.current.values =
          await probationPeriodRef?.current?.submitForm()

        setIsSubmitLoading(true)
        const {
          isPhoneNotRequired,
          hiringDate,
          followers,
          documentNumber,
          email,
          ...fields
        } = values
        const parsedDocumentNumber = documentNumber.replace(/\D/g, "") || ""
        const letterInfo = dayjs(hiringDate).isValid()
          ? { hiringDate: new Date(hiringDate).toISOString() }
          : undefined

        const parsedFollowers =
          followers.length > 0
            ? followers.map(
                (option) =>
                  ({
                    _id: option.value,
                    name: option.label,
                  }) as Follower,
              )
            : undefined

        await updateCandidate({
          candidateId: candidate._id,
          fields: {
            ...fields,
            leader: fields.leader || undefined,
            email: email.toLowerCase(),
            documentNumber: parsedDocumentNumber,
            proposalLetterInfo: letterInfo,
            probationPeriod: probationPeriod ?? null,
          },
        })

        if (!!followers?.length) {
          await updateCard({
            flowCardId: card._id,
            followers: parsedFollowers,
          })
        } else if (card.followers?.length) {
          await updateCard({
            flowCardId: card._id,
            followers: null,
          })
        }

        setTimeout(() => {
          queryClient.invalidateQueries()

          dispatchToast({
            type: "success",
            content: t("messages.editSuccessfull"),
          })
          setIsSubmitLoading(false)
          handleClose()
        }, 1500)
      } catch (err: any) {
        setIsSubmitLoading(false)
        dispatchToast({
          type: "error",
          content: t("messages.editFailure"),
        })
      }
    },
  })

  return (
    <GenericModal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Editar perfil"
      description="Realize as modificações necessárias no perfil do candidato."
      actionButtons={[
        {
          variant: "link",
          description: "Cancelar",
          handleAction: handleClose,
        },
        {
          variant: "primary",
          description: "Salvar alterações",
          handleAction: formik.handleSubmit,
          disabled: isSubmitLoading,
          isLoading: isSubmitLoading,
        },
      ]}
    >
      <FieldContainer>
        <SelectField
          label={"Vinculo empregaticio"}
          fullWidth={true}
          disabled={true}
          value={flow.subcategory}
          options={[
            { label: "CLT", value: "clt" },
            { label: "PJ", value: "pj" },
            { label: "Estágio", value: "internship" },
          ]}
        />
      </FieldContainer>

      <FieldContainer>
        <TextField
          type={"text"}
          label={"Nome Completo"}
          name={"name"}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name?.toString()}
          fullWidth={true}
        />
      </FieldContainer>

      <FieldContainer>
        <TextField
          type={"text"}
          label={"Nome Social"}
          placeholder={"Digite o nome social"}
          name={"socialName"}
          value={formik.values.socialName}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name?.toString()}
          fullWidth={true}
        />
      </FieldContainer>

      <FieldContainer>
        <SelectField
          name={"nationality"}
          label={"Nacionalidade"}
          fullWidth={true}
          onSelectChange={(e, { value }) =>
            formik.handleChange({
              target: { name: "nationality", value },
            })
          }
          error={
            formik.touched.nationality && Boolean(formik.errors.nationality)
          }
          helperText={
            formik.touched.nationality && formik.errors.nationality?.toString()
          }
          value={formik.values.nationality}
          options={[
            { label: "Brasileiro", value: "brazilian" },
            { label: "Estrangeiro", value: "foreigner" },
          ]}
        />
      </FieldContainer>

      <FieldContainer>
        <TextField
          type={"text"}
          label={"CPF"}
          name={"documentNumber"}
          value={formik.values.documentNumber}
          onChange={(e) => {
            if (e.target.value.length === 15) return

            const value = CpfMask(e.target.value)
            formik.handleChange({
              target: { name: "documentNumber", value },
            })
          }}
          error={
            formik.touched.documentNumber &&
            Boolean(formik.errors.documentNumber)
          }
          helperText={
            formik.touched.documentNumber &&
            formik.errors.documentNumber?.toString()
          }
          fullWidth={true}
        />
      </FieldContainer>

      {flow.subcategory === "pj" ? (
        <>
          <FieldContainer>
            <TextField
              type={"text"}
              label={"CNPJ"}
              name={"cnpj"}
              value={formik.values.cnpj}
              onChange={(e) => {
                if (e.target.value.length === 19) return

                const value = CnpjMask(e.target.value)
                formik.handleChange({
                  target: { name: "cnpj", value },
                })
              }}
              error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
              helperText={formik.touched.cnpj && formik.errors.cnpj?.toString()}
              fullWidth={true}
            />
          </FieldContainer>

          <FieldContainer>
            <TextField
              type={"text"}
              label={"Razão Social"}
              name={"legalName"}
              value={formik.values.legalName}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name?.toString()}
              fullWidth={true}
            />
          </FieldContainer>
        </>
      ) : (
        <></>
      )}

      <FieldContainer>
        <TextField
          type={"text"}
          label={"E-mail"}
          name={"email"}
          value={formik.values.email}
          onChange={(e) => {
            const value = EmailMask(e.target.value)
            formik.handleChange({
              target: { name: "email", value },
            })
          }}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email?.toString()}
          fullWidth={true}
        />
      </FieldContainer>

      <FieldContainer>
        <TextFieldCheckbox
          type={"text"}
          label={"Telefone"}
          name={"phone"}
          value={formik.values.phone}
          onChange={(e) => {
            if (e.target.value.length === 15) return

            const value = PhoneMask(e.target.value)
            formik.handleChange({
              target: { name: "phone", value },
            })
          }}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone?.toString()}
          fullWidth={true}
          checkbox={{
            text: "Não informar",
            name: "isPhoneNotRequired",
            value: formik.values.isPhoneNotRequired,
            onClick: (checked) =>
              formik.handleChange({
                target: { name: "isPhoneNotRequired", value: checked },
              }),
          }}
        />
      </FieldContainer>

      <FieldContainer>
        <DatePicker
          id={"hiringDate"}
          name={"hiringDate"}
          label={"Data prevista de admissão"}
          placeholder={"Digite a data prevista de admissão"}
          value={formik.values.hiringDate}
          onDateChange={async (value) => {
            const date = value && dayjs(value).isValid() ? dayjs(value) : null
            if (!date) return
            const parsedValue = dayjs(value).isValid()
              ? date?.toISOString()
              : null

            formik.handleChange({
              target: { id: "hiringDate", value: parsedValue },
            })
          }}
          error={
            formik?.touched?.hiringDate && Boolean(formik?.errors?.hiringDate)
          }
          helperText={
            formik?.touched?.hiringDate &&
            formik?.errors?.hiringDate?.toString()
          }
          fullWidth
        />
      </FieldContainer>

      <FieldContainer>
        <ProbationaryPeriod
          ref={probationPeriodRef}
          value={candidate.probationPeriod}
          hiringDate={formik.values.hiringDate}
        />
      </FieldContainer>

      <LeaderAndFollowers
        leader={{
          value: formik.values.leader
            ? [
                {
                  label: formik.values.leader.name,
                  value: formik.values.leader._id,
                },
              ]
            : [],
          handleChange: (value) => {
            formik.handleChange({
              target: { name: "leader", value },
            })
          },
          error: Boolean(formik.errors.leader),
          helperText:
            (formik?.touched?.leader && formik?.errors?.leader) || undefined,
        }}
        followers={{
          value: formik.values.followers,
          handleChange: (value) => {
            formik.handleChange({
              target: { name: "followers", value },
            })
          },
          error: formik.touched.followers && Boolean(formik.errors.followers),
          helperText:
            formik.touched.followers && formik.errors.followers?.toString(),
        }}
      />
    </GenericModal>
  )
}

export default EditCandidate
