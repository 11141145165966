import {
  Button,
  Icons,
  Modal,
  SelectField,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  HorizontalDivider,
  StyledInputContainer,
  StyledModalContent,
  StyledModalContentLeft,
  StyledModalContentRight,
  StyledModalFooter,
  VerticalDivider,
} from "./styled"
import { useTheme } from "styled-components"
import EmptyListSvg from "../../../EmptyListSvg"
import {
  Component,
  FieldType,
  IFieldConfig,
  piiCustomFieldTypeToComponentType,
  piiCustomFieldTypeToFieldTypeMap,
} from "@customTypes/documents"
import { useEffect, useMemo, useState } from "react"
import { RenderField } from "@components/common"
import { useCreateSectionPageContext } from "@pages/Configuration/DocumentTemplate/CreateSection/context/CreateSectionPageContext"
import dispatchToast from "@utils/dispatchToast"
import { v4 } from "uuid"

enum CustomFieldComponentType {
  ATTACHMENT = "ATTACHMENT",
  CUSTOM_FIELD = "CUSTOM_FIELD",
}

const customFieldComponentTypeNameMap = {
  [CustomFieldComponentType.ATTACHMENT]: "Anexo",
  [CustomFieldComponentType.CUSTOM_FIELD]: "Campo customizado",
}

const customFieldComponentSelectOptions = Object.entries(
  CustomFieldComponentType,
).map(([_, value]) => ({
  label: customFieldComponentTypeNameMap[value],
  value,
}))

type SelectCustomFieldModalProps = {
  closeModal: () => void
}

export const SelectCustomFieldModal = ({
  closeModal,
}: SelectCustomFieldModalProps) => {
  const theme = useTheme()
  const {
    fields,
    formGroupId,
    setFields,
    getCustomFieldsData,
    getDocumentTypesData,
    isGetCustomFieldsLoading,
    isGetDocumentTypesLoading,
    formConfigCustomFieldIdSet,
  } = useCreateSectionPageContext()
  const [
    selectedCustomFieldComponentType,
    setSelectedCustomFieldComponentType,
  ] = useState<CustomFieldComponentType | undefined>()
  const [selectedCustomFieldId, setSelectedCustomFieldId] = useState<
    string | undefined
  >()
  const [attachmentCustomFieldName, setAttachmentCustomFieldName] = useState<
    string | undefined
  >()
  const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState<
    string | undefined
  >()

  const isAttachmentComponentTypeSelected =
    selectedCustomFieldComponentType === CustomFieldComponentType.ATTACHMENT

  useEffect(() => {
    setSelectedCustomFieldId(undefined)
    setAttachmentCustomFieldName(undefined)
    setSelectedDocumentTypeId(undefined)
  }, [selectedCustomFieldComponentType])

  const documentTypeSelectOptions = useMemo(
    () =>
      getDocumentTypesData?.documentTypes?.map(({ id, name }) => ({
        label: name,
        value: id,
      })) || [],
    [getDocumentTypesData?.documentTypes],
  )

  const selectedCustomField = useMemo(
    () => getCustomFieldsData?.find(({ id }) => id === selectedCustomFieldId),
    [getCustomFieldsData, selectedCustomFieldId],
  )

  const fieldId = isAttachmentComponentTypeSelected
    ? v4()
    : selectedCustomFieldId

  const fieldConfig = {
    customFieldId: fieldId,
    ...(isAttachmentComponentTypeSelected
      ? { documentTypeId: selectedDocumentTypeId }
      : {}),
    enabled: true,
    fieldId: fieldId,
    formGroupId: formGroupId,
    type: "base",
    component: isAttachmentComponentTypeSelected
      ? Component.Attachment
      : piiCustomFieldTypeToComponentType[selectedCustomField?.type || ""],
    placeholder: attachmentCustomFieldName || selectedCustomField?.name,
    options: selectedCustomField?.enumOptions?.map(({ label }) => label),
    fieldType: isAttachmentComponentTypeSelected
      ? FieldType.File
      : piiCustomFieldTypeToFieldTypeMap[selectedCustomField?.type || ""],
    fieldRule: {
      fieldId: fieldId,
      required: false,
    },
  }

  const selectedCustomFieldIds = useMemo(
    () => fields.map(({ customFieldId }) => customFieldId),
    [fields],
  )

  const customFieldSelectOptions = useMemo(
    () =>
      (
        getCustomFieldsData?.map(({ name, id }) => ({
          label: name,
          value: id,
        })) || []
      ).filter(
        ({ value }) =>
          !selectedCustomFieldIds.includes(value) &&
          !formConfigCustomFieldIdSet.has(value),
      ),
    [getCustomFieldsData, selectedCustomFieldIds, formConfigCustomFieldIdSet],
  )

  const isLoading = isGetCustomFieldsLoading || isGetDocumentTypesLoading

  const isCtaEnabled = () => {
    if (isAttachmentComponentTypeSelected) {
      return !!selectedDocumentTypeId && !!attachmentCustomFieldName
    }

    return (
      !!selectedCustomFieldComponentType &&
      !!selectedCustomFieldId &&
      !isLoading
    )
  }

  return (
    <Modal.Root open onClose={() => closeModal()} size="md">
      <>
        <StyledModalContent>
          <StyledModalContentLeft>
            <Typography
              variant="headline8"
              variantColor={theme.colors.neutral[30]}
              weight={700}
              style={{ marginBottom: theme.spacings.xs5 }}
            >
              Adicionar campo
            </Typography>
            <Typography
              variant="body4"
              variantColor={theme.colors.neutral[50]}
              weight={400}
              style={{ marginBottom: theme.spacings.xs }}
            >
              Adicione um campo para a nova seção.
            </Typography>
            <StyledInputContainer>
              <SelectField
                required
                label={"Tipo de campo"}
                fullWidth={true}
                onSelectChange={(_, { value }) =>
                  setSelectedCustomFieldComponentType(value)
                }
                value={selectedCustomFieldComponentType}
                options={customFieldComponentSelectOptions}
              />
              {selectedCustomFieldComponentType &&
                !isAttachmentComponentTypeSelected && (
                  <SelectField
                    key={selectedCustomFieldId}
                    label={"Selecionar campo"}
                    fullWidth={true}
                    searchable
                    onSelectChange={(_, { value }) =>
                      setSelectedCustomFieldId(value)
                    }
                    value={selectedCustomFieldId}
                    options={customFieldSelectOptions}
                  />
                )}
              {selectedCustomFieldComponentType &&
                isAttachmentComponentTypeSelected && (
                  <SelectField
                    key={selectedDocumentTypeId}
                    label={"Selecionar campo"}
                    fullWidth={true}
                    searchable
                    onSelectChange={(_, { value }) =>
                      setSelectedDocumentTypeId(value)
                    }
                    value={selectedDocumentTypeId}
                    options={documentTypeSelectOptions}
                  />
                )}
              {isAttachmentComponentTypeSelected && (
                <>
                  <HorizontalDivider />
                  <TextField
                    label={"Nome de exibição"}
                    required
                    fullWidth
                    value={attachmentCustomFieldName}
                    onChange={(e) =>
                      setAttachmentCustomFieldName(e.target.value)
                    }
                  />
                </>
              )}
            </StyledInputContainer>
          </StyledModalContentLeft>
          <VerticalDivider />
          <StyledModalContentRight>
            <Typography
              variant="headline8"
              variantColor={theme.colors.neutral[30]}
              weight={700}
              style={{ marginBottom: theme.spacings.xs5 }}
            >
              Preview
            </Typography>
            <Typography
              variant="body4"
              variantColor={theme.colors.neutral[50]}
              weight={400}
              style={{ marginBottom: theme.spacings.s }}
            >
              Confira a pré-visualização do campo
            </Typography>
            {selectedCustomFieldComponentType &&
            (selectedCustomFieldId || selectedDocumentTypeId) ? (
              <RenderField field={fieldConfig as IFieldConfig} />
            ) : (
              <>
                <EmptyListSvg style={{ margin: "0 auto 16px auto" }} />
                <Typography
                  variant="body4"
                  variantColor={theme.colors.neutral[50]}
                  weight={600}
                  style={{ width: "100%", textAlign: "center" }}
                >
                  Você ainda não selecionou nenhum campo
                </Typography>
              </>
            )}
          </StyledModalContentRight>
        </StyledModalContent>

        <StyledModalFooter>
          <Button size="large" variant="secondary" onClick={() => closeModal()}>
            Cancelar
          </Button>
          <Button
            size="large"
            variant="primary"
            disabled={!isCtaEnabled()}
            onClick={() => {
              setFields((prev) => [
                ...prev,
                { ...fieldConfig, order: prev.length } as IFieldConfig,
              ])
              dispatchToast({
                type: "success",
                content: "Campo adicionado com sucesso!",
              })
              closeModal()
            }}
          >
            Concluir <Icons name="IconCheck" />
          </Button>
        </StyledModalFooter>
      </>
    </Modal.Root>
  )
}
