import { trpc } from "@api/client"
import { MultiSelect, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useDebouncedValue } from "@utils/useDebouncedValue"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

type InputData = {
  value?: any
  handleChange: (value) => void
  error?: boolean
  helperText?: string
}

interface LeaderAndFollowersProps {
  leader: InputData
  followers: InputData
}

type Option = {
  label: string
  value: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  gap: 24px;
`

export const LeaderAndFollowers = ({
  leader,
  followers,
}: LeaderAndFollowersProps) => {
  const [leaderOptions, setLeaderOptions] = useState<Option[]>([])
  const [followersOptions, setFollowersOptions] = useState<Option[]>([])

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const { mutateAsync: getPartialEmployee, isLoading: isSearching } =
    trpc.employee.getEmployeeByPartialName.useMutation()

  const [leaderSearchTerm, setLeaderSearchTerm] = useState<string>("")
  const [followerSearchTerm, setFollowerSearchTerm] = useState<string>("")
  const [leaderSelectFieldNoOptionsText, setLeaderSelectFieldNoOptionsText] =
    useState<string>("")
  const [
    followerSelectFieldNoOptionsText,
    setFollowerSelectFieldNoOptionsText,
  ] = useState<string>("")

  const leaderSearchTermDebouncedValue = useDebouncedValue<string>({
    inputValue: leaderSearchTerm,
    delayInMillis: 350,
  })

  const followerSearchTermDebouncedValue = useDebouncedValue<string>({
    inputValue: followerSearchTerm,
    delayInMillis: 350,
  })

  useEffect(() => {
    async function getLeaders() {
      if (leaderSearchTermDebouncedValue?.length >= 3) {
        const leaders = await getPartialEmployee({
          searchParam: leaderSearchTermDebouncedValue,
        })

        setLeaderSelectFieldNoOptionsText(
          !leaders.length
            ? t("addCandidateForm.leaderAndFollowers.noOptionText")
            : "",
        )

        setLeaderOptions(
          leaders.map((employee) => ({
            label: employee.name,
            value: employee.id,
          })),
        )
      } else {
        setLeaderSelectFieldNoOptionsText(
          t("addCandidateForm.leaderAndFollowers.invalidSearchTerm"),
        )
        setLeaderOptions([])
      }
    }
    getLeaders()
  }, [getPartialEmployee, leaderSearchTermDebouncedValue, t])

  useEffect(() => {
    async function getFollowers() {
      if (followerSearchTermDebouncedValue?.length >= 3) {
        const followers = await getPartialEmployee({
          searchParam: followerSearchTermDebouncedValue,
        })

        setFollowerSelectFieldNoOptionsText(
          !followers.length
            ? t("addCandidateForm.leaderAndFollowers.noOptionText")
            : "",
        )

        setFollowersOptions(
          followers.map((employee) => ({
            label: employee.name,
            value: employee.id,
          })),
        )
      } else {
        setFollowerSelectFieldNoOptionsText(
          t("addCandidateForm.leaderAndFollowers.invalidSearchTerm"),
        )
        setFollowersOptions([])
      }
    }
    getFollowers()
  }, [getPartialEmployee, followerSearchTermDebouncedValue, t])

  return (
    <Container>
      <div>
        <Typography variant="headline8" variantColor="#53464F">
          {t("addCandidateForm.leaderAndFollowers.title")}
        </Typography>
        <Typography variant="body4" variantColor="#83727D">
          {t("addCandidateForm.leaderAndFollowers.description")}
        </Typography>
      </div>

      <MultiSelect
        renderInput={() => null}
        value={leader.value}
        onChange={(e) => {
          setLeaderSearchTerm(e.target.value)
        }}
        onSelectChange={(_, values) => {
          if (values.length > 1) return

          const value = values[0]
            ? {
                _id: values[0]?.value,
                name: values[0]?.label,
              }
            : null

          leader?.handleChange(value)
          setLeaderSearchTerm("")
        }}
        loading={isSearching}
        error={leader?.error}
        helperText={leader?.helperText}
        loadingText={t("addCandidateForm.leaderAndFollowers.loadingText")}
        label={t("addCandidateForm.leaderAndFollowers.leaderPlaceholder")}
        noOptionsText={leaderSelectFieldNoOptionsText}
        fullWidth
        limitTags={1}
        options={leaderOptions}
      />
      <MultiSelect
        renderInput={() => null}
        value={followers.value}
        onChange={(e) => {
          setFollowerSearchTerm(e.target.value)
        }}
        onSelectChange={(_, values) => {
          followers?.handleChange(values)
          setFollowerSearchTerm("")
        }}
        loading={isSearching}
        error={followers?.error}
        helperText={followers?.helperText}
        loadingText={t("addCandidateForm.leaderAndFollowers.loadingText")}
        label={t("addCandidateForm.leaderAndFollowers.followersPlaceholder")}
        noOptionsText={followerSelectFieldNoOptionsText}
        fullWidth
        options={followersOptions}
      />
    </Container>
  )
}
