import {
  Button,
  Icons,
  IconTypes,
  LinkButton,
  Loader,
  Modal,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useCallback } from "react"

export * from "./GenericConfirmationContent"

export type ActionButton = {
  variant: "primary" | "secondary" | "link"
  description: string
  icon?: IconTypes
  disabled?: boolean
  isLoading?: boolean
  handleAction: () => void
}

export type GenericModalProps = {
  isOpen: boolean
  handleClose: () => void
  title?: string
  description?: string
  size?: "xs" | "sm" | "md" | "full"
  actionButtons?: ActionButton[]
  children: React.ReactNode
  showCloseButton?: boolean
  centerActionButton?: boolean
}

export const GenericModal = ({
  isOpen,
  handleClose,
  title,
  size = "sm",
  description,
  actionButtons,
  children,
  showCloseButton = true,
  centerActionButton = false,
}: GenericModalProps) => {
  const renderButton = (button: ActionButton, idx = 0) => {
    if (button.variant === "link") {
      return (
        <LinkButton
          key={idx}
          variant="primary"
          onClick={button.handleAction}
          disabled={button.disabled}
          style={{ marginTop: 15 }}
        >
          {button.description}
          {button.icon && <Icons name={button.icon} fill="transparent" />}
        </LinkButton>
      )
    }

    return (
      <Button
        key={idx}
        size="large"
        variant={button.variant as "primary" | "secondary"}
        disabled={button.disabled}
        onClick={button.handleAction}
      >
        {button.isLoading ? (
          <Loader size="extraSmall" variant="secondary" />
        ) : (
          <>
            {button.description}
            {button.icon && <Icons name={button.icon} fill="transparent" />}
          </>
        )}
      </Button>
    )
  }

  const renderFooter = useCallback(() => {
    if (!actionButtons) return null

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: centerActionButton
            ? "center"
            : actionButtons?.length === 1
              ? "flex-end"
              : "space-between",
        }}
      >
        {actionButtons.map((button, idx) => renderButton(button, idx))}
      </div>
    )
  }, [actionButtons, centerActionButton])

  return (
    <Modal.Root
      showClose={showCloseButton}
      open={isOpen}
      onClose={handleClose}
      size={size}
    >
      <>
        {title && <Modal.Header title={title} description={description} />}
        <Modal.Content>
          <div
            style={{
              padding: "0 25px",
              maxHeight: "50vh",
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            {children}
          </div>
        </Modal.Content>

        {actionButtons?.length && <Modal.Footer>{renderFooter()}</Modal.Footer>}
      </>
    </Modal.Root>
  )
}
