import styled from "styled-components"

export const FooterContainer = styled.div`
  display: flex;
  position: sticky;
  bottom: 0;
  width: fill-available;
  background-color: #ffffff;
  padding: ${({ theme }) => theme.spacings.xs2}
    // eslint-disable-next-line prettier/prettier
    ${({ theme }) => theme.spacings.xs};
  border-top: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
  z-index: 10;
`

export const FooterContentLeftContainer = styled.div`
  display: flex;
  flex: 3;
`

export const FooterContentRightContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.xs};
  flex: 7;
`
