import i18n from "@i18n"
import { ToastMessageCodes } from "./toastMessageCodes"

export const getSendContractCustomValidationToastMessage = (
  code: string,
  employeeName: string,
) => {
  return (
    {
      [ToastMessageCodes.MissingWitnessEmail]: i18n.t(
        "page.hiring.signature.messages.missingWitnessEmail",
        { employeeName },
      ),
      [ToastMessageCodes.MissingCompanyEmployeeEmail]: i18n.t(
        "page.hiring.signature.messages.missingCompanyEmployeeEmail",
        { employeeName },
      ),
    }[code] ??
    i18n.t("page.hiring.signature.messages.invalidContractData") ??
    ""
  )
}
