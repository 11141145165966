import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2"
import { useCreateSectionPageContext } from "../../context/CreateSectionPageContext"
import {
  FooterContainer,
  FooterContentLeftContainer,
  FooterContentRightContainer,
} from "./styled"
import { useNavigate } from "react-router-dom"

export const PageFooter = () => {
  const {
    step,
    setStep,
    goToAddFieldStep,
    relatedFormConfigPagePath,
    createSection,
    isSendFormConfigExtensionLoading,
  } = useCreateSectionPageContext()

  const navigate = useNavigate()

  const proceedButtonTextMap = {
    [0]: "Continuar",
    [1]: "Criar seção",
  }

  const proceedButtonActionMap = {
    [0]: () => goToAddFieldStep(),
    [1]: () => createSection(),
  }

  const proceedButtonIconMap = {
    [0]: <Icons name="IconArrowRight" />,
    [1]: <Icons name="IconCheck" />,
  }

  return (
    <FooterContainer>
      <FooterContentLeftContainer>
        <LinkButton
          onClick={() => navigate(relatedFormConfigPagePath)}
          variant="secondary"
          style={{ alignSelf: "center" }}
        >
          Cancelar
        </LinkButton>
      </FooterContentLeftContainer>
      <FooterContentRightContainer>
        <Button
          size="medium"
          variant="secondary"
          disabled={step === 0}
          onClick={() => setStep(0)}
          style={{ maxHeight: "48px" }} // Variant "secondary" gains a border when not disabled, increasing its height
        >
          <Icons name="IconArrowLeft" style={{ alignSelf: "center" }} /> Voltar
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={proceedButtonActionMap[step]}
          style={{ alignSelf: "center" }}
          loading={isSendFormConfigExtensionLoading}
        >
          {proceedButtonTextMap[step]} {proceedButtonIconMap[step]}
        </Button>
      </FooterContentRightContainer>
    </FooterContainer>
  )
}
