import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import {
  ContainedInput,
  GenericAccordion,
  RenderPreviewField,
} from "../../../components"

import { AccordionDetails, AccordionSummary, Skeleton } from "@mui/material"

import {
  Icons,
  dayjs,
  Typography,
  IconButton,
  NotificationCard,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2"

import {
  Container,
  RightContainer,
  SubContainer,
  PreviewDocContainer,
  AccordionChildren,
  FlexColumn,
  SectionTitle,
  PreviewImageContainer,
  PreviewTextContainer,
  PreviewDocWithoutContainer,
  StyledAccordion,
  StyledContainerIcon,
  AccordionTitle,
  AccordionDescription,
  ArchiveContainer,
  SectionSubTitle,
  StyledLinkButton,
  ShareText,
} from "./styles"

import { formatCurrency, parseHiringType, renderDate } from "../../../utils"
import dispatchToast from "../../../utils/dispatchToast"
import {
  ConfirmationDependent,
  ConfirmationDocumentsWithValue,
  FieldValue,
  Flow,
  HiringCard,
} from "../../../types"
import { UploadPreview } from "../../common/DeprecatedUploadPreview"
import { FieldData } from "./FieldData"
import { trpc } from "@api/client"

const PJContent = ({ card }: { card: HiringCard; flow: Flow }) => {
  const { _id, candidateId, metadata = {}, formConfigId, candidate } = card

  const navigate = useNavigate()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const { data: contractData, isInitialLoading: isContractLoading } =
    trpc.contract.getContractByFlowCardId.useQuery(
      {
        flowCardId: card._id,
      },
      {
        queryKey: ["contract.getContractByFlowCardId", { flowCardId: card._id }],
        enabled: !!_id,
        refetchOnWindowFocus: false,
        retry: false,
      },
    )

  const { data: getConfirmationData, isInitialLoading: isConfirmationLoading } =
    trpc.card.getConfirmationData.useQuery(
      {
        candidateId: card.candidateId,
        formConfigId: card.formConfigId,
        extensionId: card.formExtensionId,
      },
      {
        enabled: !!(formConfigId && candidateId),
        refetchOnWindowFocus: false,
        retry: false,
      },
    )

  const confirmationGroups = getConfirmationData as unknown as any

  const renderChildrenCandidateData = useCallback((fields: FieldValue[]) => {
    const filterFileFields = fields.filter((f) => f.fieldType === "file") || []
    const filterOtherFields = fields.filter((f) => f.fieldType !== "file") || []

    return (
      <div>
        {filterFileFields.length ? (
          <>
            <Typography
              variant="body3"
              style={{ color: "#856F7E", fontWeight: 700 }}
            >
              Fotos do documento
            </Typography>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {filterFileFields.map((f, index) => {
                const { value = "", key = "" } = f?.value || {}

                const lastIndexName = key ? key.lastIndexOf("/") : ""
                const nameOfArchive =
                  key && lastIndexName
                    ? key.substring(lastIndexName + 1)
                    : "Nome não identificado"

                return (
                  <ArchiveContainer key={`candidateDataFields${index}`}>
                    {value ? (
                      <>
                        <PreviewDocContainer>
                          <div style={{ display: "flex" }}>
                            <PreviewImageContainer url={value} />
                            <PreviewTextContainer
                              style={{ marginLeft: "10px" }}
                            >
                              <Typography variant="body3">
                                {f?.placeholder || ""}
                              </Typography>
                            </PreviewTextContainer>
                          </div>
                          <IconButton
                            size="medium"
                            variant="line"
                            icon="IconDownload"
                            onClick={async () => {
                              const readFile = await fetch(value, {
                                mode: "cors",
                                cache: "no-cache",
                                credentials: "same-origin",
                              })

                              const fileToArrayBuffer =
                                await readFile.arrayBuffer()
                              const res = URL.createObjectURL(
                                new Blob([fileToArrayBuffer]),
                              )
                              const a = document.createElement("a")
                              a.href = res
                              a.download = nameOfArchive
                              a.click()

                              URL.revokeObjectURL(res)
                            }}
                            style={{ marginLeft: "10px" }}
                          />
                        </PreviewDocContainer>
                        <div style={{ marginTop: "4px" }}>
                          <StyledLinkButton
                            variant="primary"
                            style={{ alignSelf: "center" }}
                            onClick={async () => {
                              navigator.clipboard.writeText(value)
                              dispatchToast({
                                content: "Link copiado",
                                type: "success",
                              })
                            }}
                          >
                            <>
                              <ShareText
                                variant="caption"
                                style={{ fontWeight: 700 }}
                              >
                                Compartilhar link
                              </ShareText>
                              <Icons
                                name="IconLink"
                                size={16}
                                style={{
                                  fill: "transparent",
                                  color: "#6B5B66",
                                }}
                              />
                            </>
                          </StyledLinkButton>
                        </div>
                      </>
                    ) : (
                      <PreviewDocWithoutContainer>
                        <div style={{ display: "flex" }}>
                          <Typography variant="body4">
                            Documento não enviado
                          </Typography>
                        </div>
                      </PreviewDocWithoutContainer>
                    )}
                  </ArchiveContainer>
                )
              })}
            </div>
          </>
        ) : (
          <></>
        )}

        {filterOtherFields.length ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {filterOtherFields.map((f, idx) => (
              <RenderPreviewField
                key={idx}
                value={f.value}
                component={f.component}
                label={f.placeholder as string}
              />
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  }, [])

  const renderCandidateData = (documents: ConfirmationDocumentsWithValue[]) => {
    return (
      <StyledAccordion
        style={{
          marginBottom: "24px",
        }}
      >
        <AccordionSummary
          expandIcon={
            <StyledContainerIcon>
              <Icons name="IconChevronDown" fill="transparent" />
            </StyledContainerIcon>
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              textAlign: "left",
              marginRight: "20px",
            }}
          >
            <AccordionTitle variant="headline8">
              {t("historicContent.candidateDocTitle")}
            </AccordionTitle>
            <AccordionDescription variant="body4">
              {t("historicContent.candidateDocSubtitle")}
            </AccordionDescription>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px 44px 0px 44px" }}>
          {documents
            .filter((doc) => doc.enabled)
            .map((doc, idx) => {
              const indexes = `candidate_document_${idx}`

              return (
                <StyledAccordion
                  key={indexes}
                  style={{
                    marginBottom: "24px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <StyledContainerIcon>
                        <Icons name="IconChevronDown" fill="transparent" />
                      </StyledContainerIcon>
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        textAlign: "left",
                        marginRight: "20px",
                      }}
                    >
                      <AccordionTitle variant="headline8">
                        {doc.title}
                      </AccordionTitle>
                      <AccordionDescription variant="body4">
                        {doc?.description}
                      </AccordionDescription>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "0px 44px 0px 44px" }}>
                    {renderChildrenCandidateData(doc.fields)}
                  </AccordionDetails>
                </StyledAccordion>
              )
            })}
        </AccordionDetails>
      </StyledAccordion>
    )
  }

  const renderChildrenDependentData = (
    documents: ConfirmationDocumentsWithValue[],
  ) => {
    return (
      <AccordionChildren>
        {documents
          .filter((doc) => doc.enabled)
          .map((doc, index) => {
            const fields = doc.fields

            const filterOtherFields =
              fields.filter((f) => f.fieldType !== "boolean") || []

            return (
              <FlexColumn key={index}>
                <SectionTitle>{doc.title}</SectionTitle>
                <SectionSubTitle>{doc.description}</SectionSubTitle>

                {filterOtherFields.length && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {filterOtherFields.map((f, idx) => (
                      <RenderPreviewField
                        key={idx}
                        value={f.value}
                        component={f.component}
                        label={f.placeholder as string}
                      />
                    ))}
                  </div>
                )}
              </FlexColumn>
            )
          })}
      </AccordionChildren>
    )
  }

  const renderDependentData = (dependents: ConfirmationDependent[]) => {
    if (dependents.length === 0) return

    return (
      <StyledAccordion
        style={{
          marginBottom: "24px",
        }}
      >
        <AccordionSummary
          expandIcon={
            <StyledContainerIcon>
              <Icons name="IconChevronDown" fill="transparent" />
            </StyledContainerIcon>
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              textAlign: "left",
              marginRight: "20px",
            }}
          >
            <AccordionTitle variant="headline8">
              {t("historicContent.dependentDocTitle")}
            </AccordionTitle>
            <AccordionDescription variant="body4">
              {t("historicContent.dependentDocSubtitle")}
            </AccordionDescription>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px 44px 0px 44px" }}>
          {dependents.map((dependent, idx) => {
            const indexes = `dependent_${idx}`
            return (
              <StyledAccordion
                key={indexes}
                style={{
                  marginBottom: "24px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <StyledContainerIcon>
                      <Icons name="IconChevronDown" fill="transparent" />
                    </StyledContainerIcon>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      textAlign: "left",
                      marginRight: "20px",
                    }}
                  >
                    <AccordionTitle variant="headline8">
                      {dependent.name}
                    </AccordionTitle>
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px 44px 0px 44px" }}>
                  {renderChildrenDependentData(dependent.documents)}
                </AccordionDetails>
              </StyledAccordion>
            )
          })}
        </AccordionDetails>
      </StyledAccordion>
    )
  }

  const renderSignedComponent = () => {
    if (!contractData?.lastContractOutput) return null
    return (
      <GenericAccordion
        title={t("historicContent.signingContractTitle")}
        description={t("historicContent.signingContractDescription")}
      >
        <>
          <UploadPreview
            uploadItem={contractData?.lastContractOutput}
            onRemove={null}
          />

          <div style={{ marginTop: "4px" }}>
            <StyledLinkButton
              variant="primary"
              style={{ alignSelf: "center" }}
              onClick={async () => {
                if (!contractData?.lastContractOutput?.value) return
                navigator.clipboard.writeText(
                  contractData?.lastContractOutput.value,
                )
                dispatchToast({
                  content: "Link copiado",
                  type: "success",
                })
              }}
            >
              <>
                <ShareText variant="caption" style={{ fontWeight: 700 }}>
                  Compartilhar link
                </ShareText>
                <Icons
                  name="IconLink"
                  size={16}
                  style={{
                    fill: "transparent",
                    color: "#6B5B66",
                  }}
                />
              </>
            </StyledLinkButton>
          </div>
        </>
      </GenericAccordion>
    )
  }

  const renderProposedLetter = () => {
    const fields = [
      {
        title: "Status da proposta",
        value: !candidate?.proposalLetterInfo?.proposalStatus
          ? "Sem resposta do candidato"
          : candidate?.proposalLetterInfo?.proposalStatus === "refused"
            ? "Recusado"
            : "Aceito",
      },
      {
        title: "Cargo",
        value: candidate?.proposalLetterInfo?.role?.name || "Não preenchido",
      },
      {
        title: "Departamento",
        value:
          candidate?.proposalLetterInfo?.department?.name || "Não preenchido",
      },
      {
        title: "Tipo de vínculo",
        value:
          parseHiringType(candidate?.proposalLetterInfo?.hiringType || "") ||
          "Não preenchido",
      },
      {
        title: "Remuneração fixa",
        value: candidate?.proposalLetterInfo?.salary
          ? formatCurrency(candidate?.proposalLetterInfo?.salary)
          : "Não preenchido",
      },
      {
        title: "Data prevista de admissão",
        value:
          renderDate(candidate?.proposalLetterInfo?.hiringDate) ||
          "Não preenchido",
      },
    ]

    const archives = [
      {
        name: "Sobre a empresa",
        value: candidate.proposalLetterInfo?.aboutCompany?.[0],
      },
      {
        name: "Benefícios",
        value: candidate.proposalLetterInfo?.benefits?.[0],
      },
    ]

    return (
      <GenericAccordion
        title={t("historicContent.proposalLetterTitle")}
        description={t("historicContent.proposalLetterDescription")}
      >
        <>
          {fields.map((field) => (
            <FieldData
              key={field.title}
              title={field.title}
              value={field.value}
            />
          ))}

          {archives.length > 0 && (
            <GenericAccordion
              title={t("historicContent.proposalLetterArchives")}
              description={t(
                "historicContent.proposalLetterArchivesDescription",
              )}
            >
              <div>
                {archives.map((archive, idx) => {
                  const { value = "", key = "" } = archive.value || {}
                  const lastIndexName = key ? key.lastIndexOf("/") : ""
                  const nameOfArchive =
                    key && lastIndexName
                      ? key.substring(lastIndexName + 1)
                      : "Nome não identificado"

                  return (
                    <ArchiveContainer key={idx}>
                      {value ? (
                        <>
                          <PreviewDocContainer>
                            <div style={{ display: "flex" }}>
                              <PreviewImageContainer url={value} />
                              <PreviewTextContainer
                                style={{ marginLeft: "10px" }}
                              >
                                <Typography variant="body3">
                                  {archive.name || ""}
                                </Typography>
                              </PreviewTextContainer>
                            </div>
                            <IconButton
                              size="medium"
                              variant="line"
                              icon="IconDownload"
                              onClick={async () => {
                                const readFile = await fetch(value, {
                                  mode: "cors",
                                  cache: "no-cache",
                                  credentials: "same-origin",
                                })

                                const fileToArrayBuffer =
                                  await readFile.arrayBuffer()
                                const res = URL.createObjectURL(
                                  new Blob([fileToArrayBuffer]),
                                )
                                const a = document.createElement("a")
                                a.href = res
                                a.download = nameOfArchive
                                a.click()

                                URL.revokeObjectURL(res)
                              }}
                              style={{ marginLeft: "10px" }}
                            />
                          </PreviewDocContainer>
                          <div style={{ marginTop: "4px" }}>
                            <StyledLinkButton
                              variant="primary"
                              style={{ alignSelf: "center" }}
                              onClick={async () => {
                                navigator.clipboard.writeText(value)
                                dispatchToast({
                                  content: "Link copiado",
                                  type: "success",
                                })
                              }}
                            >
                              <>
                                <ShareText
                                  variant="caption"
                                  style={{ fontWeight: 700 }}
                                >
                                  Compartilhar link
                                </ShareText>
                                <Icons
                                  name="IconLink"
                                  size={16}
                                  style={{
                                    fill: "transparent",
                                    color: "#6B5B66",
                                  }}
                                />
                              </>
                            </StyledLinkButton>
                          </div>
                        </>
                      ) : (
                        <PreviewDocWithoutContainer>
                          <div style={{ display: "flex" }}>
                            <Typography variant="body4">
                              Documento não enviado
                            </Typography>
                          </div>
                        </PreviewDocWithoutContainer>
                      )}
                    </ArchiveContainer>
                  )
                })}
              </div>
            </GenericAccordion>
          )}
        </>
      </GenericAccordion>
    )
  }

  const renderCorporateEmailComponent = () => {
    return (
      <GenericAccordion
        title={"E-mail corporativo"}
        description={"Endereço de e-mail e agendamento de boas-vindas"}
      >
        <>
          <div style={{ marginBottom: "24px" }}>
            <Typography
              variant="body4"
              style={{ color: "#856F7E", fontWeight: 600 }}
            >
              E-mail corporativo
            </Typography>
            <Typography
              variant="body3"
              style={{ color: "#6B5B66", fontWeight: 600 }}
            >
              {candidate?.corporateEmail}
            </Typography>
          </div>

          <div>
            <Typography
              variant="body4"
              style={{ color: "#856F7E", fontWeight: 600 }}
            >
              Convite de boas-vindas
            </Typography>
            <Typography
              variant="body3"
              style={{ color: "#6B5B66", fontWeight: 600 }}
            >
              {dayjs(candidate?.inviteDate).isValid()
                ? "Agendar envio"
                : "Enviar imediatamente"}
            </Typography>
          </div>

          {dayjs(candidate?.inviteDate).isValid() ? (
            <div style={{ display: "flex", marginTop: "24px" }}>
              <div style={{ marginRight: "64px" }}>
                <Typography
                  variant="body4"
                  style={{ color: "#856F7E", fontWeight: 600 }}
                >
                  Data de envio
                </Typography>
                <Typography
                  variant="body3"
                  style={{ color: "#6B5B66", fontWeight: 600 }}
                >
                  {dayjs(candidate?.inviteDate)?.format("DD/MM/YYYY")}
                </Typography>
              </div>

              <div>
                <Typography
                  variant="body4"
                  style={{ color: "#856F7E", fontWeight: 600 }}
                >
                  Hora do Envio
                </Typography>
                <Typography
                  variant="body3"
                  style={{ color: "#6B5B66", fontWeight: 600 }}
                >
                  {dayjs(candidate?.inviteDate)?.format("HH:mm")}
                </Typography>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      </GenericAccordion>
    )
  }

  return (
    <Container>
      <RightContainer>
        <SubContainer>
          {isConfirmationLoading || isContractLoading ? (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={"300px"}
              style={{ marginBottom: "40px" }}
            />
          ) : (
            <>
              {!candidate?.corporateEmail ? (
                <div style={{ marginBottom: "24px" }}>
                  <NotificationCard.Root
                    variant="contained"
                    type="info"
                    showCloseButton={false}
                  >
                    <>
                      <NotificationCard.Icon iconName="IconInfoCircle" />
                      {candidate?.employeeId ? (
                        <NotificationCard.WrapperTexts>
                          <Typography
                            variant="body3"
                            style={{
                              color: "#00244C",
                              fontWeight: 700,
                              marginBottom: "4px",
                            }}
                          >
                            Configurar e-mail e convite de boas-vindas
                          </Typography>
                          <Typography
                            variant="body4"
                            style={{
                              color: "#00244C",
                              fontWeight: 400,
                              marginBottom: "16px",
                            }}
                          >
                            É necessário definir o e-mail corporativo e realizar
                            o envio do convite de boas-vindas à plataforma para
                            concluir o processo de admissão de candidatos.
                          </Typography>
                          <LinkButton
                            variant="secondary"
                            onClick={() =>
                              navigate(
                                `/employees/${candidate?.employeeId}/manage`,
                              )
                            }
                          >
                            Configurar
                          </LinkButton>
                        </NotificationCard.WrapperTexts>
                      ) : (
                        <NotificationCard.WrapperTexts>
                          <Typography
                            variant="body3"
                            style={{
                              color: "#00244C",
                              fontWeight: 700,
                              marginBottom: "4px",
                            }}
                          >
                            Novo colaborador sendo cadastrado
                          </Typography>
                          <Typography
                            variant="body4"
                            style={{ color: "#00244C", fontWeight: 400 }}
                          >
                            Em alguns minutos você terá acesso ao{" "}
                            <b>perfil do colaborador</b> na aba de pessoas e
                            poderá realizar as <b>configurações finais</b>.
                            Enquanto isso, você pode conferir todas as
                            informações registradas nas etapas do processo de
                            admissão.
                          </Typography>
                        </NotificationCard.WrapperTexts>
                      )}
                    </>
                  </NotificationCard.Root>
                </div>
              ) : (
                <></>
              )}
              <StyledAccordion
                style={{
                  marginBottom: "24px",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <StyledContainerIcon>
                      <Icons name="IconChevronDown" fill="transparent" />
                    </StyledContainerIcon>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      textAlign: "left",
                      marginRight: "20px",
                    }}
                  >
                    <AccordionTitle variant="headline8">
                      {t("historicContent.recruitmentDataTitle")}
                    </AccordionTitle>
                    <AccordionDescription variant="body4">
                      {t("historicContent.recruitmentDataDescription")}
                    </AccordionDescription>
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px 44px 40px 44px" }}>
                  <div
                    style={{
                      marginTop: "24px",
                      marginBottom: "24px",
                    }}
                  >
                    <ContainedInput
                      defaultValue={
                        metadata?.candidate?.linkedin ||
                        t("historicContent.notIncluded")
                      }
                      hasNotDisableDesign={true}
                      name={"linkedin"}
                      disabled={true}
                      label={"Linkedin"}
                      type={"text"}
                      placeholder={t("historicContent.linkedinPlaceholder")}
                    />
                  </div>
                  <ContainedInput
                    defaultValue={
                      metadata?.candidate?.vacancyLink ||
                      t("historicContent.notIncluded")
                    }
                    hasNotDisableDesign={true}
                    name={"vacancyLink"}
                    disabled={true}
                    label={t("historicContent.vacantJobLabel")}
                    type={"text"}
                    placeholder={t("historicContent.vacantJobPlaceholder")}
                  />
                </AccordionDetails>
              </StyledAccordion>

              {renderProposedLetter()}

              {confirmationGroups && (
                <>
                  {renderCandidateData(confirmationGroups.documentFields)}
                  {confirmationGroups?.dependents &&
                    renderDependentData(confirmationGroups.dependents)}
                </>
              )}

              {renderSignedComponent()}

              {candidate?.corporateEmail ? (
                renderCorporateEmailComponent()
              ) : (
                <></>
              )}
            </>
          )}
        </SubContainer>
      </RightContainer>
    </Container>
  )
}

export default PJContent
