import React from "react"
import { useTranslation } from "react-i18next"
import {
  ButtonWrapper,
  RadioWrapper,
  StyleStatusButton,
  ValidationButtonWrapper,
} from "./styles"
import {
  Button,
  Icons,
  Loader,
  Radio,
  TextArea,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { trpc } from "@api/client"
import { ResignationCard, ValidationStatusOptions } from "bff/src/types/hiring"
import dispatchToast from "@utils/dispatchToast"
import { useQueryClient } from "@tanstack/react-query"

type ValidationOptions = "toResend" | "toAccept" | undefined
type ComponentProps = { card: ResignationCard; canResend: boolean }

function getValidationStatusOption(
  status?: ValidationStatusOptions,
): ValidationOptions {
  switch (status) {
    case "approved":
    case "declined":
      return "toAccept"
    case "refused":
      return "toResend"
    default:
      return undefined
  }
}

export const DocumentValidation = ({ card, canResend }: ComponentProps) => {
  const [validationStatus, setValidationStatus] =
    React.useState<ValidationOptions>(
      getValidationStatusOption(card.resignationExam?.validation?.status),
    )
  const [refuseReason, setRefuseReason] = React.useState<string>(
    card.resignationExam?.validation?.statusComment ?? "",
  )

  const queryClient = useQueryClient()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignation.exam.validation",
  })

  const { mutateAsync: validateAso } =
    trpc.resignationCard.validateAso.useMutation()
  const { mutateAsync: resendAsoRequest, isLoading: isResending } =
    trpc.resignationCard.resendAsoRequest.useMutation()

  const handleRefuse = async () => {
    if (!refuseReason || refuseReason.length === 0) {
      dispatchToast({
        content: t("refuseReasonError", { keyPrefix: "page.resignation.exam" }),
        type: "error",
      })
      return
    }

    await validateAso({
      cardId: card._id,
      status: "refused",
      statusComment: refuseReason,
    })

    if (canResend) {
      await resendAsoRequest({
        cardId: card._id,
        columnId: card.columnId,
        employeeId: card.employeeId,
        reason: refuseReason,
      })

      dispatchToast({
        content: t("successToast"),
        type: "success",
      })
    }

    queryClient.invalidateQueries()
  }

  return (
    <>
      <ValidationButtonWrapper>
        <StyleStatusButton
          size="medium"
          buttonType="secondary"
          variantType="error"
          disabled={["approved", "declined"].includes(
            card.resignationExam?.validation?.status ?? "waiting",
          )}
          onClick={() => setValidationStatus("toResend")}
          active={validationStatus === "toResend"}
        >
          <Icons name="IconX" fill="transparent" />
          {t("reproveLabel")}
        </StyleStatusButton>

        <StyleStatusButton
          size="medium"
          buttonType="secondary"
          variantType="success"
          onClick={() => setValidationStatus("toAccept")}
          disabled={card.resignationExam?.validation?.status === "refused"}
          active={validationStatus === "toAccept"}
        >
          <Icons name="IconCheck" fill="transparent" />
          {t("approveLabel")}
        </StyleStatusButton>
      </ValidationButtonWrapper>

      {validationStatus === "toResend" && (
        <>
          <TextArea
            value={refuseReason}
            maxLength={100}
            placeholder={t("textAreaPlaceholder")}
            onChange={(value: any) => setRefuseReason(value.target.value)}
            disabled={!!card.resignationExam?.validation?.statusComment}
            onBlur={() => {
              if (canResend) return
              handleRefuse()
            }}
          />

          {canResend ? (
            <ButtonWrapper>
              <Button
                variant="primary"
                onClick={handleRefuse}
                size="medium"
                disabled={isResending}
              >
                {isResending ? (
                  <Loader size="extraSmall" variant="secondary" />
                ) : (
                  <>
                    {t("resendButtonText")}
                    <Icons fill="transparent" name="IconMail" />
                  </>
                )}
              </Button>
            </ButtonWrapper>
          ) : (
            <></>
          )}
        </>
      )}

      {validationStatus === "toAccept" && (
        <div>
          <Typography
            variant="body4"
            variantColor="#83727D"
            style={{ marginBottom: 8 }}
          >
            {t("approveDescription")}
          </Typography>

          <RadioWrapper>
            <Radio
              name="radio-validation-buttons"
              value={"approved"}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                await validateAso({
                  cardId: card._id,
                  status: e.target.value as ValidationStatusOptions,
                })
                queryClient.invalidateQueries()
              }}
              checked={card.resignationExam?.validation?.status === "approved"}
            />
            <Typography variant="body3" variantColor="#53464F">
              {t("radioAbleToWorkLabel")}
            </Typography>

            <Radio
              name="radio-validation-buttons"
              value={"declined"}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                await validateAso({
                  cardId: card._id,
                  status: e.target.value as ValidationStatusOptions,
                })
                queryClient.invalidateQueries()
              }}
              checked={card.resignationExam?.validation?.status === "declined"}
            />
            <Typography variant="body3" variantColor="#53464F">
              {t("radioNotAbleToWorkLabel")}
            </Typography>
          </RadioWrapper>
        </div>
      )}
    </>
  )
}
