import { Signature, SignatureSignStatus, SignType } from "@customTypes/contract"
import i18n from "@i18n"

export function getSignatureStatus(signatures: Signature[]) {
  const signaturesTypesToSign = signatures.filter(
    ({status}) => status !== SignatureSignStatus.signed
  )

  switch (signaturesTypesToSign.length) {
    case 1: {
      const companySignature = signaturesTypesToSign.find(
        (s) => s.type === SignType.company,
      )

      if (!companySignature) {
        return i18n.t("signatureStatus.singlePendingSignature")
      }

      return i18n.t("signatureStatus.defaultPendingSignature")
    }
    case 2:
    case 3:
    case 4:
    case 5:
      return i18n.t("signatureStatus.multiplePendingSignatures", {
        pendingSignaturesCount: signaturesTypesToSign.length,
      })
    default:
      return i18n.t("signatureStatus.defaultPendingSignature")
  }
}
