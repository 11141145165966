import { useContext, useEffect, useMemo, useRef, useState } from "react"

import { Mention } from "react-mentions"
import ProfilePicture from "../ProfilePicture"

import { Icons, dayjs } from "@flash-tecnologia/hros-web-ui-v2"

import {
  CommentaryWrapper,
  Container,
  StyledButton,
  Title,
  CommentaryContainer,
  CommentaryContent,
  CommentaryTitle,
  CommentaryText,
  InfoText,
  CommentaryMention,
  CommentaryInfo,
  StyledSubtitleContainer,
  StyledDescription,
  StyledMentionsInput,
  mentionStyle,
  TextareaWrapper,
  TextareaContainer,
} from "./styles"

import _ from "lodash"
import dispatchToast from "../../../utils/dispatchToast"
import { globalContext } from "../../../context"

import relativeTime from "dayjs/plugin/relativeTime"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { api } from "../../../api"
import {
  Comment,
  FlowCategory,
  HiringCard,
  ResignationCard,
} from "../../../types"
import { trpc } from "@api/client"

dayjs.extend(relativeTime)

type MentionData = {
  id: string
  display: string
}

interface CommentaryProps {
  style?: React.CSSProperties
  card: HiringCard | ResignationCard
  flowType: FlowCategory
}

export const Commentary = ({ card, flowType, style }: CommentaryProps) => {
  const [commentary, setCommentary] = useState<string>("")
  const [comments, setComments] = useState<Comment[]>([])
  const [commentaryError, setCommentaryError] = useState<boolean>(false)
  const [isShowAll, setIsShowAll] = useState<boolean>(false)

  const timeoutRef = useRef<any>()
  const queryClient = useQueryClient()
  const { employee } = useContext(globalContext)

  const { mutateAsync: postComment } = useMutation(
    flowType === "hiring"
      ? api.mutation.hiring.card.postComment
      : api.mutation.resignation.card.postComment,
  )

  const { mutateAsync: getPartialEmployee } =
    trpc.employee.getEmployeeByPartialName.useMutation()

  useEffect(() => {
    if (card?.comments) setComments(card?.comments)
  }, [card])

  const orderedCommentaries = useMemo(
    () =>
      _.orderBy(
        comments,
        function (object) {
          return new Date(object.sentDate)
        },
        ["desc"],
      ),
    [comments],
  )

  const filteredCommentary = isShowAll
    ? orderedCommentaries
    : orderedCommentaries.slice(0, 3)

  const submitCommentary = async () => {
    if (!commentary) {
      setCommentaryError(true)

      dispatchToast({
        content: "Por favor, digite seu comentário!",
        type: "error",
      })
      setCommentary("")
      return
    }

    const previousComments = comments || []
    setCommentary("")
    setComments([
      ...previousComments,
      {
        message: commentary,
        authorName: employee?.name as string,
        authorEmployeeId: employee?.id as string,
        sentDate: dayjs().toDate().toString(),
        timeSince: dayjs(new Date().toISOString()).fromNow(),
      },
    ])

    await postComment(
      {
        message: commentary,
        flowCardId: card._id,
        version: card.version,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
        },
        onError: () => {
          setComments([...previousComments])
        },
      },
    )
  }

  const searchEmployee = async (
    searchParam: string,
    callback: (data: MentionData[]) => void,
  ) => {
    if (!searchParam || searchParam.length < 3) return

    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(async () => {
      const data = await getPartialEmployee({ searchParam })
      const displayData: MentionData[] = data.map(({ id, name: display }) => ({
        id,
        display,
      }))
      callback(displayData)
    }, 700)
  }

  function renderCommentary(str: string) {
    const mentionRegex = /@\[(.*?)\]\((.*?)\)/g
    const mentions = str.match(mentionRegex)

    if (!mentions || mentions?.length === 0)
      return <CommentaryText>{str}</CommentaryText>

    const splitMentionsRegex = /(@\[(.*?)\]\(([^)]+)\))|([^@]+)/g
    const commentarySplitted = str.match(splitMentionsRegex)

    if (!commentarySplitted) return <CommentaryText>{str}</CommentaryText>

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {commentarySplitted.map((text, idx) => {
          if (text && mentions.includes(text)) {
            const mentionName = text.slice(
              text.indexOf("[") + 1,
              text.indexOf("]"),
            )
            return (
              <CommentaryMention key={idx}>@{mentionName}</CommentaryMention>
            )
          }

          return <CommentaryText key={idx}>{text}</CommentaryText>
        })}
      </div>
    )
  }

  return (
    <Container style={style}>
      <Title>Comentários</Title>

      <TextareaContainer>
        <TextareaWrapper>
          <StyledMentionsInput
            value={commentary}
            onChange={(e) => {
              setCommentary(e.target.value)

              if (commentaryError) setCommentaryError(false)
            }}
            placeholder={"Digite seu comentário"}
            style={mentionStyle}
            maxLength={100}
            allowSpaceInQuery={true}
            width={"100%"}
            autoFocus={false}
          >
            <Mention trigger="@" data={searchEmployee} />
          </StyledMentionsInput>

          <StyledSubtitleContainer>
            <StyledDescription variant="caption">
              {commentary.length || "000"}/100
            </StyledDescription>
          </StyledSubtitleContainer>
        </TextareaWrapper>

        <StyledButton variant="primary" size="large" onClick={submitCommentary}>
          <Icons name="IconMessage" fill="transparent" size={32} />
        </StyledButton>
      </TextareaContainer>

      <CommentaryContainer>
        {filteredCommentary.map((commentary, idx) => {
          return (
            <CommentaryWrapper key={idx}>
              <ProfilePicture
                userName={commentary?.authorName}
                src={undefined}
                alt="Foto de perfil do usuario"
                width={"50px"}
                height={"50px"}
                fontSize={"20px"}
              />

              <CommentaryContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CommentaryTitle>{commentary?.authorName}</CommentaryTitle>
                  <CommentaryInfo>{commentary?.timeSince}</CommentaryInfo>
                </div>

                {renderCommentary(commentary?.message)}
              </CommentaryContent>
            </CommentaryWrapper>
          )
        })}

        {comments?.length > 3 ? (
          <InfoText onClick={() => setIsShowAll((prev) => !prev)}>
            {isShowAll ? "Ver menos..." : "Ver mais..."}
            <Icons
              name={isShowAll ? "IconChevronUp" : "IconChevronDown"}
              fill="transparent"
              color="#FE2B8F"
              size={14}
            />
          </InfoText>
        ) : (
          <></>
        )}
      </CommentaryContainer>
    </Container>
  )
}
