import { useEffect, useRef, useState } from "react"

import { Droppable, Draggable } from "react-beautiful-dnd"

import { TitleCard } from "./titleCard"
import { CardList } from "./card-list"

import {
  ColumnContainer,
  SubColumnContainer,
  HeaderColumnContainer,
  HiddenContainer,
  StyledPOneLine,
} from "./styles"

import { Icons, Menu, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Card, ColumnWithCards, FlowCategory } from "../../../types"

import dispatchToast from "../../../utils/dispatchToast"

import { COLUMN_LOCK } from "../../../api/mutations/column-lock"
import { COLUMN_VISIBILITY } from "../../../api/mutations/column-change-visibility"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { request } from "../../../api/client"
import { PendoEnum, replacePendoVariable } from "@utils/pendo"

export type CompanyData = {
  companyId: string
  setupId: string
  flowId: string
}

interface ColProps<CardType> {
  data: ColumnWithCards<CardType>
  company: CompanyData
  columnIndex: number
  category: FlowCategory
}

export const Col = <CardType extends Card>({
  data,
  company,
  category,
  columnIndex,
}: ColProps<CardType>) => {
  const queryClient = useQueryClient()
  const [localColumn, setLocalColumn] =
    useState<ColumnWithCards<CardType>>(data)

  useEffect(() => setLocalColumn(data), [data])

  const { mutate: setColumnLock } = useMutation(
    async ({ params }: { params: any }) => {
      await request(COLUMN_LOCK, { params })
    },
    {
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro no card.",
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries()
      },
    },
  )

  const { mutate: setColumnVisibility } = useMutation(
    async ({ params }: { params: any }) => {
      await request(COLUMN_VISIBILITY, { params })
    },
    {
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro no card.",
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries()
      },
    },
  )

  const {
    _id = "",
    locked = false,
    hidden = false,
    cards = [],
    name,
  } = localColumn

  const handleUpdateColumn = (updatedColumn) => setLocalColumn(updatedColumn)

  const { flowId } = company
  window["__react-beautiful-dnd-disable-dev-warnings"] = true

  const filterRef = useRef(null)

  return (
    <>
      {hidden ? (
        <Draggable
          key={_id + "hidden"}
          draggableId={_id}
          index={columnIndex}
          isDragDisabled={true}
        >
          {(provided: { [key: string]: any }) => {
            return (
              <HiddenContainer
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div
                  ref={filterRef}
                  style={{
                    display: "inline-block",
                    position: "relative",
                    userSelect: "none",
                    zIndex: "2",
                    writingMode: "horizontal-tb",
                  }}
                >
                  <Menu
                    options={[
                      {
                        onClick: async () => {
                          await setColumnLock({
                            params: {
                              flowId,
                              flowColumnId: _id,
                            },
                          })
                        },
                        children: (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              userSelect: "none",
                            }}
                          >
                            <Icons
                              name={locked ? "IconLock" : "IconLockOpen"}
                              fill={"transparent"}
                              style={{
                                marginRight: "8px",
                              }}
                            />
                            <Typography
                              variant="body3"
                              style={{ fontWeight: 700 }}
                            >
                              {locked ? "Destravar fase" : "Travar fase"}
                            </Typography>
                          </div>
                        ),
                      },
                      {
                        onClick: async () => {
                          await setColumnVisibility({
                            params: {
                              flowId,
                              flowColumnId: _id,
                            },
                          })
                        },
                        children: (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              userSelect: "none",
                            }}
                          >
                            <Icons
                              name={hidden ? "IconEye" : "IconEyeOff"}
                              fill={"transparent"}
                              style={{
                                marginRight: "8px",
                              }}
                            />
                            <Typography
                              variant="body3"
                              style={{ fontWeight: 700 }}
                            >
                              {hidden ? "Mostrar fase" : "Ocultar fase"}
                            </Typography>
                          </div>
                        ),
                      },
                    ]}
                    type="default"
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <Icons
                        name="IconDotsVertical"
                        style={{
                          stroke: "#53464F",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Menu>
                </div>

                <StyledPOneLine numberOfLines={1}>
                  <Typography
                    variant="headline8"
                    style={{ fontWeight: 700, color: "#53464F" }}
                  >
                    {name}
                  </Typography>
                </StyledPOneLine>
              </HiddenContainer>
            )
          }}
        </Draggable>
      ) : (
        <Draggable
          key={_id + "open"}
          draggableId={_id}
          index={columnIndex}
          isDragDisabled={true}
        >
          {(
            provided: { [key: string]: any },
            snapshot: { [key: string]: any },
          ) => {
            const isDragginColumn = snapshot?.isDragging || false
            return (
              <ColumnContainer
                key={_id}
                ref={provided.innerRef}
                data-onboarding={replacePendoVariable(PendoEnum.KANBAN_COLUMN, [
                  { name: "$column", value: columnIndex.toString() },
                ])}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <HeaderColumnContainer>
                  <TitleCard
                    isDragging={isDragginColumn}
                    company={company}
                    data={data}
                    handleUpdateData={handleUpdateColumn}
                  />
                </HeaderColumnContainer>

                <Droppable type={"Card"} droppableId={_id}>
                  {(
                    dropProvided: { [key: string]: any },
                    dropSnapshot: { [key: string]: any },
                  ) => (
                    <>
                      <SubColumnContainer isColumnDraggin={isDragginColumn}>
                        <CardList
                          columnIndex={columnIndex}
                          column={{ name, _id }}
                          data={cards}
                          category={category}
                          dropProvided={dropProvided}
                          dropSnapshot={dropSnapshot}
                        />
                      </SubColumnContainer>
                    </>
                  )}
                </Droppable>
              </ColumnContainer>
            )
          }}
        </Draggable>
      )}
    </>
  )
}
