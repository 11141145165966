import i18n from "@i18n"
import { ToastMessageCodes } from "./toastMessageCodes"

export const getGenericToastMessage = (code: string) => {
  return (
    {
      [ToastMessageCodes.DeleteSuccessfull]: i18n.t(
        "page.hiring.signature.messages.deleteSuccessfull",
      ),
      [ToastMessageCodes.FileUploadFailure]: i18n.t(
        "page.hiring.signature.messages.fileUploadFailure",
      ),
      [ToastMessageCodes.MissingInstitutionData]: i18n.t(
        "page.hiring.signature.messages.missingInstitutionData",
      ),
      [ToastMessageCodes.MissingInstitutionForm]: i18n.t(
        "page.hiring.signature.messages.missingInstitutionForm",
      ),
      [ToastMessageCodes.InvalidContractModel]: i18n.t(
        "page.hiring.signature.messages.invalidContractModel",
      ),
      [ToastMessageCodes.GenericContractFailure]: i18n.t(
        "page.hiring.signature.messages.genericContractFailure",
      ),
      [ToastMessageCodes.GenericSignatureFailure]: i18n.t(
        "page.hiring.signature.messages.genericSignatureFailure",
      ),
      [ToastMessageCodes.MissingCompanyEmployee]: i18n.t(
        "page.hiring.signature.messages.missingCompanyEmployee",
      ),
      [ToastMessageCodes.MissingModelContractFields]: i18n.t(
        "page.hiring.signature.messages.missingModelContractFields",
      ),
      [ToastMessageCodes.MissingLimitDate]: i18n.t(
        "page.hiring.signature.messages.missingLimitDate",
      ),
      [ToastMessageCodes.MissingModelOrTemplate]: i18n.t(
        "page.hiring.signature.messages.missingModelOrTemplate",
      ),
      [ToastMessageCodes.MissingWitnessData]: i18n.t(
        "page.hiring.signature.messages.missingWitnessData",
      ),
    }[code] ??
    i18n.t("page.hiring.signature.messages.genericSignatureFailure") ??
    ""
  )
}
