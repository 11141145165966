import gql from "graphql-tag"
import { request } from "../client"
import { ResignationCard } from "../../types/card"

export const RESIGNATION_UPDATE_CARD = gql`
  mutation ResignationUpdateCard($params: UpdateResignationCard) {
    resignationUpdateCard(params: $params) {
      _id
      setupId
      name
      description
      status
      code
      color
      active
      position
      columnId
      employeeId
      companyId
      companyName
      flowId
      version
      statusHistory {
        status
        columnId
      }
      metadata
      checklists {
        columnId
        items {
          checked
          label
        }
      }
      resignationInterviewObservation
      lastWorkingDate
      resignationRequestDate
      serveNoticePeriod
      resignation
      resignationEmail
      resignationReason
      resignationType
      comments {
        message
        authorEmployeeId
        authorName
        sentDate
        timeSince
      }
      resignationInterviewFile {
        key
        path
        value
        type
      }
      resignationLetter {
        key
        path
        value
        type
      }
      employee
      emailLink {
        name
        columnId
        link
        expired
      }
      limitDates
      resignationExam {
        validation
        medicalReferral {
          key
          path
          value
          type
        }
        limitDate
        date
        status
        file {
          key
          path
          value
          type
        }
      }
    }
  }
`

export const resignationUpdateCard = async (params: {
  name?: string
  description?: string
  color?: string
  metadata?: any
  flowCardId: string
  resignationInterviewObservation?: string
  resignationInterviewFile?: {
    key?: string
    path?: string
    type?: string
  } | null
  lastWorkingDate?: Date
  serveNoticePeriod?: boolean
  resignationRequestDate?: Date
  resignation?: string
  resignationEmail?: string
  resignationReason?: string
  resignationType?: string
  resignationLetter?: { key?: string; path?: string; type?: string } | null
}) => {
  const { resignationUpdateCard } = await request(RESIGNATION_UPDATE_CARD, {
    params,
  })
  return resignationUpdateCard as ResignationCard
}
