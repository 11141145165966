import { PageTemplate } from "../../../../components"

import { BodyContainer, HeaderContainer } from "./styled"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import {
  CreateSectionPageContextProvider,
  useCreateSectionPageContext,
} from "./context/CreateSectionPageContext"
import { useMemo } from "react"
import { SectionInfoStep } from "./components/SectionInfoStep"
import { PageFooter } from "./components/PageFooter"
import { AddFieldsStep } from "./components/AddFieldsStep"

const CreateSection = () => {
  const theme = useTheme()

  const { step, relatedFormConfigPagePath } = useCreateSectionPageContext()

  const CurrentStep = useMemo(() => {
    const stepComponentMap = {
      0: SectionInfoStep,
      1: AddFieldsStep,
    }

    return stepComponentMap[step]
  }, [step])

  return (
    <PageTemplate
      history={[
        {
          name: "Configurar modelo",
          href: relatedFormConfigPagePath,
        },
        { name: "Criar seção" },
      ]}
    >
      <HeaderContainer>
        <Typography variant="headline6" variantColor={theme.colors.neutral[20]}>
          Criar seção
        </Typography>
      </HeaderContainer>

      <BodyContainer>
        <CurrentStep />
      </BodyContainer>

      <PageFooter />
    </PageTemplate>
  )
}

const CreateSectionPage = () => (
  <CreateSectionPageContextProvider>
    <CreateSection />
  </CreateSectionPageContextProvider>
)

export default CreateSectionPage
