import { useNavigate, useParams } from "react-router-dom"
import {
  GenericModal,
  GenericModalConfirmationContent,
  RouteHistory,
} from "../../../../components"
import {
  Loader,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { SpinnerContainer } from "../../../Configuration/styles"
import { CompanyAddress, Recipient } from "../../../../types"
import { useMutation, useQuery } from "@tanstack/react-query"
import dispatchToast from "../../../../utils/dispatchToast"
import { cepParser } from "../../../../utils"
import { api } from "../../../../api"
import { PageContainer } from "../../../../components/screen/CardPageTemplate/styles"
import PageContent from "../PageContent"
import Navigator, {
  NavigatorLink,
} from "../../../../components/common/Navigator"
import { BodyContent } from "../PageContent/styles"
import React, { useCallback } from "react"
import FooterBar from "../PageContent/FooterBar"
import { useTheme } from "styled-components"
import { useFormik } from "formik"
import RecipientContactForm, {
  recipientValidationSchema,
} from "./forms/RecipientContactForm"
import AddressForm, { addressValidationSchema } from "./forms/AddressForm"
import { globalContext } from "../../../../context"
import { trpc } from "@api/client"

export const CardDeliveryPage: React.FC = () => {
  const { id: cardId = "" } = useParams()
  const navigate = useNavigate()
  const theme = useTheme()
  const context = React.useContext(globalContext)

  const [addressSelected, setAddressSelected] = React.useState<number>()
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const { data: getHiringCardByIdData, isInitialLoading: isCardLoading } =
    trpc.card.getHiringCardById.useQuery(
      { cardId },
      {
        enabled: !!cardId,
        refetchOnWindowFocus: false,
        retry: false,
        onError: (err: any) => {
          const defaultError = "Ocorreu um erro ao encontrar o candidato"
          const errorMessage =
            err.serviceError?.details?.[0]?.message ||
            err.serviceError?.message ||
            err.httpError?.message ||
            err?.message

          dispatchToast({
            content: errorMessage || defaultError,
            type: "error",
          })
          navigate("/flows/hiring")
        },
      },
    )

  const card = getHiringCardByIdData as unknown as any

  const { data: getOnlyFlowByIdData, isInitialLoading: isFlowLoading } =
    trpc.flow.getOnlyFlowById.useQuery(
      { flowId: card?.flowId },
      {
        enabled: !!card,
        refetchOnWindowFocus: false,
      },
    )

  const flow = getOnlyFlowByIdData as unknown as any

  React.useEffect(() => {
    if (card && !card?.candidate?.employeeId) {
      console.error("EmployeeId não encontrado", card)
      navigate(`/flows/hiring/initial-config/${cardId}`)
    }
  }, [card?.candidate?.employeeId])

  const { data: companyAddresses, isInitialLoading: isAddressesLoading } =
    trpc.company.getCompanyDeliveryAddresses.useQuery(undefined, {
      enabled: !!flow,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.length === 0) {
          setAddressSelected(-1)
        }
      },
      onError: (err: any) => {
        dispatchToast({
          content: "Ocorreu um erro ao buscar os endereços da empresa",
          type: "error",
        })
      },
    })

  const addressesOptions = React.useMemo(() => {
    const addNewAddressOption = {
      label: "Adicionar novo endereço",
      value: -1,
      disabled: false,
    }
    if (!companyAddresses) return [addNewAddressOption]
    const options = companyAddresses.map((address, index) => ({
      label: address.name,
      disabled: index === addressSelected,
      value: index,
    }))
    options.push({
      ...addNewAddressOption,
      disabled: addressSelected === -1,
    })
    return options
  }, [companyAddresses, addressSelected])

  const address = React.useMemo(() => {
    if (
      !companyAddresses ||
      addressSelected === undefined ||
      addressSelected < 0
    )
      return null
    const value = companyAddresses[addressSelected]
    return value
  }, [companyAddresses, addressSelected])

  const {
    mutateAsync: orderCardDelivery,
    isLoading: isSavingCardDeliveryAddress,
  } = useMutation(api.mutation.cardDelivery.orderCardDelivery, {
    onError: (err: any) => {
      const defaultError = "Ocorreu um erro solicitar o cartão"
      const errorMessage =
        err.serviceError?.details?.[0]?.message ||
        err.serviceError?.message ||
        err.httpError?.message ||
        err?.message

      dispatchToast({
        content: errorMessage || defaultError,
        type: "error",
      })
    },
    onSuccess: () => {
      dispatchToast({
        content: "Endereço salvo com sucesso",
        type: "success",
      })
      goBack()
    },
  })

  const pageHistory: RouteHistory[] = [
    {
      name: "Admissão",
      href: "/flows/hiring",
    },
    {
      name: "Configuração inicial",
      href: `/flows/hiring/initial-config/${cardId}`,
    },
    {
      name: "Solicitar cartão flash",
    },
  ]
  const breadcrumbs = pageHistory.map((history, index) => {
    return (
      <NavigatorLink
        key={`breadcrumb-link-${index}`}
        name={history.name}
        href={history.href}
      />
    )
  })

  const goBack = () => {
    navigate(`/flows/hiring/initial-config/${cardId}`)
  }

  const addressFormik = useFormik<CompanyAddress & { saveNewAddress: boolean }>(
    {
      initialValues: {
        name: address?.name ?? "",
        zipCode: cepParser(address?.zipCode || "") ?? "",
        street: address?.street ?? "",
        number: address?.number ?? "",
        complement: address?.complement ?? "",
        neighborhood: address?.neighborhood ?? "",
        city: address?.city ?? "",
        state: address?.state ?? "",
        reference: address?.reference ?? "",
        saveNewAddress: false,
      },
      onSubmit: () => {},
      validateOnChange: true,
      validationSchema: addressValidationSchema,
      validate: (values) => {
        if (values?.zipCode?.length !== 8) {
          return {
            zipCode: "CEP inválido",
          }
        }
        return {}
      },
      enableReinitialize: true,
      isInitialValid: (addressSelected || 0) >= 0,
    },
  )

  const recipientFormik = useFormik<Recipient>({
    initialValues: {
      name: "",
      phone: "",
      email: "",
    },
    onSubmit: () => {},
    validate: (values) => {
      if (values?.phone?.length !== 11) {
        return {
          phone: "Telefone inválido",
        }
      }
    },
    validationSchema: recipientValidationSchema,
    isInitialValid: false,
    validateOnChange: true,
  })

  const setFieldsTouched = (
    formik: typeof addressFormik | typeof recipientFormik,
  ) => {
    formik.setTouched(
      Object.keys(formik.values).reduce((acc, key) => {
        acc[key] = true
        return acc
      }, {} as any),
      false,
    )
  }

  const { isInitialLoading: isGettingAddressByZipCode } = useQuery(
    ["address", addressFormik.values.zipCode],
    () =>
      api.query.cardDelivery.getAddressByZipCode(addressFormik.values.zipCode),
    {
      enabled:
        (addressSelected || 0) < 0 &&
        addressFormik?.values?.zipCode?.length === 8,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        addressFormik.setValues(
          {
            ...addressFormik.values,
            ...data,
            state:
              data?.state?.toUpperCase() || addressFormik.values.state || "",
          },
          true,
        )
        setFieldsTouched(addressFormik)
      },
    },
  )

  const handleSaveButtonClick = () => {
    addressFormik.submitForm()
    let hasError = false
    if (!addressFormik.isValid) {
      dispatchToast({
        content: "Verifique os campos do formulário de endereço",
        type: "error",
      })
      hasError = true
    }

    recipientFormik.submitForm()
    if (!recipientFormik.isValid) {
      dispatchToast({
        content: "Verifique os campos do formulário de contato do destinatário",
        type: "error",
      })
      hasError = true
    }

    if (hasError) return

    setIsModalOpen(true)
  }

  const handleSave = async () => {
    const { saveNewAddress, ...address } = addressFormik.values
    const recipient = recipientFormik.values
    await orderCardDelivery({
      params: {
        adminId: context.employeeId || "",
        candidateId: card?.candidate._id || "",
        employeeId: card?.candidate.employeeId || "",
        saveAddress: saveNewAddress,
        flowCardId: card?._id || "",
        version: card?.version || 0,
      },
      address,
      recipient,
    })
  }

  const closeModal = useCallback(() => {
    if (isSavingCardDeliveryAddress) return
    setIsModalOpen(false)
  }, [isSavingCardDeliveryAddress])

  const disabledInputs =
    isGettingAddressByZipCode ||
    isSavingCardDeliveryAddress ||
    addressSelected === undefined ||
    addressSelected >= 0

  if (!flow || !card || isFlowLoading || isCardLoading || isAddressesLoading) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  return (
    <PageContainer>
      <Navigator breadcrumbs={breadcrumbs} />
      <PageContent
        title="Solicitar cartão flash"
        subtitle="Dados da entrega"
        description="Preencha as informações de entrega do novo cartão."
        card={card}
        flow={flow}
      >
        <BodyContent
          style={{
            gap: theme.spacings.xs2,
            padding: theme.spacings.s,
          }}
        >
          <Typography variant="headline8" variantColor={theme.colors.neutral30}>
            Dados da entrega
          </Typography>
          <SelectField
            name="address"
            placeholder="Selecionar um endereço salvo *"
            // disabled={disabledInputs}
            label="Selecionar um endereço salvo *"
            options={addressesOptions}
            value={addressSelected}
            onSelectChange={(_, value) => {
              setAddressSelected(value.value)
            }}
            defaultValue={addressSelected}
          />
          <AddressForm
            formik={addressFormik}
            disabled={disabledInputs}
            disableSaveCheckbox={(companyAddresses || []).length >= 5}
          />
        </BodyContent>
        <BodyContent style={{ gap: theme.spacings.xs1 }}>
          <Typography variant="headline8" variantColor={theme.colors.neutral30}>
            Contato do destinatário
          </Typography>
          <RecipientContactForm formik={recipientFormik} />
        </BodyContent>
      </PageContent>
      <FooterBar
        actionButtons={[
          {
            variant: "link",
            description: "Cancelar",
            handleAction: goBack,
          },
          {
            variant: "primary",
            description: "Salvar",
            icon: "IconCheck",
            handleAction: handleSaveButtonClick,
            disabled: isSavingCardDeliveryAddress || isGettingAddressByZipCode,
            isLoading: isSavingCardDeliveryAddress,
          },
        ]}
      />
      <GenericModal
        handleClose={closeModal}
        isOpen={isModalOpen}
        showCloseButton={false}
        actionButtons={[
          {
            variant: "link",
            description: "Cancelar",
            handleAction: closeModal,
            isLoading: isSavingCardDeliveryAddress,
          },
          {
            variant: "primary",
            description: "Finalizar configuração",
            icon: "IconCheck",
            isLoading: isSavingCardDeliveryAddress,
            handleAction: handleSave,
          },
        ]}
      >
        <GenericModalConfirmationContent
          subtitle={"Atenção!"}
          title={"Gostaria de finalizar a solicitação de cartão?"}
          desciption={
            "O pedido poderá ser acompanhado na página de solicitação de cartão após finalização."
          }
        />
      </GenericModal>
    </PageContainer>
  )
}

export default CardDeliveryPage
