import {
  Button,
  IconButton,
  Loader,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"

import {
  StyledModal,
  StyledContainer,
  StyledFooter,
  StyledLinkButton,
  StyledTextLinkButton,
  StyledTitle,
  StyledDescription,
  CloseContainer,
  StyledHeader,
  StyledOrgTitle,
} from "./styles"
import { useContext, useState } from "react"
import { globalContext } from "../../../../context"
import dispatchToast from "../../../../utils/dispatchToast"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { SearchField } from "./SearchField"
import { api } from "../../../../api"
import { CompanyEmployee, ResignationCard } from "../../../../types"
import { LeadershipHandler } from "./LeadershipHandler"
import { trpc } from "@api/client"

type CreateOnePersonModalProps = {
  flowId: string
  isOpenModal: boolean
  setIsOpenModal: (value: boolean) => void
  cards: ResignationCard[]
}

function isAlreadyAdded(employeeId: string, cards: ResignationCard[]) {
  return cards.some((card) => card.employeeId === employeeId)
}

export const CreateOnePersonModal = ({
  flowId,
  isOpenModal,
  setIsOpenModal,
  cards,
}: CreateOnePersonModalProps) => {
  const [employeeOptions, setEmployeeOptions] = useState<CompanyEmployee[]>([])
  const [selectedEmployee, setSelectedEmployee] = useState<{
    label: string
    value: string
  } | null>(null)
  const [selectedLeader, setSelectedLeader] = useState<{
    label: string
    value: string
  } | null>(null)
  const [showLeadershipHandler, setShowLeadershipHandler] =
    useState<boolean>(false)
  const [selectedOption, setSelectedOption] =
    useState<string>("Substituir líder")

  const queryClient = useQueryClient()
  const { companyId } = useContext(globalContext)

  const { mutate: createCard, isLoading: isCreating } = useMutation(
    api.mutation.resignation.card.createCard,
  )

  const { mutate: checkLeader, isLoading: isCheckingLeader } = useMutation(
    api.mutation.orgchart.checkLeaderByEmployeeId,
    {
      onSuccess: (data) => {
        if (data?.value?.leaders?.length) {
          setShowLeadershipHandler(true)
        } else {
          setShowLeadershipHandler(false)
        }
      },
    },
  )

  const { mutate: changeEmployee, isLoading: isChangingEmployee } = useMutation(
    api.mutation.orgchart.changeEmployeeInOrgchart,
    {
      onSuccess: async () => {
        await createCard(
          {
            employeeId: selectedEmployee?.value || "",
            flowId,
          },
          {
            onSuccess: () => {
              setIsOpenModal(false)
              setShowLeadershipHandler(false)
              queryClient.invalidateQueries()
            },
            onError: (err: any) => {
              console.log(err)
              dispatchToast({
                type: "error",
                content: "Ocorreu um erro ao incluir o colaborador",
              })
            },
          },
        )
      },
    },
  )

  const { mutate: replaceChildren, isLoading: isReplacingChildren } =
    useMutation(api.mutation.orgchart.replaceChildrenInOrgchart, {
      onSuccess: async () => {
        await createCard(
          {
            employeeId: selectedEmployee?.value || "",
            flowId,
          },
          {
            onSuccess: () => {
              setIsOpenModal(false)
              setShowLeadershipHandler(false)
              queryClient.invalidateQueries()
            },
            onError: (err: any) => {
              console.log(err)
              dispatchToast({
                type: "error",
                content: "Ocorreu um erro ao incluir o colaborador",
              })
            },
          },
        )
      },
    })

  const { mutateAsync: searchEmployees, isLoading: isSearchingEmployees } =
    trpc.employee.getEmployeeByPartialName.useMutation()

  const handleCheckIsLeader = (value) => {
    checkLeader({ employeeIds: [value] })
  }

  return (
    <StyledModal open={isOpenModal} onClose={() => setIsOpenModal(false)}>
      <>
        <CloseContainer>
          <IconButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={() => {
              setIsOpenModal(false)
              setShowLeadershipHandler(false)
            }}
          />
        </CloseContainer>
        <StyledHeader>
          <div style={{ width: "535px" }}>
            <StyledTitle variant="headline6">Selecione a pessoa</StyledTitle>
            <StyledDescription variant="body3">
              Selecione uma pessoa e inclua-as no fluxo de desligamento.
              <br /> Não se preocupe, elas não serão notificadas sobre isso por
              enquanto.
            </StyledDescription>
          </div>
        </StyledHeader>
        <StyledContainer>
          <StyledOrgTitle variant="body3">
            Inclusão no fluxo de desligamento
          </StyledOrgTitle>

          <SearchField
            label="Selecione a pessoa"
            onSearchChange={(_, value) => {
              setSelectedEmployee(value)
              handleCheckIsLeader(value?.value)
            }}
            onInputChange={async (_, value) => {
              if (value.length > 2) {
                await searchEmployees(
                  { searchParam: value },
                  {
                    onSuccess: (data) => {
                      setEmployeeOptions(data as unknown as any)
                    },
                  },
                )
              }
            }}
            loading={isSearchingEmployees}
            options={employeeOptions?.map((t) => {
              return { label: t.name, value: t.id }
            })}
            style={{ marginTop: "16px" }}
          />

          {showLeadershipHandler && (
            <LeadershipHandler
              companyId={companyId || ""}
              setSelectedOption={setSelectedOption}
              setSelectedLeader={setSelectedLeader}
              selectedOption={selectedOption}
            />
          )}
        </StyledContainer>
        <StyledFooter>
          <div>
            <StyledLinkButton
              variant="primary"
              style={{ alignSelf: "center" }}
              onClick={() => {
                setIsOpenModal(false)
                setSelectedEmployee(null)
                setEmployeeOptions([])
                setShowLeadershipHandler(false)
                setSelectedLeader(null)
                setSelectedOption("Substituir líder")
              }}
            >
              <StyledTextLinkButton variant="body3">
                Cancelar
              </StyledTextLinkButton>
            </StyledLinkButton>
          </div>
          <div>
            <Button
              type={"submit"}
              size={"medium"}
              variant="primary"
              style={{ marginLeft: "24px", alignSelf: "center" }}
              disabled={isCreating || !selectedEmployee}
              onClick={async () => {
                switch (true) {
                  case !Object.keys(selectedEmployee || {}).length &&
                    !showLeadershipHandler:
                    dispatchToast({
                      content:
                        "Por favor, selecione a pessoa para desligamento",
                      type: "error",
                    })
                    return

                  case selectedOption == "Substituir líder" &&
                    showLeadershipHandler &&
                    !Object.keys(selectedLeader || {}).length:
                    dispatchToast({
                      content: "Por favor, selecione um líder",
                      type: "error",
                    })
                    return

                  case selectedOption == "Substituir líder" &&
                    showLeadershipHandler &&
                    !!Object.keys(selectedLeader || {}).length:
                    changeEmployee({
                      employeeId: selectedLeader?.value || "",
                      personToBeReplaced: selectedEmployee?.value || "",
                      levelToSave: 1,
                      keepChildrens: true,
                    })
                    return

                  case selectedOption == "Não substituir líder" &&
                    showLeadershipHandler:
                    replaceChildren({
                      employeeId: selectedEmployee?.value || "",
                    })
                    return

                  default: {
                    if (
                      !selectedEmployee?.value ||
                      isAlreadyAdded(selectedEmployee.value, cards)
                    ) {
                      dispatchToast({
                        type: "error",
                        content:
                          "Colaborador já pertence ao fluxo de desligamento.",
                      })
                      return
                    }

                    await createCard(
                      {
                        employeeId: selectedEmployee?.value || "",
                        flowId,
                      },
                      {
                        onSuccess: () => {
                          setIsOpenModal(false)
                          setShowLeadershipHandler(false)
                          queryClient.invalidateQueries()
                        },
                        onError: (err: any) => {
                          console.log(err)
                          dispatchToast({
                            type: "error",
                            content: "Ocorreu um erro ao incluir o colaborador",
                          })
                        },
                      },
                    )
                    break
                  }
                }
              }}
            >
              {isCreating ||
              isCheckingLeader ||
              isChangingEmployee ||
              isReplacingChildren ? (
                <Loader size="extraSmall" variant="secondary" />
              ) : (
                <Typography variant="body3" style={{ fontWeight: 700 }}>
                  Selecionar
                </Typography>
              )}
            </Button>
          </div>
        </StyledFooter>
      </>
    </StyledModal>
  )
}
