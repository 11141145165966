import { generatePath, useParams } from "react-router-dom"
import {
  REPLACE_LEADER_PAGE_URL,
  SELECT_PEOPLE_PAGE_URL,
} from "src/routes/routesConfig"
import { ResignationContext } from "../../../context"
import ReplaceLeader from "../../Resignation/ReplaceLeader"
import SelectPeople from "../../Resignation/SelectPeople"

export const ResignationConfigurationPage = () => {
  const { flowId = "" } = useParams()

  const selectPeoplePageRouterPath = generatePath(SELECT_PEOPLE_PAGE_URL, {
    flowId,
  })

  const replaceLeaderPageRouterPath = generatePath(REPLACE_LEADER_PAGE_URL, {
    flowId,
  })

  const pages = [
    {
      route: selectPeoplePageRouterPath,
      category: "selectPeople",
    },
    {
      route: replaceLeaderPageRouterPath,
      category: "replaceLeader",
    },
  ]

  const currentPage = pages?.find((page) => location.pathname == page.route)

  return (
    <ResignationContext>
      {currentPage?.category == "selectPeople" ? (
        <SelectPeople />
      ) : (
        <ReplaceLeader />
      )}
    </ResignationContext>
  )
}
