import { z } from "zod"

export type S3File = {
  key: string
  path: string
  value?: string
  type: "internal" | "external"
}

export const S3FileZodObject = z.object({
  key: z.string(),
  path: z.string(),
  type: z.enum(["internal", "external"]),
  value: z.string().optional(),
})

export const ContractOutputZodObject = S3FileZodObject.extend({
  version: z.number(),
})

export type Nullable<T> = { [K in keyof T]: T[K] | null }

export * from "./contract"
