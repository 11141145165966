import { ResignationCard } from "../../../types"

import { ExamContent } from "./ExamContent"
import { InterviewContent } from "./InterviewContent"
import { OnHoldContent } from "./OnHoldContent"
import { RescissionContent } from "./RescissionContent"
import { SignatureContent } from "./SignatureContent"
import { StartProcessContent } from "./StartProcessContent"
import { UpdateContractContent } from "./UpdateContractContent"

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import { Container } from "./styles"

import { trpc } from "@api/client"

export const ArchivedContent = ({ card }: { card: ResignationCard }) => {
  const { data: contract, isInitialLoading: isContractLoading } =
    trpc.contract.getContractByFlowCardId.useQuery(
      {
        flowCardId: card._id,
      },
      {
        queryKey: ["contract.getContractByFlowCardId", { flowCardId: card._id }],
        enabled: !!card._id,
        refetchOnWindowFocus: false,
      },
    )

  return (
    <Container>
      {isContractLoading ? (
        <Skeleton variant="rectangular" width={"100%"} height={"200px"} />
      ) : (
        <>
          {card.employee && <OnHoldContent employee={card.employee} />}
          <StartProcessContent resignationCard={card} />
          <InterviewContent resignationCard={card} />
          <ExamContent resignationCard={card} />
          <RescissionContent resignationCard={card} />
          <SignatureContent contract={contract} />
          <UpdateContractContent resignationCard={card} />
        </>
      )}
    </Container>
  )
}
