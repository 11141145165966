import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { AccordionActive, RowValue } from "../../components"
import { useTheme } from "styled-components"
import { useNavigate } from "react-router-dom"
import { CardMovement, NotificationsConfig } from "bff/src/types/hiring"
import { Flow } from "src/types"
import {
  columnsStageInfo,
  hiringFlowStageMap,
} from "../../../../../mock/flowStages"
import { trpc } from "src/api/client"
import dispatchToast from "src/utils/dispatchToast"
import { useTranslation } from "react-i18next"
import { PendoEnum, replacePendoVariable } from "@pendo"

type MovimentationProps = {
  notificationConfig: NotificationsConfig
  flow: Flow
}

const getDefault = (override?: CardMovement): CardMovement => {
  return {
    active: false,
    notifyFollowers: true,
    notifyLeaders: true,
    stages: Object.keys(columnsStageInfo.hiring).map(
      (key) => columnsStageInfo.hiring[key].key,
    ),
    ...override,
  }
}

export const Movimentation = ({
  notificationConfig,
  flow,
}: MovimentationProps) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const utils = trpc.useContext()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.settings.notification.movimentation",
  })

  const { mutateAsync: updateConfig } =
    trpc.notification.updateNotificationConfig.useMutation()

  const handleActive = async () => {
    try {
      await updateConfig({
        notificationConfigId: notificationConfig._id,
        body: {
          cardMovement: {
            ...getDefault(notificationConfig.cardMovement),
            active: !notificationConfig.cardMovement?.active,
          },
        },
      })
      utils.notification.getNotificationConfig.invalidate()
    } catch (err: any) {
      dispatchToast({
        content: err.message,
        type: "error",
      })
    }
  }

  return (
    <AccordionActive
      dataOnboarding={replacePendoVariable(
        PendoEnum.MODEL_NOTIFICATION_ACCORDION_CONTENT,
        [
          {
            name: "$content",
            value: "movimentation",
          },
        ],
      )}
      title={t("title")}
      description={t("description")}
      actions={{
        active: {
          checked: notificationConfig.cardMovement?.active ?? false,
          handleClick: handleActive,
          dataOnboarding: replacePendoVariable(
            PendoEnum.MODEL_NOTIFICATION_ACCORDION_DISABLE_BUTTON,
            [
              {
                name: "$content",
                value: "movimentation",
              },
            ],
          ),
        },
        edit: {
          handleClick: () =>
            navigate(`/flows/settings/notification/movimentation/${flow._id}`),
        },
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
        <Typography
          variant="headline9"
          variantColor={theme.colors.neutral[30]}
          weight={700}
        >
          {t("subtitle")}
        </Typography>

        <RowValue
          title={t("selectStep")}
          value={
            notificationConfig.cardMovement?.stages.map(
              (stage) => hiringFlowStageMap[stage],
            ) ?? []
          }
        />
        <RowValue
          title={t("notifyLeader")}
          value={
            notificationConfig.cardMovement?.notifyLeaders
              ? t("values.yes")
              : t("values.no")
          }
        />
        <RowValue
          title={t("notifyFollowers")}
          value={
            notificationConfig.cardMovement?.notifyFollowers
              ? t("values.yes")
              : t("values.no")
          }
        />
      </div>
    </AccordionActive>
  )
}
