import { CSSProperties, HTMLAttributes } from "react"
import styled, { css, DefaultTheme } from "styled-components"

type BoxProps = {
  children?: React.ReactNode
  flexDirection?: CSSProperties["flexDirection"]
  justifyContent?: CSSProperties["justifyContent"]
  alignItems?: CSSProperties["alignItems"]
  flex?: CSSProperties["flex"]
  flexWrap?: CSSProperties["flexWrap"]
  gap?: keyof DefaultTheme["spacings"]
  $px?: keyof DefaultTheme["spacings"]
  $py?: keyof DefaultTheme["spacings"]
  $pt?: keyof DefaultTheme["spacings"]
  $pb?: keyof DefaultTheme["spacings"]
  $pl?: keyof DefaultTheme["spacings"]
  $pr?: keyof DefaultTheme["spacings"]
  $mt?: keyof DefaultTheme["spacings"]
  $mb?: keyof DefaultTheme["spacings"]
  $ml?: keyof DefaultTheme["spacings"]
  $mr?: keyof DefaultTheme["spacings"]
  $mx?: keyof DefaultTheme["spacings"]
  $my?: keyof DefaultTheme["spacings"]
  $width?: CSSProperties["width"]
  as?: keyof JSX.IntrinsicElements
  $borderRadius?: CSSProperties["borderRadius"]
  $border?: CSSProperties["border"]
  $cursor?: CSSProperties["cursor"]
} & HTMLAttributes<
  HTMLDivElement | HTMLSpanElement | HTMLLabelElement | HTMLButtonElement
>

export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
  ${({ theme, gap }) =>
    gap &&
    css`
      gap: ${theme.spacings[gap]};
    `}
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
  ${({ theme, $px }) =>
    $px &&
    css`
      padding-left: ${theme.spacings[$px]};
      padding-right: ${theme.spacings[$px]};
    `}
  ${({ theme, $py }) =>
    $py &&
    css`
      padding-top: ${theme.spacings[$py]};
      padding-bottom: ${theme.spacings[$py]};
    `}
  ${({ theme, $pt }) =>
    $pt &&
    css`
      padding-top: ${theme.spacings[$pt]};
    `}
  ${({ theme, $pb }) =>
    $pb &&
    css`
      padding-bottom: ${theme.spacings[$pb]};
    `}
  ${({ theme, $pl }) =>
    $pl &&
    css`
      padding-left: ${theme.spacings[$pl]};
    `}
  ${({ theme, $pr }) =>
    $pr &&
    css`
      padding-right: ${theme.spacings[$pr]};
    `}
  ${({ theme, $mt }) =>
    $mt &&
    css`
      margin-top: ${theme.spacings[$mt]};
    `}
  ${({ theme, $mb }) =>
    $mb &&
    css`
      margin-bottom: ${theme.spacings[$mb]};
    `}
  ${({ theme, $ml }) =>
    $ml &&
    css`
      margin-left: ${theme.spacings[$ml]};
    `}
  ${({ theme, $mr }) =>
    $mr &&
    css`
      margin-right: ${theme.spacings[$mr]};
    `}
  ${({ theme, $mx }) =>
    $mx &&
    css`
      margin-left: ${theme.spacings[$mx]};
      margin-right: ${theme.spacings[$mx]};
    `}
  ${({ theme, $my }) =>
    $my &&
    css`
      margin-top: ${theme.spacings[$my]};
      margin-bottom: ${theme.spacings[$my]};
    `}
  ${({ $borderRadius }) =>
    $borderRadius &&
    css`
      border-radius: ${$borderRadius}px;
    `}
  ${({ $border }) =>
    $border &&
    css`
      border: ${$border};
    `}
  ${({ $cursor }) =>
    $cursor &&
    css`
      cursor: ${$cursor || "auto"};
    `}
`

export const BoxCard = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.neutral20};
`
