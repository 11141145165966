export enum ToastMessageCodes {
  DeleteSuccessfull = "DELETE_SUCCESSFULL",
  FileUploadFailure = "FILE_UPLOAD_FAILURE",
  GenericContractFailure = "GENERIC_CONTRACT_FAILURE",
  GenericSignatureFailure = "GENERIC_SIGNATURE_FAILURE",
  InvalidContractModel = "INVALID_CONTRACT_MODEL",
  MissingCompanyEmployee = "MISSING_COMPANY_EMPLOYEE",
  MissingCompanyEmployeeEmail = "MISSING_COMPANY_EMPLOYEE_EMAIL",
  MissingInstitutionData = "MISSING_INSTITUTION_DATA",
  MissingInstitutionForm = "MISSING_INSTITUTION_FORM",
  MissingModelContractFields = "MISSING_MODEL_CONTRACT_FIELDS",
  MissingLimitDate = "MISSING_LIMIT_DATE",
  MissingModelOrTemplate = "MISSING_MODEL_OR_TEMPLATE",
  MissingWitnessData = "MISSING_WITNESS_DATA",
  MissingWitnessEmail = "MISSING_WITNESS_EMAIL",
  ResendSignatureFailure = "RESEND_SIGNATURE_FAILURE",
  ResetFailure = "RESET_FAILURE",
}
