import { useState } from "react"
import _ from "lodash-es"

import { SectionLoading } from "../SectionLoading"

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"

import {
  TitleCardContainer,
  TitleCardSubContainer,
  TitleCardRowContainer,
  TitleCardIconContainer,
  TitleCardTextContainer,
  IconRoundedContainer,
  StyledPOneLine,
  IconSelectedContainer,
  // StyledTag,
  StyledMenu,
  StyledDescriptionTwoLine,
} from "./styles"

import { Column, ColumnWithCards } from "../../../types"

import dispatchToast from "../../../utils/dispatchToast"

import { useMutation, useQueryClient } from "@tanstack/react-query"

import { COLUMN_LOCK } from "../../../api/mutations/column-lock"
import { COLUMN_VISIBILITY } from "../../../api/mutations/column-change-visibility"
import { request } from "../../../api/client"

interface TitleCardProps<CardType> {
  data: ColumnWithCards<CardType>
  handleUpdateData: (updatedData: Column) => void
  company: { [key: string]: any }
  isDragging: boolean
}

export const TitleCard = <CardType,>({
  data,
  handleUpdateData,
  company,
  isDragging,
}: TitleCardProps<CardType>) => {
  const queryClient = useQueryClient()

  const {
    _id,
    locked,
    hidden,
    description,
    name,
    cards = [],
    editable,
  } = data || {}

  const { flowId } = company
  const [orderMode, setOrderMode] = useState("")
  const findArchived = name === "Arquivado"

  const { mutate: setColumnLock } = useMutation(
    async ({ params }: { params: any }) => {
      await request(COLUMN_LOCK, { params })
    },
    {
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro no card.",
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries()
      },
    },
  )

  const { mutate: setColumnVisibility } = useMutation(
    async ({ params }: { params: any }) => {
      await request(COLUMN_VISIBILITY, { params })
    },
    {
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro no card.",
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries()
      },
    },
  )

  return (
    <TitleCardContainer
      color={findArchived ? "#F8F6F8" : ""}
      topColor={findArchived ? "#B9ACB4" : ""}
      isDragging={isDragging}
    >
      <TitleCardSubContainer>
        <TitleCardRowContainer style={{ paddingBottom: "10px" }}>
          <TitleCardTextContainer>
            <StyledPOneLine numberOfLines={1}>
              <Typography variant="headline8" style={{ fontWeight: 700 }}>
                {name}
              </Typography>
            </StyledPOneLine>
          </TitleCardTextContainer>
          <SectionLoading url={"setColumnVisibility"}>
            {({ loading }) => {
              return (
                <TitleCardIconContainer>
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                      userSelect: "none",
                      zIndex: "2",
                    }}
                  >
                    <StyledMenu
                      type={"select"}
                      options={[
                        {
                          onClick: async () => {
                            if (orderMode === "desc") setOrderMode("")
                            else setOrderMode("desc")

                            const orderedCards = _.orderBy(
                              cards,
                              ["updatedAt"],
                              ["desc"],
                            )

                            const updatedColumn = {
                              ...data,
                              cards: orderedCards,
                            }
                            handleUpdateData(updatedColumn)
                          },
                          children: (
                            <div>
                              <Typography
                                variant="body3"
                                style={{ fontWeight: 700 }}
                              >
                                {"Mais recentes"}
                              </Typography>
                            </div>
                          ),
                        },
                        {
                          onClick: async () => {
                            if (orderMode === "asc") setOrderMode("")
                            else setOrderMode("asc")

                            const orderedCards = _.orderBy(
                              cards,
                              ["updatedAt"],
                              ["asc"],
                            )

                            const updatedColumn = {
                              ...data,
                              cards: orderedCards,
                            }
                            handleUpdateData(updatedColumn)
                          },
                          children: (
                            <div>
                              <Typography
                                variant="body3"
                                style={{ fontWeight: 700 }}
                              >
                                {"Mais Antigos"}
                              </Typography>
                            </div>
                          ),
                        },
                      ]}
                    >
                      <IconSelectedContainer selected={!!orderMode}>
                        <Icons name="IconFilter" fill="transparent" />
                      </IconSelectedContainer>
                    </StyledMenu>
                  </div>

                  {editable ? (
                    <div
                      style={{
                        display: "inline-block",
                        position: "relative",
                        userSelect: "none",
                        zIndex: "2",
                      }}
                    >
                      <StyledMenu
                        type={"select"}
                        options={[
                          {
                            onClick: async () => {
                              await setColumnLock({
                                params: {
                                  flowId,
                                  flowColumnId: _id,
                                },
                              })
                            },
                            children: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  userSelect: "none",
                                }}
                              >
                                <Icons
                                  name={locked ? "IconLock" : "IconLockOpen"}
                                  fill={"transparent"}
                                  style={{
                                    marginRight: "8px",
                                  }}
                                />
                                <Typography
                                  variant="body3"
                                  style={{ fontWeight: 700 }}
                                >
                                  {locked ? "Destravar fase" : "Travar fase"}
                                </Typography>
                              </div>
                            ),
                          },
                          {
                            onClick: async () => {
                              await setColumnVisibility({
                                params: {
                                  flowId,
                                  flowColumnId: _id,
                                },
                              })
                            },
                            children: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  userSelect: "none",
                                }}
                              >
                                <Icons
                                  name={hidden ? "IconEye" : "IconEyeOff"}
                                  fill={"transparent"}
                                  style={{
                                    marginRight: "8px",
                                  }}
                                />
                                <Typography
                                  variant="body3"
                                  style={{ fontWeight: 700 }}
                                >
                                  {hidden ? "Mostrar fase" : "Ocultar fase"}
                                </Typography>
                              </div>
                            ),
                          },
                        ]}
                      >
                        <IconSelectedContainer selected={false}>
                          <Icons
                            name="IconDotsVertical"
                            style={{
                              stroke: "#53464F",
                              cursor: "pointer",
                            }}
                          />
                        </IconSelectedContainer>
                      </StyledMenu>
                    </div>
                  ) : (
                    <></>
                  )}
                </TitleCardIconContainer>
              )
            }}
          </SectionLoading>
        </TitleCardRowContainer>
        <TitleCardTextContainer>
          <IconRoundedContainer
            style={{
              marginRight: "7px",
            }}
          >
            <Icons
              name="IconUser"
              style={{
                stroke: "#9F939B",
                fill: "transparent",
                width: "16px",
                height: "16px",
              }}
            />
          </IconRoundedContainer>
          <Typography
            variant="body4"
            style={{ fontWeight: 600, color: "#83727D" }}
          >
            {cards?.length
              ? `${cards?.length} pessoa${
                  cards?.length > 1 ? "s" : ""
                } nessa etapa`
              : "Ninguém nessa etapa"}
          </Typography>
        </TitleCardTextContainer>

        {/* <div style={{ marginTop: "12px" }}>
          <StyledTag variant="disabled">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                userSelect: "none",
              }}
            >
              <Icons
                name={locked ? "IconLock" : "IconLockOpen"}
                fill={"transparent"}
                style={{
                  width: "12px",
                  height: "12px",
                  marginRight: "4px",
                }}
              />
              <Typography variant="caption" style={{ fontWeight: 600 }}>
                {locked ? "fase travada" : "fase destravada"}
              </Typography>
            </div>
          </StyledTag>
        </div> */}

        <StyledDescriptionTwoLine style={{ paddingTop: "12px" }}>
          <Typography variant="caption" style={{ fontWeight: 600 }}>
            {description}
          </Typography>
        </StyledDescriptionTwoLine>
      </TitleCardSubContainer>
    </TitleCardContainer>
  )
}
