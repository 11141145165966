import { trpc } from "@api/client"
import {
  getAccessToken,
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility"
import React, {
  createContext,
  Dispatch,
  ReactElement,
  useEffect,
  useReducer,
} from "react"
import { Employee } from "../types"
import { parseJwt } from "../utils"
import { EnumGlobalActions, globalReducer, IActions } from "./reducer"

interface AccessTokenBody {
  companyId: string
  economicGroupId: string
  employeeId: string
  role: string
}

export interface IGlobalContext {
  dispatch?: Dispatch<IActions<EnumGlobalActions>>
  employeeId?: string
  companyId?: string
  companyPermissions?: string[]
  economicGroupId?: string
  role?: string
  employee?: Employee
  modal?: { [key: string]: any }
}

const globalContext = createContext<IGlobalContext>({} as IGlobalContext)

const GlobalContext = (props: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(globalReducer, {})

  const { selectedCompany } = useSelectedCompany()
  const allPermissions = usePermissions()

  const permissions = React.useMemo(() => {
    const companyPermissions = allPermissions?.companies?.find(
      (company) => company.id === selectedCompany.id,
    )
    return companyPermissions?.permissions
  }, [allPermissions, selectedCompany.id])

  trpc.employee.getEmployee.useQuery(
    {
      employeeId: state.employeeId ?? "",
    },
    {
      onError: () => {
        console.error(
          "Ocorreu um erro na buscar dados do operador.",
          "INTERNAL_SERVER_ERROR",
        )
      },
      onSuccess: (employee: Employee) => {
        dispatch({
          type: EnumGlobalActions.SET_EMPLOYEE,
          payload: employee,
        })
      },
      enabled: !!state.employeeId,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    (async () => {
      const token = await getAccessToken()
      if (!token) return

      const decodedToken: AccessTokenBody = parseJwt(token)

      dispatch({
        type: EnumGlobalActions.SET_LOGGED_USER_DATA,
        payload: {
          ...decodedToken,
          companyId: selectedCompany.id,
        },
      })
    })()
  }, [selectedCompany])

  const value: IGlobalContext = {
    dispatch,
    companyPermissions: permissions,
    ...state,
  }

  return (
    <globalContext.Provider value={value}>
      {props.children}
    </globalContext.Provider>
  )
}

export { GlobalContext, globalContext }
