import { Navigate, useNavigate, useParams } from "react-router-dom"

import { Loader } from "@flash-tecnologia/hros-web-ui-v2"

import { BasicInfo, ContractContent } from "./Steps"

import { SpinnerContainer } from "./styles"

import { trpc } from "@api/client"
import dispatchToast from "../../utils/dispatchToast"
import { useMemo } from "react"

type StepType = typeof BasicInfo | typeof ContractContent | undefined

const CreateContractsTemplate = () => {
  const navigate = useNavigate()
  const { step, modelId, flowId } = useParams()

  const { data: flowData, isLoading: loadingFlow } =
    trpc.flow.getFlowById.useQuery(
      {
        flowId: flowId || "",
      },
      {
        queryKey: ["flow.getFlowById", { flowId: flowId || "" }],
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Erro ao buscar dados, tente novamente mais tarde.",
          })
          navigate(`/flows/hiring`)
        },
      },
    )

  const {
    isFetching: loadingModel,
    data: modelData,
  } = trpc.contract.getModelContractById.useQuery(
    {
      modelContractId: modelId || "",
    },
    {
      enabled: !!modelId,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar dados, tente novamente mais tarde.",
        })
        navigate(`/flows/settings/${flowId}/contracts`)
      },
    },
  )

  const modelContract = modelData || {}

  const Step: StepType = useMemo(() => {
    if (!step) return undefined
    const steps = {
      "basic-info": BasicInfo,
      "contract-content": ContractContent,
    }
    return steps[step]
  }, [step])

  if (!Step) return <Navigate to={`/flows/settings/${flowId}/contracts`} />

  if (loadingFlow || !flowData) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  return <Step data={modelContract} isFetching={loadingModel} flow={flowData} />
}

export default CreateContractsTemplate
