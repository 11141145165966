import { Draggable } from "react-beautiful-dnd"
import { CardComponent } from "./card"
import { Card, FlowCategory } from "../../../types"

import { Wrapper, ScrollContainer, DropZone } from "./styles"

const InnerCardList = <CardType extends Card>({
  columnIndex,
  data,
  column,
  category,
}: {
  columnIndex: number
  data: CardType[]
  category: FlowCategory
  column: { [key: string]: any }
}) => {
  return (
    <>
      {data?.map((card, cardIndex: number) => (
        <Draggable
          key={`column-${columnIndex}-card-${card._id}-${cardIndex}`}
          draggableId={card._id}
          index={cardIndex}
          isDragDisabled={false}
        >
          {(dragProvided: { [key: string]: any }) => (
            <CardComponent
              column={column}
              card={card}
              category={category}
              key={card._id}
              provided={dragProvided}
              cardIndex={cardIndex}
              columnIndex={columnIndex}
            />
          )}
        </Draggable>
      ))}
    </>
  )
}

const InnerList = <CardType extends Card>({
  columnIndex,
  dropProvided,
  data,
  category,
  column,
}: {
  columnIndex: number
  dropProvided: { [key: string]: any }
  category: FlowCategory
  column: { [key: string]: any }
  data: CardType[]
}) => {
  return (
    <DropZone ref={dropProvided.innerRef}>
      <InnerCardList
        columnIndex={columnIndex}
        data={data}
        column={column}
        category={category}
      />
      {dropProvided.placeholder}
    </DropZone>
  )
}

export const CardList = <CardType extends Card>({
  data,
  columnIndex,
  column,
  category,
  dropProvided,
  dropSnapshot,
}: {
  data: CardType[]
  columnIndex: number
  column: { [key: string]: any }
  category: FlowCategory
  dropProvided: { [key: string]: any }
  dropSnapshot: { [key: string]: any }
}) => {
  return (
    <Wrapper
      isDraggingOver={dropSnapshot.isDraggingOver}
      isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
      {...dropProvided.droppableProps}
    >
      <ScrollContainer>
        <InnerList
          columnIndex={columnIndex}
          data={data}
          column={column}
          category={category}
          dropProvided={dropProvided}
        />
      </ScrollContainer>
    </Wrapper>
  )
}
