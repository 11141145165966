import { ClientError, GraphQLClient } from "graphql-request"
import { QueryClient } from "@tanstack/react-query"
import { createTRPCReact, httpBatchLink } from "@trpc/react-query"
import { getAccessToken, getFromLS } from "@flash-tecnologia/hros-web-utility"
import type { AppRouter } from "bff"

export const trpc = createTRPCReact<AppRouter>()

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
})

export const createTrpcClient = (selectedCompany: string) =>
  trpc.createClient({
    links: [
      httpBatchLink({
        headers: async () => {
          const token = await getAccessToken()
          return {
            Authorization: token,
            "x-flash-auth": `Bearer ${token}`,
            "X-Selected-Company": selectedCompany,
          }
        },
        url: `${process.env.TRPC_BFF_URL}/trpc`,
      }),
    ],
  })

export const request = async <T = any>(
  query: any,
  variables?: any,
): Promise<T> => {
  const token = await getAccessToken()
  const { id: selectedCompanyId } = getFromLS("selectedCompany")

  const graphQLClient = new GraphQLClient(
    `${process.env.GRAPH_BFF_URL}/graphql/hiring`,
    {
      headers: {
        ...(token && { Authorization: token }),
        ...(selectedCompanyId && { "X-Selected-Company": selectedCompanyId }),
      },
      responseMiddleware: (response) => {
        if (response instanceof ClientError) {
          const exception: any = new Error(
            "Houve um problema ao se comunicar com o servidor.",
          )
          exception.name = "INTERNAL_SERVER_ERROR"

          const responseErrors = response.response.errors as any
          if (responseErrors && responseErrors.length > 0) {
            exception.message = responseErrors[0].message
            exception.name = responseErrors[0].errorCode
            exception.httpError = responseErrors[0].httpError
            exception.serviceError = responseErrors[0].serviceError
          }

          throw exception
        }
      },
    },
  )

  return graphQLClient.request<T>(query, variables)
}
