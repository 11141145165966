import styled from "styled-components"

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  margin: 40px 0;
`

export const HeaderContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`

export const StepContentContainer = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
`

export const StepSiderContentContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`

export const StepBodyContentContainer = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.l};
`

export const GenericCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  padding: ${({ theme }) => theme.spacings.m};
`
