import { SearchField } from "@flash-tecnologia/hros-web-ui-v2"
import React, { useCallback, useState } from "react"
import { CompanyEmployee } from "@customTypes/employee"
import { trpc } from "@api/client"

export type Option = {
  value: string
  label: string
}

export const SearchEmployeeField: React.FC<{
  value?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  callback: (data: CompanyEmployee) => void
  hasError?: boolean
  errorText?: string
}> = ({ callback, onBlur, value, errorText, hasError }) => {
  const [employeeOptions, setEmployeeOptions] = useState<Option[] | undefined>(
    undefined,
  )

  const {
    data: employees,
    mutateAsync: searchEmployeeByName,
    isLoading: isSearching,
  } = trpc.employee.getEmployeeByPartialName.useMutation({
    onError: (e) => {
      console.error("Error on search employee", e)
    },
    onSuccess: (data) => {
      const newOptions = data.map((employee) => ({
        value: employee.id,
        label: employee.name,
      }))
      setEmployeeOptions(newOptions)
    },
  })

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value?.length < 3) return
    await searchEmployeeByName({ searchParam: e.target.value })
  }

  const handleSearchChange = useCallback(
    (e, selectedOption: Option) => {
      if (!selectedOption) return
      const selectedEmployee = employees?.find(
        (employee) => employee.id === selectedOption.value,
      )
      if (selectedEmployee) callback(selectedEmployee as unknown as any)
    },
    [employees, callback],
  )

  return (
    <SearchField
      label={"Nome do colaborador"}
      onSearchChange={handleSearchChange}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      error={hasError}
      helperText={errorText}
      loading={isSearching}
      loadingText={"Buscando.."}
      options={employeeOptions}
    />
  )
}
