import { useEffect, useState } from "react"
import { Dialog } from "@mui/material"
import { useSearchParams, useNavigate } from "react-router-dom"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"

import { MainContainer, PageContents } from "./styles"

import Header from "./components/Header"
import ContractVisualizer from "./components/ContractVisualizer"

import { trpc } from "@api/client"

const PreviewContracts = () => {
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const [modelId, setModelId] = useState("")

  useEffect(() => {
    const modelId = searchParams.get("modelId")
    if (modelId) setModelId(modelId)

    const customModelId = searchParams.get("customModelId")

    if (!modelId && !customModelId) navigate("/flows/hiring")
  }, [searchParams])

  const {
    isLoading: loadingModel,
    data: modelData,
    refetch: fetchModelContractById,
  } = trpc.contract.getModelContractById.useQuery(
    {
      modelContractId: modelId,
    },
    {
      enabled: false,
    },
  )

  useEffect(() => {
    if (modelId) fetchModelContractById()
  }, [modelId])

  const modelContract = modelData || {}

  return (
    <Dialog open={true} keepMounted fullScreen transitionDuration={0}>
      <MainContainer>
        <Header />
        <PageContents>
          {loadingModel ? (
            <Skeleton variant="rounded" height={"100%"} width={"100%"} />
          ) : (
            <ContractVisualizer path={modelContract?.preview?.value} />
          )}
        </PageContents>
      </MainContainer>
    </Dialog>
  )
}

export default PreviewContracts
