import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import { useNavigate } from "react-router"
import { Board, RoundedButton } from "../../../components"

import { CreateOnePersonModal } from "./Modal"

import {
  Button,
  IconButton,
  Icons,
  Menu,
  PageContainer,
  PageHeader,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"

import { trpc } from "@api/client"
import { Box } from "@atoms/Box"
import { globalContext } from "../../../context"
import { ColumnWithCards, FlowWithCards, ResignationCard } from "../../../types"
import dispatchToast from "../../../utils/dispatchToast"
import {
  FlexColumn,
  MenuText,
  PageHeaderOptionsContainer,
  PageHeaderSubcontainer,
} from "./styles"

function getAllCards(flow: FlowWithCards<ResignationCard>): ResignationCard[] {
  return (flow.columns ?? [])?.reduce(
    (prev: ResignationCard[], curr: ColumnWithCards<ResignationCard>) => {
      if (curr.cards) return [...prev, ...curr.cards]
      return [...prev]
    },
    [],
  )
}

const ResignationPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { companyId } = useContext(globalContext)

  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 768px)")

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const { data: flowList, isInitialLoading: isListLoading } =
    trpc.flow.getFlowByCompanyId.useQuery(
      { companyId: companyId || "", category: "resignation" },
      {
        enabled: !!companyId,
        refetchOnWindowFocus: false,
      },
    )

  const resignationFlowId = flowList?.[0]._id

  const { data: getFlowById, refetch: refetchHiringFlow } =
    trpc.flow.getFlowById.useQuery(
      { flowId: resignationFlowId || "" },
      {
        enabled: !!resignationFlowId,
        refetchOnWindowFocus: false,
        queryKey: ["flow.getFlowById", { flowId: resignationFlowId || "" }],
      },
    )

  const flow = getFlowById as unknown as any

  const refreshBoard = () =>
    refetchHiringFlow().finally(() =>
      dispatchToast({
        type: "success",
        content: t("board.refreshBoardSuccess"),
      }),
    )

  const HeaderSkeleton = () => {
    return (
      <PageHeader>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Skeleton variant="rectangular" width="135px" height="38px" />
          <Skeleton
            variant="rectangular"
            width="276px"
            height="55px"
            style={{ marginLeft: "20px" }}
          />
        </div>
      </PageHeader>
    )
  }

  const ContentSkeleton = () => {
    return (
      <PageContainer>
        <div style={{ paddingTop: "10px" }} />
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          style={{
            borderRadius: "5px",
          }}
        />
      </PageContainer>
    )
  }

  const flowCards = flow ? getAllCards(flow) : []

  // const selectPeoplePageRouterPath = resignationFlowId
  //   ? generatePath(SELECT_PEOPLE_PAGE_URL, {
  //       flowId: resignationFlowId,
  //     })
  //   : ""

  // const flowCardsEmployeeIds = flowCards.map((card) => card.employeeId)

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isListLoading ? (
        <HeaderSkeleton />
      ) : (
        <PageHeader>
          <FlexColumn>
            <PageHeaderSubcontainer>
              <Typography variant="headline6">Desligamento</Typography>

              <Menu
                anchorOrigin={{
                  horizontal: "left",
                  vertical: "bottom",
                }}
                transformOrigin={{
                  horizontal: "left",
                  vertical: "top",
                }}
                options={[
                  {
                    onClick: () => setIsOpenModal(true),
                    children: (
                      <Box>
                        <Icons
                          name="IconUser"
                          style={{ marginRight: "8px", fill: "transparent" }}
                        />
                        <MenuText variant="body3">Apenas uma pessoa</MenuText>
                      </Box>
                    ),
                  },
                  // {
                  //   onClick: () =>
                  //     selectPeoplePageRouterPath &&
                  //     navigate(selectPeoplePageRouterPath, {
                  //       state: {
                  //         unselectableEmployeeIds: flowCardsEmployeeIds,
                  //       },
                  //     }),
                  //   children: (
                  //     <Box>
                  //       <Icons
                  //         name="IconUsers"
                  //         style={{ marginRight: "8px", fill: "transparent" }}
                  //       />
                  //       <MenuText variant="body3">Várias pessoas</MenuText>
                  //     </Box>
                  //   ),
                  // },
                ]}
                type="default"
              >
                <Button
                  size="medium"
                  variant="primary"
                  style={{
                    paddingLeft: isMobile ? "0px" : "40px",
                    alignSelf: isMobile ? "center" : "flex-start",
                  }}
                >
                  <Typography variant="body3" style={{ fontWeight: 700 }}>
                    Selecionar pessoa
                  </Typography>
                  <Icons name="IconPlus" style={{ fill: "#fff" }} />
                </Button>
              </Menu>
            </PageHeaderSubcontainer>

            <PageHeaderOptionsContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <RoundedButton
                  label={"Atualizar kanban"}
                  icon={"IconRefresh"}
                  onClick={() => refreshBoard()}
                  style={{ marginRight: 12 }}
                />

                <IconButton
                  size="small"
                  variant="line"
                  icon="IconSettings"
                  onClick={() =>
                    navigate(`/flows/settings/${flow?._id}/checklist`)
                  }
                />
              </div>
            </PageHeaderOptionsContainer>
          </FlexColumn>
        </PageHeader>
      )}

      {!flow ? (
        <ContentSkeleton />
      ) : (
        <React.Fragment>
          <PageContainer
            style={{
              marginTop: "10px",
              marginBottom: "20px",
              paddingBottom: "20px",
            }}
          >
            <Board flow={flow} />
          </PageContainer>

          {resignationFlowId ? (
            <CreateOnePersonModal
              flowId={resignationFlowId}
              isOpenModal={isOpenModal}
              setIsOpenModal={setIsOpenModal}
              cards={flowCards}
            />
          ) : (
            <></>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default ResignationPage
