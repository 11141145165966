export enum PendoEnum {
  KANBAN_COLUMN = "people-flow-kanban-column-$column",
  KANBAN_COLUMN_CARD = "people-flow-kanban-column-$column-card-$card",
  CONFIGURATION_BUTTON = "people-flow-configuration-button",
  KANBAN_SEARCH_FILTER_INPUT = "people-flow-search-filter-input",
  CONFIGURATION_TAB = "people-flow-configuration-tab-$tabName",
  MODEL_DOCUMENTS_TAB_CATEGORY = "people-flow-model-documents-tab-$category",
  MODEL_DOCUMENTS_BIND_SECTION = "people-flow-model-documents-bind-section",
  MODEL_DOCUMENTS_SECTION = "people-flow-model-documents-section-$number",
  MODEL_CHECKLIST_TAB_COLUMN = "people-flow-model-checklist-tab-$column",
  MODEL_CHECKLIST_CREATE_BUTTON = "people-flow-model-checklist-create-button",
  MODEL_NOTIFICATION_ACCORDION_CONTENT = "people-flow-notification-accordion-$content",
  MODEL_NOTIFICATION_ACCORDION_DISABLE_BUTTON = "people-flow-notification-accordion-disable-button-$content",
}

export function replacePendoVariable(
  enumerator: PendoEnum,
  variables: { name: string; value: string }[],
) {
  return variables.reduce(
    (acc: string, currValue: { name: string; value: string }) => {
      return acc.replace(currValue.name, currValue.value)
    },
    enumerator.toString(),
  )
}
