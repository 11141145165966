import styled from "styled-components"

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardContentInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs}
`