import {
  StyledAccordion,
  SubTitle,
  Title,
  StyledContainerIcon,
  AccordionTitle,
  AccordionDescription,
  Text,
  Divider,
} from "./styles"

import { Icons, dayjs } from "@flash-tecnologia/hros-web-ui-v2"

import { AccordionDetails, AccordionSummary } from "@mui/material"
import { UploadPreview } from "../../../components/common/DeprecatedUploadPreview"
import { ResignationCard } from "@customTypes/card"

export const ExamContent = ({
  resignationCard,
}: {
  resignationCard: ResignationCard
}) => {
  return (
    <StyledAccordion
      style={{
        marginBottom: "24px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <StyledContainerIcon>
            <Icons name="IconChevronDown" fill="transparent" />
          </StyledContainerIcon>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            marginRight: "20px",
          }}
        >
          <AccordionTitle variant="headline7">Exame demissional</AccordionTitle>
          <AccordionDescription variant="body4">
            Crie um checklist para organizar e acompanhar a evolução de cada
            etapa do exame demissional.
          </AccordionDescription>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "0px 44px 40px 44px" }}>
        <Title variant={"headline8"}>Agendamento do exame</Title>

        <div style={{ marginTop: "24px" }}>
          <SubTitle variant="body4" style={{ fontWeight: 700 }}>
            Data do exame
          </SubTitle>
          <Text
            variant="body3"
            hasValue={!!resignationCard.resignationExam?.date}
          >
            {resignationCard.resignationExam?.date
              ? dayjs(resignationCard.resignationExam.date).format("DD/MM/YYYY")
              : "Não preenchido"}
          </Text>
        </div>

        <Divider style={{ marginTop: "40px", marginBottom: "40px" }} />

        <Title variant={"headline8"}>Resultado do exame</Title>

        <div style={{ marginTop: "24px" }}>
          {resignationCard.resignationExam?.file ? (
            <UploadPreview
              uploadItem={resignationCard.resignationExam?.file}
              hasShareLink
            />
          ) : (
            <></>
          )}
        </div>

        <div style={{ marginTop: "24px" }}>
          <SubTitle variant="body4" style={{ fontWeight: 700 }}>
            Resultado
          </SubTitle>
          <Text
            variant="body3"
            hasValue={!!resignationCard.resignationExam?.validation?.status}
          >
            {resignationCard.resignationExam?.validation?.status === "approved"
              ? "Apto"
              : resignationCard.resignationExam?.validation?.status ===
                  "declined"
                ? "Inapto"
                : "Não preenchido"}
          </Text>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  )
}
