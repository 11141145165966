import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useFormik } from "formik"
import * as yup from "yup"

import { Flow, ResignationCard, S3File } from "../../../types"

import { SectionLoading, DeprecatedUpload } from "../../../components"
import dispatchToast from "../../../utils/dispatchToast"

import { UploadLoading } from "../../../components/common/UploadLoading"
import { UploadPreview } from "../../../components/common/DeprecatedUploadPreview"

import {
  createPreSignedUrl,
  uploadFileToS3,
  getS3Presigned,
} from "@flash-tecnologia/hros-web-utility"

import { RESIGNATION_NEXT_COLUMN } from "../../../api/mutations/resignation-card-next-column"

import { Container, FieldContainer, SubTitle, Title, Divider } from "./styles"

import {
  DatePicker,
  dayjs,
  SelectField,
  Button,
  Typography,
  Loader,
} from "@flash-tecnologia/hros-web-ui-v2"

import { request } from "../../../api/client"
import { RESIGNATION_UPDATE_CARD } from "../../../api/mutations/resignation-update-card"

export const StartProcessContent = ({
  card: initialCard,
  flow,
}: {
  card: ResignationCard
  flow: Flow
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { id } = useParams()
  const [card, setCard] = useState<ResignationCard>(initialCard)
  const [loadingFile, setLoadingFile] = useState<any>(false)

  const { mutate: updateCard } = useMutation(
    async ({ params }: { params: any }) => {
      await request(RESIGNATION_UPDATE_CARD, { params })
    },
  )

  const { mutate: sendToNextColumn } = useMutation(
    async () => {
      await request(RESIGNATION_NEXT_COLUMN, { flowCardId: id })
      return
    },
    {
      onSuccess: () => {
        dispatchToast({
          content: "Dados cadastrados com sucesso",
          type: "success",
        })
        navigate("/flows/resignation")
        queryClient.invalidateQueries()
      },
      onError: () => {
        dispatchToast({ content: "Ocorreu um erro", type: "error" })
      },
    },
  )

  const validationSchema = yup.object({
    resignation: yup.string().required("Favor selecionar o desligamento"),
    resignationReason: yup.string().when("resignationType", {
      is: (val) => val === "voluntary" || val === "involuntary",
      then: (schema) =>
        schema.required("Favor selecionar o motivo do desligamento"),
      otherwise: (schema) =>
        schema.required(
          "Favor selecionar o desligamento para habilitar este campo",
        ),
    }),
    resignationType: yup
      .string()
      .required("Favor selecionar o tipo de desligamento"),
    resignationRequestDate: yup
      .date()
      .typeError("Favor selecionar uma data válida")
      .required("Favor digitar a data do pedido de demissão"),
    lastWorkingDate: yup
      .date()
      .typeError("Favor selecionar uma data válida")
      .required("Favor informar o ultimo dia de trabalho do colaborador"),
  })

  const formik = useFormik({
    initialValues: {
      resignation: card.resignation || undefined,
      resignationReason: card.resignationReason || undefined,
      resignationType: card.resignationType || undefined,
      resignationRequestDate: card.resignationRequestDate || "",
      lastWorkingDate: card.lastWorkingDate || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sendToNextColumn()
    },
  })

  const resignationOptions =
    formik.values.resignation === "voluntary"
      ? [
          { label: "Remuneração", value: "Remuneração" },
          { label: "Benefícios", value: "Benefícios" },
          {
            label: "Identificação com a cultura",
            value: "Identificação com a cultura",
          },
          {
            label: "Falta de perspectiva de crescimento",
            value: "Falta de perspectiva de crescimento",
          },
          { label: "Mudança de área", value: "Mudança de área" },
          {
            label: "Falta de acompanhamento da gestão",
            value: "Falta de acompanhamento da gestão",
          },
          { label: "Mudança da gestão", value: "Mudança da gestão" },
          {
            label: "Falta de reconhecimento",
            value: "Falta de reconhecimento",
          },
          {
            label: "Atividades desalinhadas com a contratação",
            value: "Atividades desalinhadas com a contratação",
          },
          { label: "Mudança de carreira", value: "Mudança de carreira" },
          { label: "Outro", value: "Outro" },
        ]
      : [
          { label: "Comportamento", value: "Comportamento" },
          { label: "Performance", value: "Performance" },
          {
            label: "Ação Disciplinar (Justa Causa)",
            value: "Ação Disciplinar (Justa Causa)",
          },
          {
            label: "Falta de identificação com a cultura",
            value: "Falta de identificação com a cultura",
          },
          { label: "Falta de adaptação", value: "Falta de adaptação" },
          {
            label: "Mudança de Estratégia/Estrutura",
            value: "Mudança de Estratégia/Estrutura",
          },
          {
            label: "Efetivação (em caso de estágio)",
            value: "Efetivação (em caso de estágio)",
          },
        ]

  const updateFieldGlobally = async ({
    value,
    field,
  }: {
    value: any
    field: string
  }) => {
    await updateCard({
      params: {
        flowCardId: card._id,
        [field]: value,
      },
    })
  }

  const handleFile = async ({ name, file }) => {
    setLoadingFile(true)

    const { employeeId, companyId, _id } = card

    const url = `${companyId}/${employeeId}/${_id}/resignation/letter/${name}`

    if (!companyId || !employeeId || !name) return

    const { key = "", signedUrl = "" } = await createPreSignedUrl({
      filename: url,
      module: "employee-pii",
      contentType: file.type,
    })

    await uploadFileToS3({ file, url: signedUrl })

    const params = { key, path: url, type: "internal" }

    await updateFieldGlobally({
      value: params,
      field: "resignationLetter",
    })

    const { url: presignedUrl } = await getS3Presigned({
      filename: url,
      module: "employee-pii",
    })

    const resignationLetter: S3File = {
      key,
      path: url,
      type: "internal",
      value: presignedUrl.toString(),
    }

    setCard((prevCard) => ({
      ...prevCard,
      resignationLetter,
    }))
  }

  return (
    <Container>
      <>
        <div style={{ marginBottom: "24px" }}>
          <Title variant={"headline8"}>Dados do desligamento</Title>
          <SubTitle variant={"caption"}>
            Preencha os campos abaixo de acordo com o tipo de desligamento.
          </SubTitle>
        </div>

        <FieldContainer>
          <SelectField
            id={"resignation"}
            name={"resignation"}
            label={"Desligamento"}
            value={formik.values.resignation}
            options={[
              { label: "Voluntário", value: "voluntary" },
              { label: "Involuntário", value: "involuntary" },
            ]}
            onSelectChange={async (_, { value }) => {
              formik.handleChange({
                target: { id: "resignation", value },
              })

              await updateFieldGlobally({
                field: "resignation",
                value: value || null,
              })
            }}
            error={
              formik.touched.resignation && Boolean(formik.errors.resignation)
            }
            helperText={formik.touched.resignation && formik.errors.resignation}
            fullWidth
          />
        </FieldContainer>

        <FieldContainer>
          <SelectField
            id={"resignationReason"}
            name={"resignationReason"}
            label={"Motivo do desligamento"}
            value={formik.values.resignationReason}
            options={resignationOptions}
            disabled={!formik.values.resignation}
            onSelectChange={async (_, { value }) => {
              formik.handleChange({
                target: { id: "resignationReason", value },
              })

              await updateFieldGlobally({
                field: "resignationReason",
                value: value || null,
              })
            }}
            error={
              formik.touched.resignationReason &&
              Boolean(formik.errors.resignationReason)
            }
            helperText={
              formik.touched.resignationReason &&
              formik.errors.resignationReason
            }
            fullWidth
          />
        </FieldContainer>

        <FieldContainer>
          <SelectField
            id={"resignationType"}
            name={"resignationType"}
            label={"Tipo de desligamento"}
            value={formik.values.resignationType}
            options={[
              {
                label: "Demissão sem justa causa",
                value: "Demissão sem justa causa",
              },
              {
                label: "Demissão por justa causa",
                value: "Demissão por justa causa",
              },
              {
                label: "Demissão consensual",
                value: "Demissão consensual",
              },
              {
                label: "Pedido de demissão pelo colaborador",
                value: "Pedido de demissão pelo colaborador",
              },
            ]}
            autoComplete={formik.values.resignationType}
            onSelectChange={async (_, { value }) => {
              formik.handleChange({
                target: { id: "resignationType", value },
              })
              await updateFieldGlobally({
                field: "resignationType",
                value: value || null,
              })
            }}
            error={
              formik.touched.resignationType &&
              Boolean(formik.errors.resignationType)
            }
            helperText={
              formik.touched.resignationType && formik.errors.resignationType
            }
            fullWidth
          />
        </FieldContainer>

        <FieldContainer>
          {card?.resignationLetter?.value ? (
            <>
              <UploadPreview
                uploadItem={card?.resignationLetter}
                onRemove={async () => {
                  await updateFieldGlobally({
                    field: "resignationLetter",
                    value: null,
                  })
                  setCard((prevCard) => ({
                    ...prevCard,
                    resignationLetter: undefined,
                  }))
                }}
              />
            </>
          ) : (
            <>
              <DeprecatedUpload
                label={"Carta de desligamento"}
                maxSize={5242880}
                accept={["jpg", "png", "pdf", "txt", "doc"]}
                onFileSizeError={() => {
                  dispatchToast({
                    content:
                      "Arquivo maior que 5mb. Por favor, faça upload de arquivo menor que 5mb",
                    type: "warning",
                  })
                }}
                onChange={({ name, file }) => {
                  handleFile({ name, file })
                }}
                customPreview={() => (loadingFile ? <UploadLoading /> : <></>)}
              />
            </>
          )}
        </FieldContainer>

        <FieldContainer>
          <DatePicker
            id={"resignationRequestDate"}
            name={"resignationRequestDate"}
            label={"Data do pedido de demissão"}
            placeholder={"Digite a data do pedido de demissão"}
            value={formik.values.resignationRequestDate}
            onDateChange={async (value) => {
              const date = value && dayjs(value).isValid() ? dayjs(value) : null
              const parsedValue = dayjs(value).isValid()
                ? date?.toISOString()
                : null

              formik.handleChange({
                target: {
                  id: "resignationRequestDate",
                  value: parsedValue,
                },
              })

              if (!date) return

              await updateFieldGlobally({
                field: "resignationRequestDate",
                value: parsedValue,
              })
            }}
            error={
              formik?.touched?.resignationRequestDate &&
              Boolean(formik?.errors?.resignationRequestDate)
            }
            helperText={
              formik?.touched?.resignationRequestDate &&
              formik?.errors?.resignationRequestDate
            }
            fullWidth
          />
        </FieldContainer>

        <FieldContainer>
          <DatePicker
            id={"lastWorkingDate"}
            name={"lastWorkingDate"}
            label={"Último dia de trabalho efetivo"}
            value={formik.values.lastWorkingDate}
            onDateChange={async (value) => {
              const date = value && dayjs(value).isValid() ? dayjs(value) : null
              const parsedValue = dayjs(value).isValid()
                ? date?.toISOString()
                : null

              formik.handleChange({
                target: { id: "lastWorkingDate", value: parsedValue },
              })

              if (!date) return

              await updateFieldGlobally({
                field: "lastWorkingDate",
                value: parsedValue,
              })
            }}
            error={
              formik?.touched?.lastWorkingDate &&
              Boolean(formik?.errors?.lastWorkingDate)
            }
            helperText={
              formik?.touched?.lastWorkingDate &&
              formik?.errors?.lastWorkingDate
                ? String(formik.errors.lastWorkingDate)
                : ""
            }
            fullWidth
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <Typography
              variant="body2"
              style={{
                color: "#FF0000",
                marginLeft: 5,
              }}
            >
              *
            </Typography>

            <Typography
              variant="body3"
              style={{
                color: "#6B5B66",
                marginTop: 5,
                marginLeft: 5,
              }}
            >
              O colaborador será inativado na data informada acima.
            </Typography>
          </div>
        </FieldContainer>

        <Divider style={{ marginTop: "40px", marginBottom: "49px" }} />
        <SectionLoading url={"resignationNextColumn"}>
          {({ loading }) => (
            <Button
              type={"submit"}
              size={"medium"}
              variant="primary"
              style={{ alignSelf: "center", width: "100%" }}
              disabled={loading}
              onClick={() => formik.handleSubmit()}
            >
              {loading ? (
                <Loader size="extraSmall" variant="secondary" />
              ) : (
                <Typography variant="body3" style={{ fontWeight: 700 }}>
                  Cadastrar
                </Typography>
              )}
            </Button>
          )}
        </SectionLoading>
      </>
    </Container>
  )
}
