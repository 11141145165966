import {
  Candidate,
  ConfirmationData,
  HiringCard,
  HistoryData,
  ResignationCard,
} from "../../../../types"
import {
  countDependentDocuments,
  countDocumentWithValue,
  formatCurrency,
  parseHiringType,
  renderDate,
} from "../../../../utils"
import {
  CnpjMask,
  ContractDurationMask,
  CpfMask,
  PhoneMask,
  WorkloadMask,
  WorkshipMask,
} from "../../../../utils/masks"

export const resignationData = (
  card: ResignationCard,
  contract,
): HistoryData[] => {
  const { employee } = card
  const employeePhone = employee?.phoneNumber

  return [
    {
      title: "Em espera",
      description: "",
      items: [
        {
          title: "Dados pessoais",
          fields: [
            { title: "Nome completo", type: "text", text: employee?.name },
            {
              title: "CPF",
              type: "text",
              text: employee?.documentNumber
                ? CpfMask(employee?.documentNumber)
                : "Não preenchido",
            },
            {
              title: "Email",
              type: "text",
              text: employee?.email || "Não preenchido",
            },
            {
              title: "Celular",
              type: "text",
              text: employeePhone ? PhoneMask(employeePhone) : "Não preenchido",
            },
          ],
        },
        {
          title: "Dados complementares",
          fields: [
            { title: "Nome da mãe", type: "text", text: employee?.mothersName },
            {
              title: "Data de Nascimento",
              type: "text",
              text: renderDate(employee?.birthDate),
            },
            {
              title: "Endereço",
              type: "text",
              text: employee?.address
                ? `${employee?.address?.street || ""}, ${
                    employee?.address?.number || ""
                  } - ${employee?.address?.district || ""}, ${
                    employee?.address?.city || ""
                  } -  ${employee?.address?.state || ""}, ${
                    employee?.address?.zipCode || ""
                  }`
                : "",
            },
          ],
        },
      ],
      columnId: "NorGGe2Kt_JCq65zz4Pt6",
      order: 0,
    },
    {
      title: "Processo iniciado",
      description: "",
      items: [
        {
          title: "Dados do desligamento",
          fields: [
            {
              title: "Tipo de desligamento",
              type: "text",
              text: card.resignationType,
            },
            {
              title: "Carta de desligamento",
              type: "file",
              file: card.resignationLetter
                ? [{ ...card.resignationLetter }]
                : undefined,
            },
            {
              title: "Motivo do desligamento",
              type: "text",
              text: card.resignationReason,
            },
            {
              title: "Irá cumprir aviso prévio",
              type: "text",
              text:
                card.serveNoticePeriod === null
                  ? undefined
                  : card.serveNoticePeriod
                    ? "Sim"
                    : "Não",
            },
            {
              title: "Data de desligamento (último dia de trabalho efetivo)",
              type: "text",
              text: renderDate(card.lastWorkingDate),
            },
          ],
        },
      ],
      columnId: "Xc8aMfjDVTZjR32e9KlTl",
      order: 1,
    },
    {
      title: "Entrevista de desligamento",
      description: "",
      items: [
        {
          title: "Entrevista de desligamento",
          fields: [
            {
              title: "Entrevista de desligamento",
              type: "file",
              file: card?.resignationInterviewFile
                ? [{ ...card?.resignationInterviewFile }]
                : undefined,
            },
            {
              title: "Observações",
              type: "text",
              text: card?.resignationInterviewObservation,
            },
          ],
        },
      ],
      columnId: "f1PAn4f42SGP1YbiDnUJp",
      order: 2,
    },
    {
      title: "Exame demissional",
      description: "",
      items: [
        {
          title: "Agendamento do exame",
          fields: [
            {
              title: "Data do exame",
              type: "text",
              text: renderDate(card.resignationExam?.date),
            },
          ],
        },
        {
          title: "Resultado do exame",
          fields: [
            {
              title: "Entrevista de desligamento",
              type: "file",
              file: card.resignationExam?.file ? [{ ...card.resignationExam.file }] : undefined,
            },
            {
              title: "Resultado",
              type: "text",
              text:
                card?.resignationExam?.validation?.status === "approved"
                  ? "Apto"
                  : card?.resignationExam?.validation?.status === "declined"
                    ? "Inapto"
                    : "",
            },
          ],
        },
      ],
      columnId: "9UCVbL4llwtcBJ3vqm1eo",
      order: 3,
    },
    {
      title: "Calculo da recisão",
      description: "",
      items: [
        {
          title: "Checklist de tarefas",
          fields: [
            {
              title: "",
              type: "checklist",
              checklist: card?.checklists?.find(
                (checklist) => checklist.columnId === "rCUyF82cXlfUIrdHDpMsX",
              ),
            },
          ],
        },
      ],
      columnId: "rCUyF82cXlfUIrdHDpMsX",
      order: 4,
    },
    {
      title: "Assinatura de rescisão",
      description: "",
      items: [
        {
          title: "Documentos de rescisão",
          fields: [
            {
              title: "Arquivos",
              type: "file",
              file: contract?.lastContractOutput || "",
            },
          ],
        },
        {
          title: "Destinatários",
          fields: [
            {
              title: "Pessoa",
              type: "signature",
              signatureInfo:
                contract?.signature?.find((f) => f.type === "person") || {},
            },
            {
              title: "Empresa",
              type: "signature",
              signatureInfo:
                contract?.signature?.find((f) => f.type === "company") || {},
            },
          ],
        },
      ],
      columnId: "4TJMj1Dqwk_F_RaAfPmw1",
      order: 5,
    },
    {
      title: "Atualização de documentação",
      description: "",
      items: [
        {
          title: "Checklist de tarefas",
          fields: [
            {
              title: "",
              type: "checklist",
              checklist: card?.checklists?.find(
                (checklist) => checklist.columnId === "uz6g7xOUBzoyRR35cgorn",
              ),
            },
          ],
        },
      ],
      columnId: "uz6g7xOUBzoyRR35cgorn",
      order: 6,
    },
  ]
}

export const cltHiringData = (
  candidate: Candidate,
  card: HiringCard,
  contract,
  confirmationData?: ConfirmationData,
): HistoryData[] => {
  const candidateSignature =
    contract?.signature?.find((s) => s.type === "candidate") || null
  const companySignature =
    contract?.signature?.find((s) => s.type === "company") || null

  return [
    {
      title: "Pessoa",
      description: "",
      items: [
        {
          title: "Informações da candidatura",
          fields: [
            {
              title: "CV",
              type: "file",
              file: card.metadata?.candidate?.cv,
            },
            {
              title: "Linkedin",
              type: card.metadata?.candidate?.linkedin ? "link" : "text",
              text: card.metadata?.candidate?.linkedin
                ? "Visualizar Linkedin"
                : "Não preenchido",
              link: card.metadata?.candidate?.linkedin,
            },
            {
              title: "Link da vaga",
              type: card.metadata?.candidate?.vacancyLink ? "link" : "text",
              text: card.metadata?.candidate?.vacancyLink
                ? "Visualizar link da vaga"
                : "Não preenchido",
              link: card.metadata?.candidate?.vacancyLink,
            },
          ],
        },
      ],
      columnId: "tRJpyyDaWvTtwQEWqB8BO",
      order: 0,
    },
    {
      title: "Carta proposta",
      description: "",
      items: [
        {
          title: "Conteúdo da proposta",
          fields: [
            {
              title: "Cargo",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.role?.name || "Não preenchido",
            },
            {
              title: "Departamento",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.department?.name ||
                "Não preenchido",
            },
            {
              title: "Grupo",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.group?.name || "Não preenchido",
            },
            {
              title: "Tipo de vínculo",
              type: "text",
              text:
                parseHiringType(
                  candidate?.proposalLetterInfo?.hiringType || "",
                ) || "Não preenchido",
            },
            {
              title: "Atribuições e responsabilidades",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.assignments || "Não preenchido",
            },
            {
              title: "Remuneração fixa",
              type: "text",
              text: candidate?.proposalLetterInfo?.salary
                ? formatCurrency(candidate?.proposalLetterInfo?.salary)
                : "Não preenchido",
            },
            {
              title: "Remuneração Variável",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.variableSalary ||
                "Não preenchido",
            },
            {
              title: "Data prevista de admissão",
              type: "text",
              text: candidate?.proposalLetterInfo?.hiringDate
                ? renderDate(candidate?.proposalLetterInfo?.hiringDate)
                : "Não preenchido",
            },
          ],
        },
        {
          title: "Arquivos",
          fields: [
            {
              title: "Sobre a empresa",
              type: "file",
              file: candidate.proposalLetterInfo?.aboutCompany,
            },
            {
              title: "Benefícios",
              type: "file",
              file: candidate.proposalLetterInfo?.benefits,
            },
          ],
        },
      ],
      columnId: "iBQ6kyM0cmPVDoDZv9BI7",
      order: 1,
    },
    {
      title: "Envio de documentos",
      description: "",
      items: [
        {
          title: "Documentos da pessoa",
          fields: [
            {
              title: "Documentos coletados",
              type:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? "counter"
                  : "text",
              text:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? countDocumentWithValue(confirmationData?.documentFields)
                  : "Não preenchido",
            },
          ],
        },
        {
          title: "Documentos de dependentes",
          fields: [
            {
              title: "Documentos coletados",
              type: confirmationData?.dependents?.length ? "counter" : "text",
              text: confirmationData?.dependents?.length
                ? countDependentDocuments(confirmationData?.dependents)
                : "Não preenchido",
            },
          ],
        },
      ],
      columnId: "79VS8G8CfV53uQmJYQ_dR",
      order: 2,
    },
    {
      title: "Validação de documentos",
      description: "",
      items: [
        {
          title: "Documentos da pessoa",
          fields: [
            {
              title: "Documentos coletados",
              type:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? "counter"
                  : "text",
              text:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? countDocumentWithValue(confirmationData?.documentFields)
                  : "Não preenchido",
            },
          ],
        },
        {
          title: "Documentos de dependentes",
          fields: [
            {
              title: "Documentos coletados",
              type: confirmationData?.dependents?.length ? "counter" : "text",
              text: confirmationData?.dependents?.length
                ? countDependentDocuments(confirmationData?.dependents)
                : "Não preenchido",
            },
          ],
        },
      ],
      columnId: "QK-1JLmCDHdOV71keXtO4",
      order: 3,
    },
    {
      title: "Exame admissional",
      description: "",
      items: [
        {
          title: "Checklist de tarefas",
          fields: [
            {
              title: "",
              type: "checklist",
              checklist: card.checklists?.find(
                (checklist) => checklist.columnId === "2BuJf8_cHjLYdzkw3mfqr",
              ),
            },
          ],
        },
        {
          title: "Agendamento do exame",
          fields: [
            {
              title: "Data do exame",
              type: "text",
              text: card.admissionalExam?.date
                ? renderDate(card.admissionalExam?.date)
                : "Não preenchido",
            },
          ],
        },
        {
          title: "Resultado do exame",
          fields: [
            {
              title: "ASO",
              type: "file",
              file: card.admissionalExam?.file
                ? [card.admissionalExam?.file]
                : undefined,
            },
            {
              title: "Resultado",
              type: "text",
              text: !card.admissionalExam
                ? "Não preenchido"
                : card.admissionalExam.status === "approved"
                  ? "Apta"
                  : card.admissionalExam.status === "declined"
                    ? "Inapta"
                    : "Aguardando processo",
            },
          ],
        },
      ],
      columnId: "2BuJf8_cHjLYdzkw3mfqr",
      order: 4,
    },
    {
      title: "Assinatura de contrato",
      description: "",
      items: [
        {
          title: "Status",
          fields: [
            {
              title: candidateSignature ? candidateSignature.name : "Pessoa",
              type: candidateSignature ? "status" : "text",
              text: "Não preenchido",
              signature: candidateSignature,
            },
            {
              title: companySignature ? companySignature.name : "Empresa",
              type: companySignature ? "status" : "text",
              text: "Não preenchido",
              signature: companySignature,
            },
          ],
        },
        {
          title: "Documentos",
          fields: [
            {
              title: "Documentos",
              type: "file",
              file: contract?.template || [],
            },
          ],
        },
      ],
      columnId: "eIFKwtQ3YwUScPG1-l8As",
      order: 5,
    },
  ]
}

export const pjHiringData = (
  candidate: Candidate,
  card: HiringCard,
  contract,
  confirmationData?: ConfirmationData,
): HistoryData[] => {
  const candidateSignature =
    contract?.signature?.find((s) => s.type === "person") || null
  const companySignature =
    contract?.signature?.find((s) => s.type === "company") || null

  return [
    {
      title: "Prestador de serviço",
      description: "",
      items: [
        {
          title: "Dados pessoais e contratuais",
          fields: [
            {
              title: "Nome completo",
              type: "text",
              text: candidate?.name || "Não preenchido",
            },
            {
              title: "CPF",
              type: "text",
              text: candidate?.documentNumber
                ? CpfMask(candidate?.documentNumber)
                : "Não preenchido",
            },
            {
              title: "Email",
              type: "text",
              text: candidate?.email || "Não preenchido",
            },
            {
              title: "Celular",
              type: "text",
              text: candidate.phone
                ? PhoneMask(candidate.phone)
                : "Não preenchido",
            },
            {
              title: "CNPJ",
              type: "text",
              text: candidate?.cnpj
                ? CnpjMask(candidate?.cnpj)
                : "Não preenchido",
            },
            {
              title: "Razão social",
              type: "text",
              text: candidate?.legalName || "Não preenchido",
            },
          ],
        },
        {
          title: "Informações da candidatura",
          fields: [
            {
              title: "CV",
              type: "file",
              file: card.metadata?.candidate?.cv,
            },
            {
              title: "Linkedin",
              type: card.metadata?.candidate?.linkedin ? "link" : "text",
              text: card.metadata?.candidate?.linkedin
                ? "Visualizar Linkedin"
                : "Não preenchido",
              link: card.metadata?.candidate?.linkedin,
            },
            {
              title: "Link da vaga",
              type: card.metadata?.candidate?.vacancyLink ? "link" : "text",
              text: card.metadata?.candidate?.vacancyLink
                ? "Visualizar link da vaga"
                : "Não preenchido",
              link: card.metadata?.candidate?.vacancyLink,
            },
          ],
        },
      ],
      columnId: "SGHourMe5ZuWbKcNTufdp",
      order: 0,
    },
    {
      title: "Carta proposta",
      description: "",
      items: [
        {
          title: "Conteúdo da proposta",
          fields: [
            {
              title: "Cargo",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.role?.name || "Não preenchido",
            },
            {
              title: "Departamento",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.department?.name ||
                "Não preenchido",
            },
            {
              title: "Grupo",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.group?.name || "Não preenchido",
            },
            {
              title: "Tipo de vínculo",
              type: "text",
              text:
                parseHiringType(
                  candidate?.proposalLetterInfo?.hiringType || "pj",
                ) || "Não preenchido",
            },
            {
              title: "Atribuições e responsabilidades",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.assignments || "Não preenchido",
            },
            {
              title: "Remuneração",
              type: "text",
              text: candidate?.proposalLetterInfo?.salary
                ? formatCurrency(candidate?.proposalLetterInfo?.salary)
                : "Não preenchido",
            },
            {
              title: "Remuneração Variável",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.variableSalary ||
                "Não preenchido",
            },
            {
              title: "Data prevista de início",
              type: "text",
              text: candidate?.proposalLetterInfo?.hiringDate
                ? renderDate(candidate?.proposalLetterInfo?.hiringDate)
                : "Não preenchido",
            },
          ],
        },
        {
          title: "Anexar documentos",
          fields: [
            {
              title: "Sobre a empresa",
              type: "file",
              file: candidate.proposalLetterInfo?.aboutCompany,
            },
            {
              title: "Benefícios",
              type: "file",
              file: candidate.proposalLetterInfo?.benefits,
            },
          ],
        },
      ],
      columnId: "mL60LCZrDoobUCEs4Uwbz",
      order: 1,
    },
    {
      title: "Envio de documentos",
      description: "",
      items: [
        {
          title: "Documentos da pessoa",
          fields: [
            {
              title: "Documentos coletados",
              type:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? "counter"
                  : "text",
              text:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? countDocumentWithValue(confirmationData?.documentFields)
                  : "Não preenchido",
            },
          ],
        },
        {
          title: "Documentos de dependentes",
          fields: [
            {
              title: "Documentos coletados",
              type: confirmationData?.dependents?.length ? "counter" : "text",
              text: confirmationData?.dependents?.length
                ? countDependentDocuments(confirmationData?.dependents)
                : "Não preenchido",
            },
          ],
        },
      ],
      columnId: "tWrA2o2pD_jYj_5jgIk8f",
      order: 2,
    },
    {
      title: "Validação de documentos",
      description: "",
      items: [
        {
          title: "Documentos da pessoa",
          fields: [
            {
              title: "Documentos coletados",
              type:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? "counter"
                  : "text",
              text:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? countDocumentWithValue(confirmationData?.documentFields)
                  : "Não preenchido",
            },
          ],
        },
        {
          title: "Documentos de dependentes",
          fields: [
            {
              title: "Documentos coletados",
              type: confirmationData?.dependents?.length ? "counter" : "text",
              text: confirmationData?.dependents?.length
                ? countDependentDocuments(confirmationData?.dependents)
                : "Não preenchido",
            },
          ],
        },
      ],
      columnId: "qpG65fUAKteLAwGZUO-gv",
      order: 3,
    },
    {
      title: "Assinatura de contrato",
      description: "",
      items: [
        {
          title: "Status",
          fields: [
            {
              title: candidateSignature ? candidateSignature.name : "Pessoa",
              type: candidateSignature ? "status" : "text",
              text: "Não preenchido",
              signature: candidateSignature,
            },
            {
              title: companySignature ? companySignature.name : "Empresa",
              type: companySignature ? "status" : "text",
              text: "Não preenchido",
              signature: companySignature,
            },
          ],
        },
        {
          title: "Documentos",
          fields: [
            {
              title: "Documentos",
              type: "file",
              file: contract?.template || [],
            },
          ],
        },
        {
          title: "Duração do contrato",
          fields: [
            {
              title: "Prazo de duração do contrato",
              type: "text",
              text:
                ContractDurationMask(contract?.contractDuration) ||
                "Não preenchido",
            },
          ],
        },
      ],
      columnId: "FDcChp56n_l-AM7wNLDeL",
      order: 4,
    },
  ]
}

export const internshipHiringData = (
  candidate: Candidate,
  card: HiringCard,
  contract,
  confirmationData?: ConfirmationData,
): HistoryData[] => {
  const candidateSignature =
    contract?.signature?.find((s) => s.type === "candidate") || null
  const companySignature =
    contract?.signature?.find((s) => s.type === "company") || null

  return [
    {
      title: "Estagiário",
      description: "",
      items: [
        {
          title: "Dados pessoais",
          fields: [
            {
              title: "Nome completo",
              type: "text",
              text: candidate?.name || "Não preenchido",
            },
            {
              title: "CPF",
              type: "text",
              text: candidate?.documentNumber
                ? CpfMask(candidate?.documentNumber)
                : "Não preenchido",
            },
            {
              title: "Email",
              type: "text",
              text: candidate?.email,
            },
            {
              title: "Celular",
              type: "text",
              text: candidate.phone
                ? PhoneMask(candidate.phone)
                : "Não preenchido",
            },
          ],
        },
        {
          title: "Instituição de ensino",
          fields: [
            {
              title: "Instituição de ensino",
              type: "text",
              text: candidate?.internship?.institutionName || "Não preenchido",
            },
            {
              title: "Curso",
              type: "text",
              text: candidate?.internship?.courseName || "Não preenchido",
            },
            {
              title: "Data prevista de término",
              type: "text",
              text: candidate?.internship?.endDate
                ? renderDate(candidate?.internship?.endDate)
                : "Não preenchido",
            },
          ],
        },
        {
          title: "Informações da candidatura",
          fields: [
            {
              title: "CV",
              type: "file",
              file: card.metadata?.candidate?.cv,
            },
            {
              title: "Linkedin",
              type: card.metadata?.candidate?.linkedin ? "link" : "text",
              text: card.metadata?.candidate?.linkedin
                ? "Visualizar Linkedin"
                : "Não preenchido",
              link: card.metadata?.candidate?.linkedin,
            },
            {
              title: "Link da vaga",
              type: card.metadata?.candidate?.vacancyLink ? "link" : "text",
              text: card.metadata?.candidate?.vacancyLink
                ? "Visualizar link da vaga"
                : "Não preenchido",
              link: card.metadata?.candidate?.vacancyLink,
            },
          ],
        },
      ],
      columnId: "gRVF22nRm8Vv_NvXkczxR",
      order: 0,
    },
    {
      title: "Carta proposta",
      description: "",
      items: [
        {
          title: "Conteúdo da proposta",
          fields: [
            {
              title: "Cargo",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.role?.name || "Não preenchido",
            },
            {
              title: "Departamento",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.department?.name ||
                "Não preenchido",
            },
            {
              title: "Grupo",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.group?.name || "Não preenchido",
            },
            {
              title: "Tipo de vínculo",
              type: "text",
              text:
                parseHiringType(
                  candidate?.proposalLetterInfo?.hiringType || "estágio",
                ) || "Não preenchido",
            },
            {
              title: "Atribuições e responsabilidades",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.assignments || "Não preenchido",
            },
            {
              title: "Valor da bolsa auxílio",
              type: "text",
              text: candidate?.proposalLetterInfo?.salary
                ? formatCurrency(candidate?.proposalLetterInfo?.salary)
                : "Não preenchido",
            },
            {
              title: "Remuneração Variável",
              type: "text",
              text:
                candidate?.proposalLetterInfo?.variableSalary ||
                "Não preenchido",
            },
            {
              title: "Líder direto",
              type: "text",
              text: candidate?.leader?.name || "Não preenchido",
            },
            {
              title: "Carga horária",
              type: "text",
              text: candidate?.internship?.workload
                ? WorkloadMask(candidate?.internship?.workload)
                : "Não preenchido",
            },
            {
              title: "Período de trabalho",
              type: "text",
              text: candidate?.internship?.workship
                ? WorkshipMask(candidate?.internship?.workship)
                : "Não preenchido",
            },
            {
              title: "Data prevista de início",
              type: "text",
              text: candidate?.internship?.startDate
                ? renderDate(candidate?.internship?.startDate)
                : "Não preenchido",
            },
          ],
        },
        {
          title: "Anexar documentos",
          fields: [
            {
              title: "Sobre a empresa",
              type: "file",
              file: candidate.proposalLetterInfo?.aboutCompany,
            },
            {
              title: "Benefícios",
              type: "file",
              file: candidate.proposalLetterInfo?.benefits,
            },
          ],
        },
      ],
      columnId: "yytMYZdnZ6sEDTTEwQUC6",
      order: 1,
    },
    {
      title: "Envio de documentos",
      description: "",
      items: [
        {
          title: "Documentos da pessoa",
          fields: [
            {
              title: "Documentos coletados",
              type:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? "counter"
                  : "text",
              text:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? countDocumentWithValue(confirmationData?.documentFields)
                  : "Não preenchido",
            },
          ],
        },
        {
          title: "Documentos de dependentes",
          fields: [
            {
              title: "Documentos coletados",
              type: confirmationData?.dependents?.length ? "counter" : "text",
              text: confirmationData?.dependents?.length
                ? countDependentDocuments(confirmationData?.dependents)
                : "Não preenchido",
            },
          ],
        },
      ],
      columnId: "8ESbDkaIj7IvuDbNSWoFi",
      order: 2,
    },
    {
      title: "Validação de documentos",
      description: "",
      items: [
        {
          title: "Documentos da pessoa",
          fields: [
            {
              title: "Documentos coletados",
              type:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? "counter"
                  : "text",
              text:
                confirmationData?.documentFields?.length &&
                confirmationData.documentFields.length > 0
                  ? countDocumentWithValue(confirmationData?.documentFields)
                  : "Não preenchido",
            },
          ],
        },
        {
          title: "Documentos de dependentes",
          fields: [
            {
              title: "Documentos coletados",
              type: confirmationData?.dependents?.length ? "counter" : "text",
              text: confirmationData?.dependents?.length
                ? countDependentDocuments(confirmationData?.dependents)
                : "Não preenchido",
            },
          ],
        },
      ],
      columnId: "CxlaQY9Ey_ZNwn1wKId4X",
      order: 3,
    },
    {
      title: "Exame admissional",
      description: "",
      items: [
        {
          title: "Checklist de tarefas",
          fields: [
            {
              title: "",
              type: "checklist",
              checklist: card?.checklists?.find(
                (checklist) => checklist.columnId === "7Xox8DiCYKqyetiQaY3qC",
              ),
            },
          ],
        },
        {
          title: "Agendamento do exame",
          fields: [
            {
              title: "Data do exame",
              type: "text",
              text: card.admissionalExam?.date
                ? renderDate(card.admissionalExam?.date)
                : "Não preenchido",
            },
          ],
        },
        {
          title: "Resultado do exame",
          fields: [
            {
              title: "ASO",
              type: "file",
              file: card?.admissionalExam?.file
                ? [card.admissionalExam?.file]
                : undefined,
            },
            {
              title: "Resultado",
              type: "text",
              text: !card.admissionalExam
                ? "Não preenchido"
                : card.admissionalExam.status === "approved"
                  ? "Apta"
                  : card.admissionalExam.status === "declined"
                    ? "Inapta"
                    : "Aguardando processo",
            },
          ],
        },
      ],
      columnId: "7Xox8DiCYKqyetiQaY3qC",
      order: 4,
    },
    {
      title: "Assinatura de contrato",
      description: "",
      items: [
        {
          title: "Status",
          fields: [
            {
              title: candidateSignature ? candidateSignature.name : "Pessoa",
              type: candidateSignature ? "status" : "text",
              text: "Não preenchido",
              signature: candidateSignature,
            },
            {
              title: companySignature ? companySignature.name : "Empresa",
              type: companySignature ? "status" : "text",
              text: "Não preenchido",
              signature: companySignature,
            },
          ],
        },
        {
          title: "Documentos",
          fields: [
            {
              title: "Documentos",
              type: "file",
              file: contract?.template || [],
            },
          ],
        },
      ],
      columnId: "cFZfUaMVksdXqQRQn14z0",
      order: 5,
    },
  ]
}
