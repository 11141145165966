import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import timezone from "dayjs/plugin/timezone"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import ptBrLocale from "dayjs/locale/pt-br"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(localeData)
const portugueseCustomOptions = {
  ...ptBrLocale,
  formats: {
    ...ptBrLocale.formats,
    MMMM: "MMMM",
  },
}
dayjs.locale("pt-br", portugueseCustomOptions)

dayjs.tz.setDefault("America/Sao_Paulo")

export { dayjs }
