import { generatePath, useParams } from "react-router-dom"
import { PageTemplate } from "../../../components"
import { ReplaceLeaderContent } from "../../../components/screen/ReplaceLeader"
import {
  REPLACE_LEADER_PAGE_URL,
  SELECT_PEOPLE_PAGE_URL,
} from "src/routes/routesConfig"

const ReplaceLeader = () => {
  const { flowId = "" } = useParams()

  const selectPeoplePageRouterPath = generatePath(SELECT_PEOPLE_PAGE_URL, {
    flowId,
  })

  const replaceLeaderPageRouterPath = generatePath(REPLACE_LEADER_PAGE_URL, {
    flowId,
  })

  return (
    <PageTemplate
      history={[
        { name: "Desligamento", href: "/flows/resignation" },
        {
          name: "Selecionar pessoa",
          href: selectPeoplePageRouterPath,
        },
        {
          name: "Substituir líder",
          href: replaceLeaderPageRouterPath,
        },
      ]}
    >
      <ReplaceLeaderContent />
    </PageTemplate>
  )
}

export default ReplaceLeader
