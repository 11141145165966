import { AdmissionalExamStatus, HiringCard } from "@customTypes/card"
import dispatchToast from "@utils/dispatchToast"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Row, StatusContainer, StyleStatusButton } from "../../styles"
import {
  Button,
  DatePicker,
  Icons,
  Loader,
  Radio,
  Skeleton,
  TextArea,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Upload } from "@components/common"
import { trpc } from "@api/client"
import i18n from "@i18n"

export const ValidateContent = ({ card }: { card: HiringCard }) => {
  const [refuseReason, setRefuseReason] = useState<string>("")
  const [documentStatus, setDocumentStatus] = useState<
    "toAccept" | "toResend" | null
  >(
    ["approved", "declined"].includes(card.admissionalExam?.status || "")
      ? "toAccept"
      : null,
  )

  const [t] = useTranslation("translations", {
    keyPrefix: "page.hiring.admissionalExam",
  })
  const utils = trpc.useUtils()

  const { mutateAsync: refuseAdmissionalExam, isLoading: isRefusing } =
    trpc.admissionalExam.refuseAdmissionalExam.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
      onSuccess: () => {
        utils.card.getHiringCardById.invalidate()
      },
    })

  const { mutateAsync: approveAdmissionalExam, isLoading: isApproving } =
    trpc.admissionalExam.approveAdmissionalExam.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
      onSuccess: () => {
        utils.card.getHiringCardById.invalidate()
      },
    })

  const { mutateAsync: resendAdmissionalExam, isLoading: isResending } =
    trpc.admissionalExam.resendAdmissionalExam.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
      onSuccess: () => {
        utils.card.getHiringCardById.invalidate()
        dispatchToast({
          content: t("toastResendSuccess"),
          type: "success",
        })
      },
    })

  const handleResend = async () => {
    if (!refuseReason || refuseReason.length === 0) {
      dispatchToast({
        type: "error",
        content: t("toastRefuseReason"),
      })
      return
    }

    await resendAdmissionalExam({
      flowCardId: card._id,
      status: "declined",
      statusComment: refuseReason,
    })
  }

  const handleApprove = async () => {
    await approveAdmissionalExam({
      flowCardId: card._id,
    })
  }

  const handleRefuse = async () => {
    await refuseAdmissionalExam({
      flowCardId: card._id,
    })
  }

  return (
    <>
      <Row>
        <div>
          <Typography variant="headline8" variantColor="#53464F">
            {t("dateLimitTitle")}
          </Typography>
          <Typography variant="body4" variantColor="#83727D">
            {t("dateLimitDescription")}
          </Typography>
        </div>

        <DatePicker
          label={t("dateLimitLabel")}
          value={card.admissionalExam.limitDate}
          onDateChange={() => {}}
          disabled={true}
          fullWidth
        />
      </Row>

      <Row>
        <div>
          <Typography variant="headline8" variantColor="#53464F">
            {t("guideTitle")}
          </Typography>
          <Typography variant="body4" variantColor="#83727D">
            {t("guideDescription")}
          </Typography>
        </div>

        <Upload
          value={card.admissionalExam.medicalReferral}
          label={t("guideLabel")}
          accept={["jpg", "png", "pdf"]}
          folder={`${card.companyId}/${card.candidateId}/${card._id}/aso`}
          canDelete={false}
        />
      </Row>

      <Row>
        <div>
          <Typography variant="headline8" variantColor="#53464F">
            {t("asoTitle")}
          </Typography>
          <Typography variant="body4" variantColor="#83727D">
            {t("asoDescription")}
          </Typography>
        </div>

        {!card.admissionalExam.file ? (
          <StatusContainer>
            <Typography
              variant="body3"
              variantColor="#53464F"
              style={{
                fontWeight: 700,
              }}
            >
              {card.candidate.name}
            </Typography>
            <Typography
              variant="body4"
              variantColor="#83727D"
              style={{
                textDecoration: "underline",
              }}
            >
              {card.candidate.email}
            </Typography>
            <Typography
              variant="body4"
              variantColor="#C96C01"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontWeight: 700,
              }}
            >
              <Icons
                name="IconClock"
                fill="transparent"
                size={16}
                style={{
                  marginRight: 5,
                }}
              />
              {t("asoStatus")}
            </Typography>
          </StatusContainer>
        ) : (
          <>
            <DatePicker
              label={t("asoExamDate")}
              value={card.admissionalExam.date}
              onDateChange={() => {}}
              disabled={true}
              fullWidth
            />

            <div>
              <Typography
                variant="body3"
                variantColor="#53464F"
                style={{ marginBottom: 4 }}
              >
                ASO
              </Typography>
              <Upload
                value={card.admissionalExam.file}
                label={t("guideLabel")}
                accept={["jpg", "png", "pdf"]}
                folder={`${card.companyId}/${card.candidateId}/${card._id}/aso`}
                canDelete={false}
              />
            </div>
          </>
        )}
      </Row>

      {card.admissionalExam?.status &&
        [
          AdmissionalExamStatus.filled,
          AdmissionalExamStatus.approved,
          AdmissionalExamStatus.declined,
        ].includes(card.admissionalExam.status) && (
          <Row>
            {isRefusing || isApproving ? (
              <Skeleton width={"100%"} height={200} />
            ) : (
              <>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "calc(50% - 12px) calc(50% - 12px)",
                    gap: 24,
                  }}
                >
                  <StyleStatusButton
                    size="medium"
                    buttonType="secondary"
                    variantType="error"
                    onClick={() => setDocumentStatus("toResend")}
                    style={{ width: "100%" }}
                    disabled={[
                      AdmissionalExamStatus.approved,
                      AdmissionalExamStatus.declined,
                    ].includes(card.admissionalExam.status)}
                    active={documentStatus === "toResend"}
                  >
                    <Icons name="IconX" fill="transparent" />
                    {t("asoReproveButton")}
                  </StyleStatusButton>
                  <StyleStatusButton
                    size="medium"
                    buttonType="secondary"
                    variantType="success"
                    onClick={() => setDocumentStatus("toAccept")}
                    style={{ width: "100%" }}
                    active={documentStatus === "toAccept"}
                  >
                    <Icons name="IconCheck" fill="transparent" />
                    {t("asoApproveButton")}
                  </StyleStatusButton>
                </div>

                {documentStatus === "toResend" && (
                  <>
                    <TextArea
                      maxLength={100}
                      placeholder={t("textAreaRefuseLabel")}
                      onChange={(value: any) =>
                        setRefuseReason(value.target.value)
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="primary"
                        onClick={handleResend}
                        disabled={isResending}
                        size="medium"
                      >
                        {isResending ? (
                          <Loader size="extraSmall" variant="secondary" />
                        ) : (
                          <>
                            {t("buttonResendRequest")}
                            <Icons fill="transparent" name="IconMail" />
                          </>
                        )}
                      </Button>
                    </div>
                  </>
                )}

                {documentStatus === "toAccept" && (
                  <div>
                    <Typography
                      variant="body4"
                      variantColor="#83727D"
                      style={{ marginBottom: 8 }}
                    >
                      {t("asoCanWorkDescription")}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Radio
                        name="radio-buttons"
                        onChange={handleApprove}
                        checked={
                          card.admissionalExam.status ===
                          AdmissionalExamStatus.approved
                        }
                      />
                      <Typography variant="body3" variantColor="#53464F">
                        {t("asoCanWorkLabel")}
                      </Typography>

                      <Radio
                        name="radio-buttons"
                        onChange={handleRefuse}
                        checked={
                          card.admissionalExam.status ===
                          AdmissionalExamStatus.declined
                        }
                        style={{ marginLeft: 40 }}
                      />
                      <Typography variant="body3" variantColor="#53464F">
                        {t("asoCannotWorkLabel")}
                      </Typography>
                    </div>
                  </div>
                )}
              </>
            )}
          </Row>
        )}
    </>
  )
}
