import { Contract, SendSignRequestResponse } from "@customTypes/contract"
import { dayjs } from "@utils/dayjs"

export function mapSendSignRequestResponseToContract(
  sendSignRequestResponse: SendSignRequestResponse,
) {
  const contract: Contract = {
    ...sendSignRequestResponse,
    signature: sendSignRequestResponse.signature.map((signature) => ({
      ...signature,
      signedAt: signature.signedAt
        ? dayjs(signature.signedAt).toDate()
        : undefined,
    })),
  }

  return contract
}
