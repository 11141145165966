import { Loader } from "@flash-tecnologia/hros-web-ui-v2"
import { HiringCardTemplate } from "../CardTemplate"
import { PageTemplate, RouteHistory } from "../CardPageTemplate"
import { SpinnerContainer } from "./styles"
import { HiringContent, HiringContentProps, HiringPageProps } from "./types"
import dispatchToast from "../../../utils/dispatchToast"
import { useLocation, useNavigate } from "react-router-dom"
import { hiringFlowColumns } from "../../../mock/board"
import { trpc } from "@api/client"

interface CustomContentProps extends HiringContentProps {
  content: HiringContent
}

const CustomContent = ({
  card,
  flow,
  content,
}: CustomContentProps): JSX.Element => {
  if (!flow.subcategory) return <></>
  const Content = content[flow.subcategory]
  return <Content card={card} flow={flow} />
}

interface ContentListProps extends HiringContentProps {
  contents: HiringContent[]
}

const renderContentList = ({
  card,
  flow,
  contents,
}: ContentListProps): JSX.Element[] => {
  return contents.map((item, index) => (
    <CustomContent
      key={`custom-content-${index}`}
      card={card}
      flow={flow}
      content={item}
    />
  ))
}

const HiringCardPage = ({
  cardId,
  pageHistory,
  contents,
  loading,
}: HiringPageProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const {
    data: getHiringCardByIdData,
    isLoading,
    isRefetching,
  } = trpc.card.getHiringCardById.useQuery(
    { cardId },
    {
      enabled: !!cardId,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      queryKey: ["card.getHiringCardById", { cardId }],
      retry: false,
      onError: (err: any) => {
        const defaultError = "Ocorreu um erro ao encontrar o candidato"
        const errorMessage =
          err.serviceError?.details?.[0]?.message ||
          err.serviceError?.message ||
          err.httpError?.message ||
          err?.message

        dispatchToast({
          content: errorMessage || defaultError,
          type: "error",
        })
        navigate("/flows/hiring")
      },
      onSuccess: (card) => {
        const columnFromPathname = hiringFlowColumns.find((column) =>
          location.pathname.includes(column.route),
        )
        if (
          !columnFromPathname ||
          columnFromPathname.columnsId.includes(card.columnId)
        )
          return

        const rightColumnInfo = hiringFlowColumns.find((column) =>
          column.columnsId.includes(card.columnId),
        )
        if (rightColumnInfo) {
          navigate(`${rightColumnInfo.route}${card._id}`, {
            replace: true,
          })
        }
      },
    },
  )

  const card = getHiringCardByIdData as unknown as any

  const { data: getOnlyFlowByIdData, isInitialLoading: isFlowLoading } =
    trpc.flow.getOnlyFlowById.useQuery(
      {
        flowId: card?.flowId as string,
      },
      {
        enabled: !!card,
        refetchOnWindowFocus: false,
      },
    )

  const flow = getOnlyFlowByIdData as unknown as any

  if (!flow || !card || isFlowLoading || isLoading || loading || isRefetching) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  const breadcrumbs: RouteHistory[] = pageHistory.map((history) => {
    if (!flow.subcategory) throw new Error("Flow subcategory is required")
    let name = history.name
    if (!name && history.dynamicName) {
      name = history.dynamicName(flow.subcategory)
    }
    let href = history.href
    if (!href && history.dynamicHref) {
      href = history.dynamicHref(flow.subcategory)
    }
    return {
      name: name || "",
      href,
    }
  })

  return (
    <PageTemplate
      history={breadcrumbs}
      footer={{
        card,
        flow: flow,
        flowType: "hiring",
        disableButton: isRefetching,
      }}
    >
      <HiringCardTemplate card={card} flow={flow}>
        {Array.isArray(contents) ? (
          renderContentList({ card, flow, contents })
        ) : (
          <CustomContent card={card} flow={flow} content={contents} />
        )}
      </HiringCardTemplate>
    </PageTemplate>
  )
}

export default HiringCardPage
