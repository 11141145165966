import { z } from "zod"
import { S3File } from "."

export enum SignType {
  candidate = "candidate",
  company = "company",
  witness = "witness",
  institution = "institution",
  employee = "person",
}

export enum SignStatus {
  pending = "Pending",
  signed = "Signed",
}

export enum SignatureSignStatus {
  pending = "Pending",
  signed = "Signed",
  external = "External",
}

export type DumbSignInfoParam = { name: string; email: string; phone?: string }
export type SignInfoParam = { id: string } & DumbSignInfoParam

export const DumbSignInfoParamZodObject = z.object({
  name: z.string(),
  email: z.string(),
  phone: z.string().optional(),
})

export const SignInfoParamZodObject = DumbSignInfoParamZodObject.extend({
  id: z.string(),
})

export type Signature = {
  candidateId?: string
  employeeId?: string
  name: string
  email: string
  phone: string | null
  type: SignType
  status: SignatureSignStatus
  token?: string
  signedAt?: Date
  signatureFile?: any
  ip?: string
  confirmationInfo?: {
    name?: string
    documentNumber: string
    email: string
  }
}

export interface ContractOutput extends S3File {
  version: number
}

export type Contract = {
  _id: string
  companyId: string
  flowCardId: string
  contractDuration?: string
  category: "hiring" | "resignation"
  template: S3File[]
  modelContract?: { custom: string; origin: string }[]
  status: SignStatus
  signature: Signature[]
  contractOutput: ContractOutput[]
  lastContractOutput?: S3File
}
