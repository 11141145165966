import {
  segmentEventTracking,
  segmentPageTracking,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility"
import { useCallback } from "react"

type EventProps = {
  name: string
  params?: Record<string, string>
}

const MODULE_NAME = "hiring"
const BUSINESS_UNIT = "people"

export function useTracking() {
  const { selectedCompany } = useSelectedCompany()

  const buildTrackProps = useCallback(
    (event: EventProps) => {
      return {
        businessUnit: BUSINESS_UNIT,
        module: MODULE_NAME,
        name: event.name,
        params: {
          company_id: selectedCompany?.id || "",
          ...event?.params,
        },
      }
    },
    [selectedCompany?.id],
  )

  const trackEvent = useCallback(
    (event: EventProps) => {
      const trackEventProps = buildTrackProps(event)

      return segmentEventTracking(trackEventProps)
    },
    [buildTrackProps],
  )

  const trackPage = useCallback(
    (event: EventProps) => {
      const trackPageProps = buildTrackProps(event)

      return segmentPageTracking(trackPageProps)
    },
    [buildTrackProps],
  )

  return {
    trackEvent,
    trackPage,
  }
}
