import { useCallback, useMemo } from "react"

import AccordionHistory from "../../AccordionHistory"

import {
  Flow,
  HiringCard,
  HistoryData,
  ResignationCard,
} from "../../../../types"

import {
  cltHiringData,
  internshipHiringData,
  pjHiringData,
  resignationData,
} from "./historyMock"
import EmptyHistory from "../../../../assets/empty_history.svg"

import {
  Container,
  EmptyDescription,
  EmptyStateWrapper,
  EmptyTitle,
  StyledPOneLine,
} from "./styles"

import { Skeleton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { trpc } from "@api/client"

interface HistoryTab {
  card: HiringCard | ResignationCard
  flow: Flow
  data: HistoryData[]
  loading?: boolean
}

const HistoryTab = ({ card, flow, data, loading }: HistoryTab) => {
  const theme = useTheme()

  const renderHistory = useCallback(() => {
    if (!data) return null

    const lastColumnPosition =
      data.find((history) => history.columnId === card.columnId)?.order ?? 999

    const filteredHistory = data.filter(
      (history) => history.order < lastColumnPosition,
    )
    if (!filteredHistory) {
      throw new Error("History wasn't able to render.")
    }

    const orderedHistory = filteredHistory.sort((a, b) => b.order - a.order)
    return orderedHistory.map((data, idx) => (
      <AccordionHistory
        key={idx}
        title={data.title}
        description={data.description}
        items={data?.items}
      />
    ))
  }, [data])

  const actualColumn = flow?.columns?.find(
    (column) => column._id === card.columnId,
  )
  const isFirstColumn = actualColumn?.position === 0

  if (isFirstColumn) {
    return (
      <Container>
        <EmptyStateWrapper>
          <EmptyHistory />
          <EmptyTitle variant="headline7">
            Você ainda não preencheu nenhuma outra etapa
          </EmptyTitle>
          <EmptyDescription variant="body3">
            O resumo dos dados preenchidos em cada etapa será exibido aqui.
          </EmptyDescription>
        </EmptyStateWrapper>
      </Container>
    )
  }

  return (
    <Container>
      <StyledPOneLine numberOfLines={1}>
        <Typography variantColor={theme.colors.neutral30} variant="headline8">
          Histórico
        </Typography>
      </StyledPOneLine>

      {loading ? (
        <Skeleton variant="rectangular" width={"100%"} height={400} />
      ) : (
        renderHistory()
      )}
    </Container>
  )
}

interface ResignationHistoryTabProps {
  flow: Flow
  card: ResignationCard
}

export const ResignationHistoryTab = ({
  card,
  flow,
}: ResignationHistoryTabProps) => {
  const { data: contract, isInitialLoading: isContractLoading } =
    trpc.contract.getContractByFlowCardId.useQuery(
      { flowCardId: card._id },
      {
        queryKey: ["contract.getContractByFlowCardId", { flowCardId: card._id }],
        enabled: !!card._id,
        refetchOnWindowFocus: false,
        retry: 0,
      },
    )

  const historyData = useMemo(() => {
    return resignationData(card, contract)
  }, [card, contract])

  return (
    <HistoryTab
      card={card}
      flow={flow}
      data={historyData}
      loading={isContractLoading}
    />
  )
}

interface HiringHistoryTabProps {
  card: HiringCard
  flow: Flow
}

export const HiringHistoryTab = ({ card, flow }: HiringHistoryTabProps) => {
  const { data: contract, isInitialLoading: isContractLoading } =
    trpc.contract.getContractByFlowCardId.useQuery(
      { flowCardId: card._id },
      {
        queryKey: ["contract.getContractByFlowCardId", { flowCardId: card._id }],
        enabled: !!card._id,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
      },
    )

  const { data: getConfirmationData, isInitialLoading: isConfirmationLoading } =
    trpc.card.getConfirmationData.useQuery(
      {
        candidateId: card.candidateId,
        formConfigId: card.formConfigId,
        extensionId: card.formExtensionId,
      },
      {
        enabled: !!(card.formConfigId && card.candidateId),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
      },
    )

  const confirmationInfo = getConfirmationData as unknown as any

  const historyData = useMemo(() => {
    if (isConfirmationLoading || isContractLoading) return []
    switch (flow.subcategory) {
      case "clt":
        return cltHiringData(card.candidate, card, contract, confirmationInfo)
      case "pj":
        return pjHiringData(card.candidate, card, contract, confirmationInfo)
      case "internship":
        return internshipHiringData(
          card.candidate,
          card,
          contract,
          confirmationInfo,
        )
      default:
        console.error("Invalid flow subcategory")
        return []
    }
  }, [
    card,
    flow,
    contract,
    confirmationInfo,
    isContractLoading,
    isConfirmationLoading,
  ])

  return (
    <HistoryTab
      card={card}
      flow={flow}
      data={historyData || []}
      loading={isContractLoading || isConfirmationLoading}
    />
  )
}
