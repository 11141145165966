import { trpc } from "@api/client"
import { Box } from "@components/common/atoms/Box"
import { Loader } from "@flash-tecnologia/hros-web-ui-v2"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import { PageTemplate } from "../../../components"
import { SelectPeopleContent } from "../../../components/screen/SelectPeople"

interface SelectPeoplePageState {
  unselectableEmployeeIds: string[]
}

const SelectPeople = () => {
  const location = useLocation()

  const state = location.state as SelectPeoplePageState | null

  const [unselectableEmployeeIds, setUnselectableEmployeeIds] = useState<
    string[] | undefined
  >(state?.unselectableEmployeeIds)

  const { isFetching } = trpc.resignationCard.getAllResignationCards.useQuery(
    undefined,
    {
      onSuccess: (data) => {
        setUnselectableEmployeeIds(data.map((card) => card.employeeId))
      },
      enabled: !unselectableEmployeeIds,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      queryKey: ["resignationCard.getAllResignationCards", undefined],
    },
  )

  const steps = ["Desligamento", "Selecionar pessoa"]

  return (
    <PageTemplate
      history={[
        { name: "Desligamento", href: "/flows/resignation" },
        {
          name: "Selecionar pessoa",
          href: `/flows/selectPeople`,
        },
      ]}
    >
      {isFetching ? (
        <Box
          justifyContent="center"
          alignItems="center"
          $mt={"m"}
          $width={"100%"}
        >
          <Loader variant="primary" size="large" />
        </Box>
      ) : (
        <SelectPeopleContent
          unselectableEmployeeIds={unselectableEmployeeIds}
          steps={steps}
          currentStep={1}
        />
      )}
    </PageTemplate>
  )
}

export default SelectPeople
