import { trpc } from "@api/client"
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  Icons,
  Loader,
  SearchField,
  SelectField,
  Skeleton,
  Tag,
  TextField,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  createPreSignedUrl,
  uploadFileToS3,
} from "@flash-tecnologia/hros-web-utility"
import { useMutation } from "@tanstack/react-query"
import { HiringLimitDateEnum } from "bff/src/types/hiring"
import { concat } from "lodash"
import React, { useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { api } from "../../../api"
import SignedContract from "../../../assets/done.svg"
import {
  DeprecatedUpload,
  HiringLimitDate,
  LimitDateRef,
  PreviewLoading,
  WizardCardLink,
} from "../../../components"
import { UploadPreview } from "../../../components/common/UploadPreview"
import { hiringCardContext } from "../../../components/screen/CardTemplate/Hiring"
import {
  Contract,
  EmailLink,
  Flow,
  HiringCard,
  S3File,
  SignInfoParam,
  SignStatus,
  SignType,
  SignatureSignStatus,
} from "../../../types"
import {
  createSegmentTrack,
  isObjectEmpty,
  processingFile,
} from "../../../utils"
import dispatchToast from "../../../utils/dispatchToast"
import { ContractTemplateUpload } from "./components/ContractTemplateUpload"
import { FormInstitution, InstitutionForm } from "./components/InstitutionForm"
import { ConfirmationSignatureModal } from "./components/ModalConfirmationSignature"
import { PreviewUploadComponent } from "./components/PreviewUploadComponent"
import {
  Container,
  ContentContainer,
  Details,
  FormButton,
  ProposalAcceptedContainer,
  RightContainer,
  Section,
  SectionSubTitle,
  SectionSubTitleAccordion,
  SectionTitle,
  StyledAccordion,
  StyledLinkButton,
  StyledPOneLine,
  SubContainer,
  Summary,
} from "./styles"
import { ValidationErrorException } from "./utils"
import { getGenericToastMessage } from "./utils/getGenericToastMessage"
import { getSendContractCustomValidationToastMessage } from "./utils/getSendContractCustomValidationToastMessage"
import { ToastMessageCodes } from "./utils/toastMessageCodes"

type WitnessInfo = {
  name?: string
  email?: string
  phone?: string
  employeeId?: string
  isNotEmployee: boolean
}

function getMultipleEmailLinksOrdered(
  subcategory: "clt" | "internship" | "pj",
  emailLinks: EmailLink[],
): { title: string; emailLink: EmailLink }[] {
  const signatureOrderMap = {
    ["candidateSign"]: {
      title:
        subcategory === "internship"
          ? "Link do estagiário"
          : "Link do candidato",
      order: 0,
    },
    ["witnessSign0"]: {
      title: "Link da testemunha 1",
      order: 1,
    },
    ["witnessSign1"]: {
      title: "Link da testemunha 2",
      order: 2,
    },
    ["companySign"]: {
      title: "Link da empresa",
      order: 3,
    },
    ["institutionSign"]: {
      title: "Link da instituição de ensino",
      order: 4,
    },
  }

  const mappedEmailLinks =
    emailLinks?.map((emailLink) => ({
      emailLink: emailLink,
      ...signatureOrderMap[emailLink?.name],
    })) ?? []

  const sortedEmailLinks = mappedEmailLinks.sort((a, b) => a.order - b.order)
  return sortedEmailLinks.map(({ order, ...rest }) => rest)
}

export const GenericContent = ({
  card,
  flow,
}: {
  card: HiringCard
  flow: Flow
}) => {
  const [contract, setContract] = useState<Contract | undefined>(undefined)
  const [template, setTemplate] = useState<S3File[]>([])
  const [contractOutput, setContractOutput] = useState<any>([])
  const [modelContract, setModelContract] = useState<
    { label: string; _id: string; customModelContractId?: string }[]
  >([])
  const [companyEmployee, setCompanyEmployee] = useState<
    SignInfoParam | undefined
  >(undefined)
  const [employeeOptions, setEmployeeOptions] = useState<
    SignInfoParam[] | undefined
  >(undefined)
  const [witnessArray, setWitnessArray] = useState<WitnessInfo[]>([
    {
      name: "",
      email: "",
      phone: "",
      employeeId: "",
      isNotEmployee: false,
    },
  ])
  const [error, setError] = useState({
    companyEmployee: { hasError: false, errorText: "", toastMessage: "" },
    witnessEmployee1: { hasError: false, errorText: "", toastMessage: "" },
    witnessEmployee2: { hasError: false, errorText: "", toastMessage: "" },
    witnessName1: { hasError: false, errorText: "" },
    witnessName2: { hasError: false, errorText: "" },
    witnessEmail1: { hasError: false, errorText: "" },
    witnessEmail2: { hasError: false, errorText: "" },
    institutionName: { hasError: false, errorText: "" },
    institutionMail: { hasError: false, errorText: "" },
  })
  const [modelMissingFields, setModelMissingFields] = useState<any>([])
  const [isSendingContract, setIsSendingContract] = useState<boolean>(false)
  const [isResendingContract, setIsResendingContract] = useState<
    | {
        type?: SignType
        isResending?: boolean
      }
    | undefined
  >(undefined)
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false)
  const [formInstitution, setFormInstitution] = useState<FormInstitution>({
    institutionMail: "",
    institutionName: "",
    institutionPhone: "",
    institutionEmployeeId: "",
  })
  const [dropZoneKey, setDropZoneKey] = useState(false)
  const [loadingFile, setLoadingFile] = useState<boolean>(false)

  const { setResetStep } = React.useContext(hiringCardContext)
  const limitDateRef = useRef<LimitDateRef>(null)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const { isInitialLoading: isContractLoading } =
    trpc.contract.getContractByFlowCardId.useQuery(
      {
        flowCardId: card._id,
      },
      {
        queryKey: [
          "contract.getContractByFlowCardId",
          { flowCardId: card._id },
        ],
        enabled: !!card,
        refetchOnWindowFocus: false,
        onSuccess: (contract) => setContract(contract as unknown as any),
        retry: false,
      },
    )

  const { data: templateOptions } =
    trpc.contract.getModelContractByName.useQuery(
      {
        flowId: flow._id,
        candidateId: card.candidateId,
      },
      {
        enabled: !!flow && !!card,
        refetchOnWindowFocus: false,
        retry: false,
      },
    )

  const { mutateAsync: sendSignRequestV2 } = useMutation(
    api.mutation.hiring.contract.sendSignRequestV2,
  )

  const { mutateAsync: resendSignRequest } = useMutation(
    api.mutation.hiring.contract.resendSignRequest,
  )

  const { mutateAsync: resetContract, isLoading: isResetLoading } = useMutation(
    api.mutation.hiring.contract.resetContract,
  )

  const { mutateAsync: verifyModelContract } = useMutation(
    api.mutation.hiring.contract.verifyModelContract,
    {
      onSuccess: (modelContractIds) => {
        setModelMissingFields(modelContractIds)

        if (modelContractIds.length) {
          setOpenConfirmationModal(false)
          dispatchToast({
            type: "error",
            content: getGenericToastMessage(
              ToastMessageCodes.MissingModelContractFields,
            ),
          })
        }
      },
      onError: () => {
        setOpenConfirmationModal(false)
        dispatchToast({
          type: "error",
          content: getGenericToastMessage(
            ToastMessageCodes.GenericContractFailure,
          ),
        })
      },
    },
  )

  const { mutateAsync: getPartialEmployee, isLoading: isSearching } =
    trpc.employee.getEmployeeByPartialName.useMutation()

  const utils = trpc.useUtils()

  React.useEffect(() => {
    setResetStep({
      canReset: !!contract,
      confirmCallback: () => {
        resetContract(contract?._id as string, {
          onSuccess: () => {
            dispatchToast({
              type: "success",
              content: getGenericToastMessage(
                ToastMessageCodes.DeleteSuccessfull,
              ),
            })

            utils.card.getHiringCardById.invalidate({ cardId: card._id })
            utils.contract.getContractByFlowCardId.invalidate({
              flowCardId: card._id,
            })
          },
          onError: () => {
            dispatchToast({
              type: "error",
              content: getGenericToastMessage(
                ToastMessageCodes.ResetFailure,
              ),
            })
          },
        })
      },
      isLoading: isResetLoading,
    })
  }, [contract, isResetLoading])

  const findEmailLink = useMemo(() => {
    const linksFromThisColumn = card.emailLink?.filter(
      (e) => e.columnId === card.columnId,
    )
    return getMultipleEmailLinksOrdered(
      flow.subcategory || "clt",
      linksFromThisColumn,
    )
  }, [card.emailLink, flow.subcategory])

  const limitDateStep = useMemo(() => {
    return flow.subcategory === "clt"
      ? HiringLimitDateEnum.signContract_Clt
      : flow.subcategory === "internship"
        ? HiringLimitDateEnum.signContract_Estag
        : HiringLimitDateEnum.signContract_Pj
  }, [flow.subcategory])

  if (isContractLoading)
    return (
      <Skeleton
        variant="rectangular"
        style={{
          width: "100%",
          height: "400px",
        }}
      />
    )

  const handleFile = async ({ name, file }) => {
    createSegmentTrack({
      track: `company_hiring_${flow.subcategory}_fileupload_contract_clicked`,
    })
    setLoadingFile(true)

    const url = `${
      card.companyId +
      "/" +
      card.candidateId +
      "/" +
      card._id +
      "/contract/" +
      name
    }`

    let templateParams: S3File = { key: "", path: url, type: "internal" }
    let contractOutputParams = {}

    await createPreSignedUrl({
      filename: url,
      module: "employee-pii",
      contentType: file.type,
    })
      .then(async ({ key = "", signedUrl = "" }) => {
        templateParams = { key, path: url, type: "internal" }

        await uploadFileToS3({
          file: file,
          url: signedUrl,
        })
        contractOutputParams = concat(contractOutput, [
          {
            key,
            path: url,
            type: "internal",
            version: (contractOutput?.length || 0) + 1,
          },
        ])
      })
      .catch((err) => {
        dispatchToast({
          content: getGenericToastMessage(ToastMessageCodes.FileUploadFailure),
          type: "error",
        })
        setLoadingFile(false)
      })

    const fileValue = await processingFile({
      file: templateParams,
    })

    templateParams = {
      ...templateParams,
      value: fileValue?.value?.value || "",
    }

    const concatedTemplates: S3File[] = concat(
      template?.map((c) => {
        return { key: c.key, path: c.path, type: c.type, value: c.value }
      }) || [],
      templateParams,
    )

    setTemplate(concatedTemplates)
    setContractOutput(contractOutputParams)
    setDropZoneKey(!dropZoneKey)
    setLoadingFile(false)
  }

  const handleSubmit = async () => {
    async function validateLimitDate() {
      const errorOnValidate = await limitDateRef.current?.validate()
      if (!isObjectEmpty(errorOnValidate))
        throw new ValidationErrorException(
          getGenericToastMessage(ToastMessageCodes.MissingLimitDate),
        )
    }

    async function validateContract() {
      if (!modelContract.length && !template.length)
        throw new ValidationErrorException(
          getGenericToastMessage(ToastMessageCodes.MissingModelOrTemplate),
        )

      if (modelContract && modelContract?.length > 0) {
        const ids = await verifyModelContract({
          modelContracts: modelContract.map((model) => model._id),
          candidateId: card.candidateId,
        })

        if (ids.length)
          throw new ValidationErrorException(
            getGenericToastMessage(ToastMessageCodes.InvalidContractModel),
          )
      }
    }

    function validateWitness() {
      if (witnessArray?.some((witness) => witness.email)) {
        let hasError = false
        witnessArray.forEach((w, i) => {
          if (w.isNotEmployee) {
            if (!w.name) {
              hasError = true
              setError((prev) => ({
                ...prev,
                [`witnessName${i + 1}`]: {
                  hasError: true,
                  errorText: "Por favor, digite o nome da testemunha",
                },
              }))
            }

            if (!w.email) {
              hasError = true
              setError((prev) => ({
                ...prev,
                [`witnessEmail${i + 1}`]: {
                  hasError: true,
                  errorText: "Por favor, digite o email da testemunha",
                },
              }))
            }
          } else {
            if (!w.employeeId || !w.email) {
              hasError = true
              setError((prev) => ({
                ...prev,
                [`witnessEmployee${i + 1}`]: {
                  hasError: true,
                  errorText: "Por favor, selecione um colaborador",
                },
              }))
            }
          }
        })

        if (hasError)
          throw new ValidationErrorException(
            getGenericToastMessage(ToastMessageCodes.MissingWitnessData),
          )
      }
    }

    function validateCompanyEmployee() {
      if (!companyEmployee || !companyEmployee.id)
        throw new ValidationErrorException(
          getGenericToastMessage(ToastMessageCodes.MissingCompanyEmployee),
        )
    }

    function validateInstitutionForm() {
      if (flow.subcategory === "internship") {
        if (!formInstitution.type)
          throw new ValidationErrorException(
            getGenericToastMessage(ToastMessageCodes.MissingInstitutionForm),
          )

        if (formInstitution.type === "digital") {
          let errorOnInstitutionForm = false
          if (!formInstitution?.institutionMail) {
            setError((prev) => ({
              ...prev,
              institutionMail: {
                hasError: true,
                errorText: "Por favor, digite o email da instituição",
              },
            }))
            errorOnInstitutionForm = true
          }

          if (!formInstitution?.institutionName) {
            setError((prev) => ({
              ...prev,
              institutionName: {
                hasError: true,
                errorText: "Por favor, digite o nome da instituição",
              },
            }))
            errorOnInstitutionForm = true
          }

          if (errorOnInstitutionForm)
            throw new ValidationErrorException(
              getGenericToastMessage(ToastMessageCodes.MissingInstitutionData),
            )
        }
      }
    }

    setIsSendingContract(true)
    try {
      createSegmentTrack({
        track: `company_hiring_${flow.subcategory}_sendcontract_clicked`,
      })

      await validateLimitDate()
      await validateContract()
      validateWitness()
      validateCompanyEmployee()
      validateInstitutionForm()

      limitDateRef.current?.submit()
      await sendSignRequestV2(
        {
          cardId: card._id,
          columnId: card.columnId,
          companyId: card.companyId,
          category: "hiring",
          witness: witnessArray?.some((witness) => witness.email)
            ? witnessArray.map((w) => ({
                name: w.name || "",
                phone: w.phone || "",
                email: w.email || "",
              }))
            : undefined,
          candidate: {
            id: card.candidate._id,
            name: card.candidate.name,
            email: card.candidate.email,
            phone: card.candidate.phone || undefined,
          },
          companyEmployee,
          institution:
            formInstitution.type && formInstitution.type === "digital"
              ? {
                  name: formInstitution.institutionName,
                  email: formInstitution.institutionMail,
                  phone: formInstitution.institutionPhone,
                }
              : undefined,
          contractOutput: contractOutput,
          template: template?.map(({ value, ...template }) => template),
          modelContract: modelContract.map((m) => {
            return { origin: m._id, custom: m.customModelContractId }
          }),
        },
        {
          onSuccess: () => {
            setOpenConfirmationModal(false)
            setIsSendingContract(false)
            dispatchToast({
              content: "Requisição de assinatura enviada com sucesso!",
              type: "success",
            })

            utils.card.getHiringCardById.invalidate({ cardId: card._id })
            utils.contract.getContractByFlowCardId.invalidate({
              flowCardId: card._id,
            })
          },
        },
      )
    } catch (err: any) {
      let errorMessage = getGenericToastMessage(
        ToastMessageCodes.GenericSignatureFailure,
      )

      if (err instanceof ValidationErrorException) {
        if (err.message) errorMessage = err.message
      }

      dispatchToast({
        content: errorMessage,
        type: "error",
      })
      setOpenConfirmationModal(false)
      setIsSendingContract(false)
    }
  }

  const handleSendContractClick = () => {
    const witnessEmailError = [
      error.witnessEmployee1,
      error.witnessEmployee2,
    ].find(
      (witnessError) => witnessError?.hasError && witnessError?.toastMessage,
    )

    if (witnessEmailError) {
      dispatchToast({
        content: witnessEmailError.toastMessage,
        type: "error",
      })
      return
    }

    if (error.companyEmployee.hasError && error.companyEmployee.toastMessage) {
      dispatchToast({
        content: error.companyEmployee.toastMessage,
        type: "error",
      })
      return
    }

    setOpenConfirmationModal(true)
  }

  const handleResendSignature = async (signatureToReset: {
    type: SignType
    name: string
    email: string
  }) => {
    setIsResendingContract({
      type: signatureToReset.type,
      isResending: true,
    })

    try {
      const errorsOnValidate = await limitDateRef.current?.validate()
      if (!isObjectEmpty(errorsOnValidate))
        throw new ValidationErrorException(
          getGenericToastMessage(ToastMessageCodes.MissingLimitDate),
        )

      limitDateRef.current?.submit()

      if (!contract?.signature) {
        throw new ValidationErrorException(
          getGenericToastMessage(ToastMessageCodes.ResendSignatureFailure),
        )
      }

      await resendSignRequest(
        {
          cardId: card._id,
          columnId: card.columnId,
          companyId: card.companyId,
          contractId: contract?._id,
          signature: contract?.signature,
          signatureToReset,
          category: "hiring",
          subcategory: flow.subcategory,
        },
        {
          onSuccess: () => {
            utils.card.getHiringCardById.invalidate({ cardId: card._id })
            utils.contract.getContractByFlowCardId.invalidate({
              flowCardId: card._id,
            })

            setIsResendingContract((prev) => ({
              ...prev,
              isResending: false,
            }))

            dispatchToast({
              content: "Contrato reenviado!",
              type: "success",
            })
          },
        },
      )
    } catch (err: any) {
      let errorMessage = getGenericToastMessage(
        ToastMessageCodes.ResendSignatureFailure,
      )

      if (err instanceof ValidationErrorException) {
        if (err.message) errorMessage = err.message
      }

      setIsResendingContract((prev) => ({
        ...prev,
        isResending: false,
      }))
      dispatchToast({
        content: errorMessage,
        type: "error",
      })
    }
  }

  function handleWitnessChange(field: string, index: number, value: any): void {
    const tmp = [...witnessArray]
    tmp[index][field] = value
    setWitnessArray(tmp)
  }

  function verifyIfIsWitness(employeeId) {
    const witnessFound = witnessArray?.find(
      (witness) => witness.employeeId === employeeId,
    )
    if (witnessFound) return true
    return false
  }

  function validateIfWitnessIsNotEligible(employeeId) {
    if (verifyIfIsWitness(employeeId)) return true
    if (companyEmployee?.id === employeeId) return true
    return false
  }

  function validateIfCompanyEmployeeIsNotEligible(employeeId) {
    if (verifyIfIsWitness(employeeId)) return true
    return false
  }

  const hasSentSignatureRequest =
    contract && contract?.status && contract?.signature?.length > 0

  return (
    <Container>
      <RightContainer>
        {(!contract || contract?.status !== SignStatus.signed) && (
          <HiringLimitDate
            ref={limitDateRef}
            cardId={card._id}
            step={limitDateStep}
            limitDate={card.limitDates?.find(
              (limitDate) => limitDate.step === limitDateStep,
            )}
            style={{
              marginBottom: 24,
              paddingBottom: "24px",
              borderBottom: "1px solid #EBE6E9",
            }}
          />
        )}

        {findEmailLink?.length ? (
          <WizardCardLink multiplesEmailLinks={findEmailLink} />
        ) : (
          <></>
        )}

        <ContentContainer>
          {hasSentSignatureRequest ? (
            <SubContainer>
              <ProposalAcceptedContainer>
                <SignedContract />
                <Typography
                  variant="headline6"
                  style={{ color: "#6B5B66", fontWeight: 700 }}
                >
                  Contrato enviado!
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: "#83727D",
                    fontWeight: 600,
                    marginTop: "16px",
                  }}
                >
                  Agora é só aguardar o retorno do destinatário. Confira abaixo
                  as configurações do contrato encaminhado.
                </Typography>
              </ProposalAcceptedContainer>
            </SubContainer>
          ) : (
            <SubContainer>
              <Typography
                variant="headline8"
                style={{
                  color: "#53464F",
                  fontWeight: 700,
                }}
              >
                Contrato de trabalho
              </Typography>
              <Typography
                variant="body4"
                style={{
                  color: "#83727D",
                  fontWeight: 400,
                }}
              >
                Adicione os contratos que a pessoa candidata deverá assinar.
                Você pode escolher modelos prontos ou carregar arquivos do seu
                computador.
              </Typography>
              <ContractTemplateUpload
                flowId={card.flowId}
                candidateId={card.candidateId}
                hiringType={flow.subcategory}
                templates={modelContract}
                templatesOptions={templateOptions?.map((t) => {
                  return {
                    _id: t._id,
                    label: t.name,
                    customModelContractId: t?.customModelContractId,
                  }
                })}
                modelMissingFields={modelMissingFields}
                onTemplatesChanged={setModelContract}
                uploadComponent={
                  <div>
                    {template?.length ? (
                      <PreviewUploadComponent
                        template={template}
                        handleSetTemplate={(templates) =>
                          setTemplate(templates)
                        }
                      />
                    ) : (
                      <></>
                    )}

                    <DeprecatedUpload
                      key={`${template?.length}${dropZoneKey}`}
                      label={"Contrato"}
                      onChange={handleFile}
                      accept={["pdf"]}
                      customPreview={() =>
                        loadingFile ? <PreviewLoading /> : <></>
                      }
                      maxSize={5242880}
                      onFileSizeError={() => {
                        dispatchToast({
                          content:
                            "Arquivo maior que 5mb. Por favor, faça upload de arquivo menor que 5mb",
                          type: "warning",
                        })
                      }}
                    />
                  </div>
                }
              />
              <Divider />

              <Section>
                <SectionTitle variant="headline8">Destinatários</SectionTitle>
                <SectionSubTitle variant="body4">
                  Adicione os destinatários desejados no fluxo de assinatura
                </SectionSubTitle>

                <StyledAccordion>
                  <Summary
                    expandIcon={
                      <IconButton
                        size="medium"
                        variant="filled"
                        icon="IconChevronDown"
                      />
                    }
                  >
                    <Section>
                      <SectionTitle variant="headline8">Candidato</SectionTitle>
                      <SectionSubTitleAccordion variant="body4">
                        Preencha as informações abaixo conforme os dados do
                        destinatário.
                      </SectionSubTitleAccordion>
                    </Section>
                  </Summary>
                  <Details>
                    <div style={{ marginBottom: "24px" }}>
                      <TextField
                        id="name"
                        name="name"
                        label="Nome completo"
                        disabled
                        fullWidth
                        defaultValue={"Não cadastrado"}
                        value={card.candidate?.name}
                      />
                    </div>
                    <div style={{ marginBottom: "24px" }}>
                      <TextField
                        id="mail"
                        name="mail"
                        label="E-mail"
                        disabled
                        fullWidth
                        defaultValue={"Não cadastrado"}
                        value={card.candidate.email}
                      />
                    </div>
                    <div style={{ marginBottom: "24px" }}>
                      <TextField
                        id="phone"
                        name="phone"
                        label="Celular"
                        disabled
                        fullWidth
                        defaultValue={"Não cadastrado"}
                        value={card.candidate.phone}
                      />
                    </div>
                    <div style={{ marginBottom: "24px" }}>
                      <SelectField
                        name="toSign"
                        label="Assinar como"
                        disabled
                        fullWidth
                        value="candidate"
                        options={[
                          {
                            label: "Candidato",
                            value: "candidate",
                          },
                        ]}
                      />
                    </div>
                  </Details>
                </StyledAccordion>

                <StyledAccordion>
                  <Summary
                    expandIcon={
                      <IconButton
                        size="medium"
                        variant="filled"
                        icon="IconChevronDown"
                      />
                    }
                  >
                    <Section>
                      <SectionTitle variant="headline8">
                        Testemunhas
                      </SectionTitle>
                      <SectionSubTitleAccordion variant="body4">
                        Preencha as informações abaixo conforme os dados dos
                        destinatários. <br />
                        <b>Obs:</b> As testemunhas da assinatura devem ser
                        funcionários ativos na empresa.
                      </SectionSubTitleAccordion>
                    </Section>
                  </Summary>
                  <Details>
                    {witnessArray.map((w, i) => (
                      <>
                        <Typography
                          variant="body2"
                          style={{
                            color: "#53464F",
                            fontWeight: 700,
                            marginBottom: "16px",
                          }}
                        >
                          Testemunha {i + 1}
                        </Typography>
                        {w.isNotEmployee ? (
                          <>
                            <div style={{ marginBottom: "24px" }}>
                              <TextField
                                id="witnessName"
                                name="witnessName"
                                label="Nome"
                                fullWidth
                                defaultValue={"Não cadastrado"}
                                error={!!error[`witnessName${i + 1}`]?.hasError}
                                helperText={
                                  error[`witnessName${i + 1}`]?.errorText
                                }
                                value={w.name}
                                onChange={(e) =>
                                  handleWitnessChange("name", i, e.target.value)
                                }
                                onBlur={({ target: { value } }) => {
                                  if (value) {
                                    setError((prev) => ({
                                      ...prev,
                                      [`witnessName${i + 1}`]: {
                                        hasError: false,
                                        errorText: "",
                                      },
                                    }))
                                  } else {
                                    setError((prev) => ({
                                      ...prev,
                                      [`witnessName${i + 1}`]: {
                                        hasError: true,
                                        errorText:
                                          "Insira o nome da Testemunha",
                                      },
                                    }))
                                  }
                                }}
                              />
                            </div>
                            <div style={{ marginBottom: "24px" }}>
                              <TextField
                                id="witnessEmail"
                                name="witnessEmail"
                                label="E-mail"
                                fullWidth
                                defaultValue={"Não cadastrado"}
                                error={
                                  !!error[`witnessEmail${i + 1}`]?.hasError
                                }
                                helperText={
                                  error[`witnessEmail${i + 1}`]?.errorText
                                }
                                value={w.email}
                                onChange={(e) =>
                                  handleWitnessChange(
                                    "email",
                                    i,
                                    e.target.value,
                                  )
                                }
                              />
                            </div>
                            <div style={{ marginBottom: "24px" }}>
                              <TextField
                                id="witnessPhone"
                                name="witnessPhone"
                                label="Celular"
                                fullWidth
                                defaultValue={"Não cadastrado"}
                                value={w.phone}
                                onChange={(e) =>
                                  handleWitnessChange(
                                    "phone",
                                    i,
                                    e.target.value,
                                  )
                                }
                              />
                            </div>
                          </>
                        ) : (
                          <SearchField
                            label="Buscar colaborador"
                            onSearchChange={(_, option) => {
                              if (!option || !option.value) {
                                setWitnessArray((prev) => {
                                  const tmp = [...prev]
                                  tmp.splice(i, 1, {
                                    employeeId: "",
                                    email: "",
                                    name: "",
                                    phone: "",
                                    isNotEmployee: false,
                                  })
                                  return tmp
                                })
                                return
                              }

                              if (
                                validateIfWitnessIsNotEligible(option.value)
                              ) {
                                setError((prev) => ({
                                  ...prev,
                                  [`witnessEmployee${i + 1}`]: {
                                    hasError: true,
                                    errorText:
                                      "Colaborador já está como assinante em testemunha ou empresa",
                                  },
                                }))

                                dispatchToast({
                                  type: "error",
                                  content:
                                    "Colaborador já está como assinante em testemunha ou empresa",
                                })
                                return
                              }

                              const employeeData = employeeOptions?.find(
                                (employee) => employee.id === option.value,
                              )

                              if (employeeData && !employeeData.email) {
                                const toastMessage =
                                  getSendContractCustomValidationToastMessage(
                                    ToastMessageCodes.MissingWitnessEmail,
                                    employeeData.name || `${i + 1}`,
                                  )
                                setError((prev) => ({
                                  ...prev,
                                  [`witnessEmployee${i + 1}`]: {
                                    hasError: true,
                                    errorText: t(
                                      "hiring.signature.formValidation.missingWitnessEmail",
                                    ),
                                    toastMessage,
                                  },
                                }))
                                dispatchToast({
                                  type: "error",
                                  content: toastMessage,
                                })
                                return
                              }

                              setWitnessArray((prev) => {
                                const tmp = [...prev]
                                tmp.splice(i, 1, {
                                  employeeId: employeeData?.id,
                                  email: employeeData?.email,
                                  name: employeeData?.name,
                                  phone: employeeData?.phone || undefined,
                                  isNotEmployee: false,
                                })
                                return tmp
                              })
                              setEmployeeOptions(undefined)
                              setError((prev) => ({
                                ...prev,
                                [`witnessEmployee${i + 1}`]: {
                                  hasError: false,
                                  errorText: "",
                                },
                              }))
                            }}
                            value={
                              witnessArray[i].employeeId
                                ? {
                                    label: witnessArray[i].name,
                                    value: witnessArray[i].employeeId,
                                  }
                                : undefined
                            }
                            onChange={async (e) => {
                              if (e.target.value?.length === 0) {
                                setEmployeeOptions(undefined)
                                return
                              }

                              if (e.target.value?.length < 3) return
                              const foundEmployees = await getPartialEmployee({
                                searchParam: e.target.value,
                              })
                              setEmployeeOptions(
                                foundEmployees.map((employee) => ({
                                  id: employee?.id,
                                  name: employee?.name,
                                  email:
                                    employee?.corporateEmail ||
                                    employee?.email ||
                                    "",
                                  phone: employee?.phoneNumber,
                                })),
                              )
                            }}
                            loading={isSearching}
                            loadingText={"Buscando.."}
                            error={!!error[`witnessEmployee${i + 1}`]?.hasError}
                            helperText={
                              error[`witnessEmployee${i + 1}`]?.errorText
                            }
                            options={
                              employeeOptions?.map((employee) => ({
                                label: employee.name,
                                value: employee.id,
                              })) || []
                            }
                            onBlur={({ target: { value } }) => {
                              if (!value) {
                                setError((prev) => ({
                                  ...prev,
                                  [`witnessEmployee${i + 1}`]: {
                                    hasError: false,
                                    errorText: "",
                                  },
                                }))
                              }

                              setEmployeeOptions(undefined)
                            }}
                          />
                        )}

                        <div
                          style={{
                            marginBottom: "24px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            value={w.isNotEmployee}
                            onChange={async (_, checked) => {
                              const isNotEmployee = checked
                              setWitnessArray((prev) => {
                                const tmp = [...prev]
                                tmp[i] = {
                                  isNotEmployee,
                                  email: "",
                                  name: "",
                                  phone: "",
                                  employeeId: undefined,
                                }
                                return tmp
                              })
                            }}
                          />
                          <Typography variant="body3">
                            A testemunha não está cadastrada na plataforma
                          </Typography>
                        </div>
                        <StyledLinkButton
                          style={{ marginBottom: "24px" }}
                          variant="secondary"
                          onClick={() => {
                            let tmp = [...witnessArray]
                            tmp.splice(i, 1)
                            if (tmp.length === 0)
                              tmp = [
                                {
                                  name: "",
                                  email: "",
                                  phone: "",
                                  employeeId: "",
                                  isNotEmployee: false,
                                },
                              ]
                            setWitnessArray(tmp)

                            setError((prev) => ({
                              ...prev,
                              [`witnessEmail${i + 1}`]: {
                                hasError: false,
                                errorText: "",
                              },
                              [`witnessName${i + 1}`]: {
                                hasError: false,
                                errorText: "",
                              },
                            }))
                          }}
                        >
                          <Typography
                            variant="body3"
                            style={{
                              color: "#D1C7CE",
                              fontWeight: 700,
                            }}
                          >
                            Remover
                          </Typography>
                          <Icons
                            name="IconTrash"
                            fill="transparent"
                            size={16}
                            style={{ color: "#D1C7CE" }}
                          />
                        </StyledLinkButton>
                      </>
                    ))}
                    {witnessArray.length < 2 ? (
                      <div
                        style={{
                          marginBottom: "24px",
                          paddingTop: "16px",
                          marginTop: "32px",
                          borderTop: "1px solid #EBE6E9",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <StyledLinkButton
                          variant="primary"
                          onClick={() => {
                            setWitnessArray([
                              ...witnessArray,
                              {
                                name: "",
                                email: "",
                                phone: "",
                                employeeId: "",
                                isNotEmployee: false,
                              },
                            ])
                          }}
                        >
                          <Icons name="IconPlus" fill="transparent" size={24} />
                          <Typography
                            variant="body2"
                            style={{
                              fontWeight: 700,
                            }}
                          >
                            Adicionar nova testemunha
                          </Typography>
                        </StyledLinkButton>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Details>
                </StyledAccordion>

                <StyledAccordion>
                  <Summary
                    expandIcon={
                      <IconButton
                        size="medium"
                        variant="filled"
                        icon="IconChevronDown"
                      />
                    }
                  >
                    <Section>
                      <SectionTitle variant="headline8">Empresa</SectionTitle>
                      <SectionSubTitleAccordion variant="body4">
                        Preencha as informações abaixo conforme os dados do
                        destinatário. <br />
                        <b>Obs:</b> A pessoa que assinará como representante da
                        empresa deve ser um funcionário ativo.
                      </SectionSubTitleAccordion>
                    </Section>
                  </Summary>
                  <Details>
                    <div style={{ marginBottom: "24px" }}>
                      <SelectField
                        name="companyToSign"
                        label="Assinar como"
                        fullWidth
                        disabled
                        value="company"
                        options={[
                          {
                            label: "Empresa",
                            value: "company",
                          },
                        ]}
                      />
                    </div>
                    <div style={{ marginBottom: "24px" }}>
                      <TextField
                        id="company"
                        name="company"
                        label="Nome da empresa"
                        fullWidth
                        disabled
                        value={card.companyName}
                        defaultValue={"Não cadastrado"}
                      />
                    </div>
                    <div style={{ marginBottom: "24px" }}>
                      <SearchField
                        label="Buscar colaborador"
                        onSearchChange={(_, option) => {
                          if (!option || !option.value) {
                            setCompanyEmployee(undefined)
                            return
                          }
                          if (
                            validateIfCompanyEmployeeIsNotEligible(option.value)
                          ) {
                            setError((prev) => ({
                              ...prev,
                              companyEmployee: {
                                hasError: true,
                                errorText:
                                  "Colaborador já está como assinante em testemunha ou empresa",
                                toastMessage: "",
                              },
                            }))
                            dispatchToast({
                              type: "error",
                              content:
                                "Colaborador já está sendo usado para assinar como Testemunha",
                            })
                            return
                          }

                          const employeeData = employeeOptions?.find(
                            (employee) => employee.id === option.value,
                          )

                          if (employeeData && !employeeData.email) {
                            const toastMessage =
                              getSendContractCustomValidationToastMessage(
                                ToastMessageCodes.MissingCompanyEmployeeEmail,
                                employeeData.name || "",
                              )
                            setError((prev) => ({
                              ...prev,
                              companyEmployee: {
                                hasError: true,
                                errorText: t(
                                  "hiring.signature.formValidation.missingCompanyEmployeeEmail",
                                ),
                                toastMessage,
                              },
                            }))
                            dispatchToast({
                              type: "error",
                              content: toastMessage,
                            })
                            return
                          }

                          setCompanyEmployee({
                            id: employeeData?.id || "",
                            email: employeeData?.email || "",
                            name: employeeData?.name || "",
                            phone: employeeData?.phone || undefined,
                          })
                          setEmployeeOptions(undefined)
                          setError((prev) => ({
                            ...prev,
                            companyEmployee: {
                              hasError: false,
                              errorText: "",
                              toastMessage: "",
                            },
                          }))
                        }}
                        value={
                          companyEmployee
                            ? {
                                label: companyEmployee.name,
                                value: companyEmployee.id,
                              }
                            : undefined
                        }
                        onChange={async (e) => {
                          if (e.target.value?.length === 0) {
                            setEmployeeOptions(undefined)
                            return
                          }

                          if (e.target.value?.length < 3) return
                          const foundEmployees = await getPartialEmployee({
                            searchParam: e.target.value,
                          })
                          setEmployeeOptions(
                            foundEmployees.map((employee) => ({
                              id: employee?.id || "",
                              name: employee?.name || "",
                              email:
                                employee?.corporateEmail ||
                                employee?.email ||
                                "",
                              phone: employee?.phoneNumber,
                            })),
                          )
                        }}
                        loading={isSearching}
                        loadingText={"Buscando.."}
                        error={!!error.companyEmployee?.hasError}
                        helperText={error.companyEmployee?.errorText}
                        options={
                          employeeOptions?.map((employee) => ({
                            label: employee.name,
                            value: employee.id,
                          })) || []
                        }
                        onBlur={({ target: { value } }) => {
                          if (!value) {
                            setError((prev) => ({
                              ...prev,
                              companyEmployee: {
                                hasError: false,
                                errorText: "",
                                toastMessage: "",
                              },
                            }))
                          }

                          setEmployeeOptions(undefined)
                        }}
                      />
                    </div>
                  </Details>
                </StyledAccordion>

                <Divider />

                {flow.subcategory === "internship" ? (
                  <>
                    <InstitutionForm
                      formInstitution={formInstitution}
                      setFormInstitution={(form) => setFormInstitution(form)}
                      error={error}
                      setError={(error) =>
                        setError((prev) => ({
                          ...prev,
                          ...error,
                        }))
                      }
                    />
                    <Divider />
                  </>
                ) : (
                  <></>
                )}

                <FormButton
                  size="large"
                  variant="primary"
                  onClick={handleSendContractClick}
                  disabled={isSendingContract}
                >
                  {isSendingContract ? (
                    <Loader size="small" variant="secondary" />
                  ) : (
                    <>
                      Enviar Contrato
                      <Icons name="IconArrowRight" />
                    </>
                  )}
                </FormButton>
              </Section>
            </SubContainer>
          )}
        </ContentContainer>

        {hasSentSignatureRequest ? (
          <ContentContainer style={{ paddingBottom: "48px" }}>
            <SubContainer
              style={{
                borderTop: "1px solid #E8E3E6",
                paddingTop: "48px",
              }}
            >
              <div style={{ marginBottom: "24px" }}>
                <Typography
                  variant="headline8"
                  style={{
                    fontWeight: 700,
                    color: "#53464F",
                  }}
                >
                  {"Status"}
                </Typography>

                <Typography
                  variant="body4"
                  style={{ fontWeight: 400, color: "#83727D" }}
                >
                  Acompanhe o status da assinatura do contrato
                </Typography>
              </div>

              {contract?.signature?.map((item, index) => {
                const canResend = item.status !== SignatureSignStatus.signed
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "24px 32px",
                      border: "1px solid #EBE6E9",
                      borderRadius: "8px",
                      marginBottom: "24px",
                      width: "100%",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          alignSelf: "flex-start",
                        }}
                      >
                        <Typography variant="body3" style={{ fontWeight: 700 }}>
                          {item?.name || ""}
                        </Typography>
                        <Tag variant="disabled" style={{ marginLeft: "5px" }}>
                          {item?.type === "candidate"
                            ? "Candidato"
                            : item?.type === "witness"
                              ? "Testemunha"
                              : "Empresa"}
                        </Tag>
                      </div>
                      <Typography variant="body4" style={{ marginTop: "5px" }}>
                        {item.email}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                          color:
                            item?.status === "Signed" ? "#21A38A" : "#C96C01",
                        }}
                      >
                        <Icons
                          name={
                            item?.status === "Signed"
                              ? "IconCheck"
                              : "IconClock"
                          }
                          fill="transparent"
                          size={16}
                          style={{
                            marginRight: "8px",
                            stroke:
                              item?.status === "Signed" ? "#21A38A" : "#C96C01",
                          }}
                        />
                        <Typography
                          variant="body4"
                          style={{
                            color:
                              item?.status === "Signed" ? "#21A38A" : "#C96C01",
                          }}
                        >
                          {item?.status === "Signed"
                            ? `Assinado em: ${
                                item?.signedAt
                                  ? dayjs(item?.signedAt).format(
                                      "DD/MM/YYYY [às] HH:mm",
                                    )
                                  : ""
                              }`
                            : "Aguardando assinatura"}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Icons
                          name="IconDevices"
                          fill="transparent"
                          size={16}
                          style={{ marginRight: "8px" }}
                        />
                        <Typography variant="body4">
                          IP: {item?.ip || "Não registrado"}
                        </Typography>
                      </div>
                    </div>
                    {canResend ? (
                      <Button
                        variant="primary"
                        size="large"
                        onClick={() =>
                          handleResendSignature({
                            type: item.type,
                            email: item.email,
                            name: item.name,
                          })
                        }
                        disabled={isResendingContract?.isResending}
                        style={{
                          alignSelf: "center",
                          marginLeft: "20px",
                          marginTop: "0px",
                        }}
                      >
                        {isResendingContract?.isResending &&
                        isResendingContract?.type === item.type ? (
                          <Loader variant="secondary" size="small" />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <StyledPOneLine numberOfLines={1}>
                              <span>{"Reenviar contrato"}</span>
                            </StyledPOneLine>
                            <Icons
                              name="IconArrowRight"
                              style={{
                                width: "24px",
                                height: "24px",
                                color: "#fff",
                                fill: "transparent",
                              }}
                            />
                          </div>
                        )}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                )
              })}

              {contract?.lastContractOutput && (
                <UploadPreview
                  file={contract?.lastContractOutput}
                  options={{
                    canDownload: true,
                    canRemove: false,
                    downloadText: "Baixar contrato",
                  }}
                />
              )}
            </SubContainer>
          </ContentContainer>
        ) : (
          <></>
        )}
      </RightContainer>

      <ConfirmationSignatureModal
        isLoading={isSendingContract}
        isOpen={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onSubmit={() => handleSubmit()}
      />
    </Container>
  )
}
