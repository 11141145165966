import { trpc } from "@api/client"
import {
  Checkbox,
  Icons,
  SearchField,
  SelectField,
  TextArea,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { FormikErrors, useFormik } from "formik"
import { forwardRef, useImperativeHandle, useMemo } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import * as yup from "yup"
import { Banner, UploadMultiples } from "../../../../components"
import { FlowSubcategory, IProposedLetter, S3File } from "../../../../types"
import {
  currencyParser,
  formatCurrency,
  removeNullValues,
} from "../../../../utils"
import dispatchToast from "../../../../utils/dispatchToast"
import { StyledTextArea, StyledTextAreaHelper } from "../pages/Manage/styles"

export type FormBasicRef = {
  getValues: () => any
  handleSubmit: () => void
  validateForm: () => Promise<FormikErrors<any>>
}

interface FormProposedInfoProps {
  companyId: string
  modelId: string
  data?: IProposedLetter
  onSubmit: (values: IProposedLetter) => void
  flowType: FlowSubcategory
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 12px;
`

export const FormProposedInfo = forwardRef<FormBasicRef, FormProposedInfoProps>(
  ({ data, onSubmit, modelId, companyId, flowType }, ref) => {
    const [t] = useTranslation("translations", {
      keyPrefix: "page.settings.proposedLetterModel.proposedInfoForm",
    })

    const { data: groups, isInitialLoading: isGroupsLoading } =
      trpc.company.getGroups.useQuery(undefined, {
        enabled: !!companyId,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
      })

    const { data: departments, isInitialLoading: isDepartmentsLoading } =
      trpc.company.getDepartments.useQuery(undefined, {
        enabled: !!companyId,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
      })

    const { data: roles, isInitialLoading: isRolesLoading } =
      trpc.company.getRoles.useQuery(undefined, {
        enabled: !!companyId,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
      })

    const validationSchema = useMemo(
      () =>
        yup.object({
          aboutCompany: yup
            .array()
            .of(
              yup.object().shape({
                key: yup.string().notRequired(),
                path: yup.string().notRequired(),
                type: yup.string().notRequired(),
                value: yup.string().notRequired(),
              }),
            )
            .nullable()
            .notRequired(),
          benefits: yup
            .array()
            .of(
              yup.object().shape({
                key: yup.string().notRequired(),
                path: yup.string().notRequired(),
                type: yup.string().notRequired(),
                value: yup.string().notRequired(),
              }),
            )
            .nullable()
            .notRequired(),
          role: yup.object().nullable().notRequired(),
          department: yup.object().nullable().notRequired(),
          group: yup.object().nullable().notRequired(),
          assignments: yup.string().notRequired(),
          salary: yup.string().notRequired(),
          hasntVariableSalary: yup.boolean(),
          variableSalary: yup.string().when("hasntVariableSalary", {
            is: true,
            then: yup.string().notRequired(),
            otherwise: yup.string().notRequired(),
          }),
          workload: yup.string().when([], {
            is: () => flowType === "internship",
            then: yup.string().notRequired(),
            otherwise: yup.string().notRequired(),
          }),
          workship: yup.string().when([], {
            is: () => flowType === "internship",
            then: yup.string().notRequired(),
            otherwise: yup.string().notRequired(),
          }),
        }),
      [flowType],
    )

    const formik = useFormik({
      initialValues: {
        aboutCompany: data?.aboutCompany || null,
        benefits: data?.benefits || null,
        role: data?.role || undefined,
        department: data?.department || undefined,
        group: data?.group || undefined,
        assignments: data?.assignments || "",
        salary: data?.salary ? formatCurrency(data.salary) : "",
        variableSalary: data?.variableSalary || "",
        hasntVariableSalary: false,
        workload: data?.workload || "",
        workship: data?.workship || "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        const valuesWithoutNull = removeNullValues(values)

        const {
          hasntVariableSalary,
          variableSalary,
          salary,
          aboutCompany,
          benefits,
          ...rest
        } = valuesWithoutNull

        const cleanedSalary = salary.replace(/[R$]|[.]/g, "").replace(",", ".")
        const parsedSalary = cleanedSalary == "R$0,00" ? null : +cleanedSalary

        const proposedLetter: IProposedLetter = {
          ...rest,
          aboutCompany: aboutCompany || undefined,
          benefits: benefits || undefined,
          salary: parsedSalary,
          variableSalary: hasntVariableSalary ? undefined : variableSalary,
        }
        onSubmit(proposedLetter)
      },
    })

    useImperativeHandle(ref, () => ({
      getValues: () => formik.values,
      handleSubmit: () => formik.handleSubmit(),
      validateForm: () => formik.validateForm(),
    }))

    const handleRemoveFile = (file, fieldName) => {
      const files = [...formik.values[fieldName]]
      const indexToBeRemoved = files.findIndex(
        (fileFromArray) => fileFromArray.key === file.key,
      )
      if (indexToBeRemoved === -1) {
        dispatchToast({
          content: "Algo ocorreu ao remover o arquivo",
          type: "error",
        })
        return
      }

      files.splice(indexToBeRemoved, 1)
      formik.handleChange({
        target: { name: fieldName, value: files },
      })
    }

    return (
      <form onSubmit={formik.handleSubmit}>
        <Container>
          <Wrapper>
            <Typography
              variant="headline8"
              style={{
                color: "#53464f",
              }}
            >
              {t("aboutCompany.title")}
            </Typography>
            <Typography
              variant="body4"
              style={{
                marginBottom: 32,
                color: "#83727D",
              }}
            >
              {t("aboutCompany.description")}
            </Typography>

            <UploadMultiples
              values={formik.values.aboutCompany || null}
              label={t("aboutCompany.label")}
              accept={["jpg", "png", "pdf"]}
              folder={`${companyId}/modelProposedLetter/${modelId}/aboutCompany`}
              onUpload={async (file) => {
                formik.handleChange({
                  target: {
                    name: "aboutCompany",
                    value: formik.values.aboutCompany
                      ? [...formik.values.aboutCompany, file]
                      : [file],
                  },
                })
              }}
              onRemove={async (file) => {
                handleRemoveFile(file, "aboutCompany")
              }}
            />
          </Wrapper>

          <Wrapper>
            <Typography
              variant="headline8"
              style={{
                color: "#53464f",
              }}
            >
              {t("content.title")}
            </Typography>
            <Typography
              variant="body4"
              style={{
                marginBottom: 32,
                color: "#83727D",
              }}
            >
              {t("content.description")}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
              }}
            >
              <SearchField
                label={t("content.roleLabel")}
                onSearchChange={(_, option) => {
                  const value = { _id: option.value, name: option.label }
                  formik.handleChange({
                    target: { name: "role", value },
                  })
                }}
                value={
                  formik.values.role
                    ? {
                        label: formik.values.role.name,
                        value: formik.values.role._id,
                      }
                    : null
                }
                loading={!roles || isRolesLoading}
                options={
                  roles
                    ? roles.map((role) => ({
                        label: role.name,
                        value: role.id,
                      }))
                    : []
                }
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={
                  formik.touched.role && Boolean(formik.errors.role)
                    ? formik.errors.role?.toString()
                    : undefined
                }
              />

              <SearchField
                label={t("content.departmentLabel")}
                onSearchChange={(_, option) => {
                  const value = { _id: option.value, name: option.label }
                  formik.handleChange({
                    target: { name: "department", value },
                  })
                }}
                value={
                  formik.values.department
                    ? {
                        label: formik.values.department.name,
                        value: formik.values.department._id,
                      }
                    : null
                }
                loading={!departments || isDepartmentsLoading}
                options={
                  departments
                    ? departments.map((department) => ({
                        label: department.name,
                        value: department.id,
                      }))
                    : []
                }
                error={
                  formik.touched.department && Boolean(formik.errors.department)
                }
                helperText={
                  formik.touched.department && Boolean(formik.errors.department)
                    ? formik.errors.department?.toString()
                    : undefined
                }
              />

              <SearchField
                label={t("content.groupLabel")}
                onSearchChange={(_, option) => {
                  const value = { _id: option.value, name: option.label }
                  formik.handleChange({
                    target: { name: "group", value },
                  })
                }}
                value={
                  formik.values.group
                    ? {
                        label: formik.values.group.name,
                        value: formik.values.group._id,
                      }
                    : null
                }
                loading={!groups || isGroupsLoading}
                options={
                  groups
                    ? groups.map((group) => ({
                        label: group.name,
                        value: group.id,
                      }))
                    : []
                }
                error={formik.touched.group && Boolean(formik.errors.group)}
                helperText={
                  formik.touched.group && Boolean(formik.errors.group)
                    ? formik.errors.group?.toString()
                    : undefined
                }
              />
              <StyledTextArea
                error={
                  (formik.touched.assignments &&
                    Boolean(formik.errors.assignments)) ??
                  false
                }
              >
                <TextArea
                  maxLength={10000}
                  placeholder={t("content.assignmentsLabel")}
                  value={formik.values.assignments}
                  onChange={(e: any) => {
                    const value = e.target.value
                    formik.handleChange({
                      target: { name: "assignments", value },
                    })
                  }}
                />
                {formik.touched.assignments && !!formik.errors.assignments ? (
                  <StyledTextAreaHelper>
                    <Icons fill="transparent" name="IconInfoCircle" size={18} />
                    {formik.errors.assignments}
                  </StyledTextAreaHelper>
                ) : (
                  <></>
                )}
              </StyledTextArea>

              <div>
                <TextField
                  label={t("content.salaryLabel")}
                  placeholder={t("content.salaryLabel")}
                  value={formik.values.salary}
                  onChange={(event: any) => {
                    let value = currencyParser(event.target.value)
                    if (value === "R$0,00") value = ""

                    formik.handleChange({
                      target: { name: "salary", value },
                    })
                  }}
                  error={formik.touched.salary && Boolean(formik.errors.salary)}
                  helperText={
                    formik.touched.salary && Boolean(formik.errors.salary)
                      ? formik.errors.salary?.toString()
                      : undefined
                  }
                  fullWidth
                />
              </div>

              <StyledTextArea
                error={
                  (formik.touched.variableSalary &&
                    Boolean(formik.errors.variableSalary)) ??
                  false
                }
              >
                <TextArea
                  placeholder={t("content.variableSalaryLabel")}
                  value={formik.values.variableSalary}
                  onChange={(e: any) => {
                    const value = e.target.value
                    formik.handleChange({
                      target: { name: "variableSalary", value },
                    })
                  }}
                />
                {formik.touched.variableSalary &&
                !!formik.errors.variableSalary ? (
                  <StyledTextAreaHelper>
                    <Icons fill="transparent" name="IconInfoCircle" size={18} />
                    {formik.errors.variableSalary}
                  </StyledTextAreaHelper>
                ) : (
                  <></>
                )}
              </StyledTextArea>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  onChange={(_, value) => {
                    formik.handleChange({
                      target: { name: "hasntVariableSalary", value },
                    })
                  }}
                />
                <Typography variant="body3" variantColor="#53464F">
                  {t("content.dontHaveVariableSalaryLabel")}
                </Typography>
              </div>

              <Banner
                icon="IconInfoCircle"
                type="info"
                title={t("content.whatIsVariableSalaryTitle")}
                message={t("content.whatIsVariableSalaryDescription")}
                hasHideBanner={false}
              />

              {flowType === "internship" ? (
                <>
                  <SelectField
                    label={t("content.workloadLabel")}
                    placeholder={t("content.workloadLabel")}
                    value={formik.values.workload}
                    options={[
                      {
                        value: "twenty-hours-week",
                        label: "20 horas semanais",
                      },
                      { value: "thiry-hours-week", label: "30 horas semanais" },
                      { value: "forty-hours-week", label: "40 horas semanais" },
                    ]}
                    onSelectChange={async (_, { value }) => {
                      formik.handleChange({
                        target: { id: "workload", value },
                      })
                    }}
                    error={
                      formik.touched.workload &&
                      Boolean(formik?.errors?.workload)
                    }
                    helperText={
                      formik.touched.workload &&
                      Boolean(formik?.errors?.workload)
                        ? formik?.errors?.workload?.toString()
                        : undefined
                    }
                    fullWidth
                  />

                  <SelectField
                    label={t("content.workshipLabel")}
                    placeholder={t("content.workshipLabel")}
                    value={formik.values.workship}
                    options={[
                      { label: "Manhã", value: "morning" },
                      { label: "Tarde", value: "afternoon" },
                    ]}
                    autoFocus={false}
                    onSelectChange={async (_, { value }) => {
                      formik.handleChange({
                        target: { id: "workship", value },
                      })
                    }}
                    error={
                      formik.touched.workship &&
                      Boolean(formik?.errors?.workship)
                    }
                    helperText={
                      formik.touched.workship &&
                      Boolean(formik?.errors?.workship)
                        ? formik?.errors?.workship?.toString()
                        : undefined
                    }
                    fullWidth
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </Wrapper>

          <Wrapper>
            <Typography
              variant="headline8"
              style={{
                color: "#53464f",
              }}
            >
              {t("benefits.title")}
            </Typography>
            <Typography
              variant="body4"
              style={{
                marginBottom: 32,
                color: "#83727D",
              }}
            >
              {t("benefits.description")}
            </Typography>

            <UploadMultiples
              values={formik.values.benefits as S3File[]}
              label={t("benefits.label")}
              accept={["jpg", "png", "pdf"]}
              folder={`${companyId}/modelProposedLetter/${modelId}/benefits`}
              onUpload={async (file) => {
                formik.handleChange({
                  target: {
                    name: "benefits",
                    value: formik.values.benefits
                      ? [...formik.values.benefits, file]
                      : [file],
                  },
                })
              }}
              onRemove={async (file) => {
                handleRemoveFile(file, "benefits")
              }}
            />
          </Wrapper>
        </Container>
      </form>
    )
  },
)
